// Fonts
$font1: 'DM Sans', $font0;
// $font2: 'HeronSans', $font0;

// Font Weights
$fw-normal: 400;
$fw-bold: 700;

// Font Sizes
$fs-energy-icon: 10px;

$font-sets: (
    body-xsmall: (
        font-size: 11,
        line-height: 14
    ),
    body-small: (
        font-size: 13,
        line-height: 16
    ),
    body-text: (
        font-size: 16,
        line-height: 20
    ),
    body-medium: (
        font-size: 16,
        line-height: 20
    ),
    body-large: (
        font-size: 18,
        line-height: 24
    ),
    h-paragraph: (
        font-size: 18,
        line-height: 24,
        font-weight: $fw-bold
    ),
    body-xlarge: (
        font-size: 24,
        line-height: 32
    ),
    h-xtiny: (
        // font-family: $font2,
        font-size: 12,
        line-height: 14,
        font-weight: $fw-bold
    ),
    h-tiny: (
        // font-family: $font2,
        font-size: 14,
        line-height: 16,
        font-weight: $fw-bold
    ),
    h-xsmall: (
        // font-family: $font2,
        font-size: 16,
        line-height: 20,
        font-weight: $fw-bold
    ),
    h-small: (
        // font-family: $font2,
        font-size: 18,
        line-height: 22,
        font-weight: $fw-bold
    ),
    h: (
        // font-family: $font2,
        font-size: 22,
        line-height: 26,
        font-weight: $fw-bold
    ),
    h-large: (
        // font-family: $font2,
        font-size: 32,
        line-height: 40,
        font-weight: $fw-bold
    ),
    h-xl: (
        // font-family: $font2,
        font-size: 36,
        line-height: 42,
        font-weight: $fw-bold
    ),
    h-xxxl: (
        // font-family: $font2,
        font-size: 52,
        line-height: 50,
        font-weight: $fw-bold
    )
);

// Line Height Base
$lh-base: map-get(map-get($font-sets, body-text), line-height)/map-get(map-get($font-sets, body-text), font-size);

// Border Radius
$br-bullet: 100%;
$br-popup-middle: 5px;
$br-popup-bottom: 10px;
$br-popover: 5px;
$br-product-card: 0;

// Button
$btn-h-default: 44px;
$btn-h-small: 35px;
$btn-br-factor: .5;

// Form Elements
$h-input: 48px;
$h-textarea: 104px;

// Colours
$c-brand: #D12D26;
$c-primary: #D12D26;
$c-secondary: #232F5F;
$c-light: #f6f6f6;
$c-dark: #22242C;
$c-black: #000000;
$c-white: #ffffff;

$c-grey-dark: #C1C4CE;
$c-grey-light: #d8d8d8;
$c-grey-lighter: #eeeeee;

$c-grey-border: #e5e5e5;
$c-grey-border-dark: #b7b7b7;
$c-pdp-border: #444444;
// $c-colored-border: #dccbd7;
$c-colored-border: #EDE5EA;
$c-assistant-border: #d6c8d1;

$c-text-dark: #232F5F;
$c-text-light: #ffffff;
$c-text-weak: #7f7f7f;
$c-text-grey: #989898;

$c-bg-body: #ffffff;
$c-bg-light: #f6f6f6;
$c-bg-colored: #C1C4CE;
$c-bg-dark: #111111;
$c-bg-prd-footer: #ECEDEF;
$c-bg-prd-mini: #ffffff;
$c-bg-pdp-details-1: #474747;
$c-bg-pdp-details-2: #222222;
$c-bg-assistant: $c-bg-colored;
$c-bg-grey: #22242C;

$c-success: #6e994e;
$c-error: #D12D26;
$c-warning: #ffce00;

$c-bullet-dark: #22242C;
$c-bullet-light: #ffffff;

$c-switch-off: #989898;
$c-dm-green: #34A852;
$c-dm-off-text: #666666;

// Banner Background & Text Colours
$c-banner: (
    1: (
        background: #7E2020,
        color: $c-text-light
    ),
    2: (
        background: #232F5F,
        color: $c-text-light
    ),
    3: (
        background: #54627E,
        color: $c-text-light
    ),
    4: (
        background: #22242C,
        color: $c-text-light
    ),
    5: (
        background: #6B6775,
        color: $c-text-light
    ),
    6: (
        background: #222222,
        color: $c-text-light
    ),
    7: (
        background: #504B4B,
        color: $c-text-light
    ),
    8: (
        background: #C1C4CE
    )
);

$border-product-card: 1px solid #C8C8C8;
// Product Card Theme Colours
$c-product-card: ();

// Deal Countdown Background Colours
$c-deal-countdown: (
    1: #504B4B,
    2: #22242C,
    3: #7E2020
);

// Notifiers Type Colours
$c-notifiers: (
    1: #D12D26,
    2: #6e994e,
    3: #ffce00,
    4: #989898
);

// Indicator
$c-indicator: (
    1: #D12D26,
    2: #ffce00,
    3: #6e994e,
    4: #3eb187
);

// Stock Bars
$c-stock-bars: (
    1: #ffce00,
    2: #ffce00,
    3: #6e994e,
    4: #6e994e
);

// Energy Colours
$energy-colors: (
    a0: #009036,
    a1: #c8d200,
    a2: #58ab27,
    a3: #009036,
    b0: #faba00,
    c0: #eb6a0a,
    d0: #e2001a,
    e0: #faba00,
    f0: #e64433,
    g0: #e2001a
);
$energy-label-height: 17px;

$top-stripe-height: 50px;

// header variables
$header-top-bg: #F6F6F6;
$header-top-color: $c-text-dark;
$header-top-padding-x: 15px;
$header-top-border-bottom: 1px solid $c-grey-lighter;

$header-top-cover-bg: transparent;
$header-top-cover-color: $c-white;
$header-top-cover-border-bottom-color: rgba($color: $c-white, $alpha: .3);

$header-top-opaque-bg: $c-white;
$header-top-opaque-color: $c-black;

// desktop header variables
$d-header-item-space: 25px;
$d-header-link-space: 20px;
$product-card-height: 648px;

$c-bg-live-chat: #8A2B2B;
$c-online: #02b71d;

// Main Menu
$menu-space-x: 20px;
$menu-sub-bg: #f6f6f6;
$menu-lv-1-padding: 22px 0;
$menu-lv-1-border-color: #f0f0f0;
$menu-lv-2-row-item: 3;
$menu-lv-2-img-width: 95px;
$menu-lv-2-img-height: 95px;
$menu-brand-row-item: 3;
$menu-brand-height: 115px;

// Dark Mode Controls
$dm-control-height: 0;

// Compare Set
$cmp-add-icon-color: #d9d9d9;
$cmp-add-bg-color: #f0f0f0;

$preorder-text-color: #76bc1e;

// User Orders Process Bar Colors
$ord-bar-colors: (
    delivery: #80b03e,
    return: #b32424,
    warranty: #80b03e
);

// Dawlance
$c-bg-footer-bottom: #D93131;
$c-header-input-bg: #C1C4CE;
$c-bg-warn: #ECE3E9;