@include d-elements-appshell;

// Mobile Scrollable
.scrollable-h{
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    > * {
        white-space: normal;
    }

    &::-webkit-scrollbar{
        display: none;
    }
}

// Scrollable Area
.scrollable-area {
    overflow: auto;
    padding-bottom: 10px;
    width: 100%;
}

.link{
    // position: relative;

    // &::after{
    //     content: "";
    //     display: inline-block;
    //     width: 100%;
    //     height: 0;
    //     border-bottom: 1px solid currentColor;
    //     position: absolute;
    //     bottom: 1px;
    //     left: 0;
    //     font-size: 0;
    //     opacity: 0;
    //     @include transitions();
    // }

    &-inline{
        text-decoration: underline;
        // position: relative;

        // &::after{
        //     content: "";
        //     display: inline-block;
        //     width: 100%;
        //     height: 0;
        //     border-bottom: 1px solid currentColor;
        //     position: absolute;
        //     bottom: 1px;
        //     left: 0;
        //     font-size: 0;
        //     @include transitions();
        // }
    }
}

.input{
    position: relative;
    display: inline-block;
}

.textbox{
    width: 100%;
    .wrp{
        display: block;
        position: relative;
    }
    i{
        display: inline-block;
        position: absolute;
        top: ($h-input - 32px) / 2;
        right: 0;
    }
    .timer{
        position: absolute;
        top: 0;
        right: 0;
        @include font-set(body-small);
        color: $c-primary;
    }
    input{
        height: $h-input;
        line-height: $h-input; 
        width: 100%;
        border-style: solid;
        border-color: $c-grey-light;
        border-width: 0 0 1px 0;
        padding: 0 5px;
        background: none;
        outline: none;
        color: $c-text-dark;

        &:focus{
            border-bottom-color: $c-grey-dark;
        }
        &:disabled{
            opacity: .7;
        }
    }
    label{
        @include font-set(body-small);
        margin: 0;
    }
    .input-icon{
        input{
            padding-right: 37px;
        }
    }
}

.textarea{
    width: 100%;
    textarea{
        height: $h-textarea;
        width: 100%;
        border-style: solid;
        border-color: $c-grey-light;
        border-width: 0 0 1px 0;
        padding: 25px 5px;
        background: none;
        outline: none;

        &:focus{
            border-bottom-color: $c-grey-dark;
        }
    }
    label{
        @include font-set(body-small);
        margin: 0;
    }
}

.textbox input.form-error, .form-error .textbox input, textarea.form-error, .form-error textarea{
    border-bottom-color: $c-error;
}
.form-error .textbox .wrp, .form-error .textarea .wrp{
    &::before{
        content: "";
        display: inline-block;
        @extend .svg;
        @extend .svg-input-error;
        position: absolute;
        top: ($h-input - 32px) / 2;
        right: 0;
    }
}
.form-error .textarea .wrp{
    &::before{
        top: auto;
        bottom: 0;
    }
}
.textbox input.processing, .processing .textbox input{
    background: url(#{$dir-images + 'preloader.svg'}) right center no-repeat;
    background-size: contain;
}

.checkbox, .radio, .rl-item{
    input{
        width:32px;
        height:32px;
        position: absolute;
        opacity: 0;
        z-index: 2;
        top: 0;
        left: 0;
        cursor: pointer;
    }

    label{
        display: inline-block;
        padding: 9px 0 0 32px;
        margin: 0;
        min-height: 32px;
        cursor: pointer;
    
        &::before{
            content:"";
            position:absolute;
            top: 0;
            left: 0;
        }

        &::after{
            content:"";
            position:absolute;
            top: 0;
            left: 0;
            transform: scale(0);
            @include transitions($dur: "now");
        }
    }

    input:checked + label{
        &::before{
            opacity: 0;
        }

        &::after{
            transform: scale(1);
        }
    }
}

.checkbox{
    label{
        &::before{
            @extend .svg;
            @extend .svg-check;
        }

        &::after{
            @extend .svg;
            @extend .svg-check-full;
        }
    }
}

.radio, .rl-item{
    label{
        &::before{
            @extend .svg;
            @extend .svg-radio;
        }

        &::after{
            @extend .svg;
            @extend .svg-radio-full;
        }
    }
}

.switch{
    input{
        width:31px;
        height:24px;
        position: absolute;
        opacity: 0;
        z-index: 2;
        top: 0;
        left: 0;
        cursor: pointer;
    }
    label{
        display: inline-block;
        padding: 4px 0 0 41px;
        margin: 0;
        min-height: 24px;
        cursor: pointer;
        
    
        &::before{
            content:"";
            display: inline-block;
            background-color: $c-grey-dark;
            width: 31px;
            height: 24px;
            border-radius: 12px;
            position:absolute;
            top: 0;
            left: 0;
            @include transitions($dur: "now");
        }

        &::after{
            content:"";
            display: inline-block;
            background: $c-white;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 2px;
            left: 2px;
            border-radius: 10px;
            @include transitions($dur: "now");
        }
    }

    input:checked + label{
        &::before{
            background: $c-success;
        }

        &::after{
            left: 8.5px;
        }
    }
}

// Radio Switch
.radio-switch{
    display: flex;
    align-items: center;
    justify-content: center;
    .rs-1{
        padding-right: 70px;
        position: relative;
        label{
            &::before{
                content:"";
                display: inline-block;
                width: 60px;
                height: 24px;
                background-color: $c-primary;
                border-radius: 12px;
                position: absolute;
                top: calc(50% - 12px);
                right: 0;
            }
            &::after{
                content:"";
                display: inline-block;
                width: 20px;
                height: 20px;
                background-color: $c-white;
                border-radius: 50%;
                position: absolute;
                top: calc(50% - 10px);
                right: 2.5px;
                @include transitions($prop: right);
            }
        }
        input{
            right: 30px;
        }
    }
    .rs-2{
        padding-left: 10px;
        position: relative;
        input{
            left: -30px;
        }
    }
    label{
        margin: 0;
        color: $c-grey-dark;
        cursor: pointer;
    }
    input{
        position: absolute;
        top: calc(50% - 12px);
        width: 30px;
        height: 24px;
        opacity: 0;
        z-index: $z-lower;
        &:checked + label{
            color: $c-text-dark;
            &::after{
                right: calc(60px - 20px - 2.5px);
            }
        }
        cursor: pointer;
    }
}

// Select
.select{
    width: 100%;
    label{
        @include font-set(body-small);
        margin: 0;
    }

    .wrp{
        position: relative;
        display: inline-block;
        width: 100%;
        height: 48px;
        &::after{
            content: "";
            display: inline-block;
            @extend .svg;
            @extend .svg-arrow2-r;
            transform: rotate(90deg);
            position: absolute;
            right: 0;
            top: calc(50% - 16px);
        }
    }

    select{
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        outline: 0;
        box-shadow: none;
        background: none;
        height: 100%;
        width: 100%;
        border-style: solid;
        border-color: $c-grey-light;
        border-width: 0 0 1px 0;
        padding: 0 5px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        z-index: $z-lower;
        color: $c-text-dark;

        &::-ms-expand {
            display: none;
        }

        &:focus{
            border-bottom-color: $c-grey-dark;
        }
    }
}

// Radio List
.radio-list{
    .rl{
        &-item{
            label{
                padding-left: 42px;
            }
        }
        &-content{
            display: flex;
            align-items: center;
        }
        &-media{
            margin-right: 20px;
            text-align: center;
        }
        &-text{
            flex: 1;
        }
    }
    &.rl-block{
        .rl-item{
            label{
                padding: 20px 20px 20px 60px;
                background-color: $c-bg-light;
                width: 100%;
                border: 1px solid $c-bg-light;
                &::before, &::after{
                    top: calc(50% - 16px);
                    left: 10px;
                }
            }
            .input{
                display: block;
            }
            input{
                top: calc(50% - 16px);
                left: 10px;
                &:checked + label{
                    border: 1px solid $c-primary;
                }
            }
            + .rl-item{
                margin-top: 5px;
            }
        }
    }
    &.rl-block-reverse{
        .rl-item{
            label{
                padding: 20px 60px 20px 20px;
                background-color: $c-bg-light;
                width: 100%;
                border: 1px solid $c-bg-light;
                &::before, &::after{
                    top: calc(50% - 16px);
                    left: auto;
                    right: 10px;
                }
            }
            .input{
                display: block;
            }
            input{
                top: calc(50% - 16px);
                left: auto;
                right: 10px;
                &:checked + label{
                    border: 1px solid $c-primary;
                }
            }
            + .rl-item{
                margin-top: 5px;
            }

            // Box Type
            &.rl-box{
                label{
                    padding: 20px;
                    border: none !important;
                    &::before, &::after{
                        top: 10px;
                    }
                }
                input{
                    top: 10px;
                }
            }
        }
    } 
}

// Forms
.form{
    &-rows {
        .mt-40 {
            margin-top: 40px;
        }
    }
    &-row{
        margin-bottom: 20px;
        &-info {
            a {
                text-decoration: underline;
            }
            &-col {
                display: flex;
                align-items: flex-start;
                .icon {
                    margin-right: 10px;
                }
            }
        }
    }
    &-error-msg{
        margin-top: 5px;
        @include font-set(body-small);
        color: $c-error;
    }
    &-result{
        justify-content: center;
        flex-direction: column;
        height: 100%;
        &-title{
            margin: 20px 0 10px;
            text-align: center;
            @include font-set(h);
        }
        &-text{
            text-align: center;
            p{
                margin-bottom: 20px;
            }
            .icon{
                color: $c-success;
            }
        }
        &-button{
            margin-top: auto;
            .btn{
                width: 100%;
            }
        }
    }
    &-col{
        &-2{
            &-1{
                padding-right: 7px;
            }
            &-2{
                padding-left: 7px;
            }
        }
        &-3{
            &-1{
                padding-right: 7px;
            }
            &-2{
                padding-left: 7px;
                padding-right: 7px;
            }
            &-3{
                padding-left: 7px;
            } 
        }
    }
    &-info{
        margin-bottom: 20px;
        color: $c-text-weak;
    }
}

.chk-row{
    + .chk-row{
        margin-top: 10px;
    }
}

// Password Indicator
.ind{
    &-bar{
        display: flex;
        i{
            background-color: $c-grey-light;
            height: 4px;
            width: 25%;
            + i{
                margin-left: 2px;
            }
        }
    }
    &-info{
        text-align: right;
        margin-top: 5px;
        height: 32px;
        line-height: 32px;
        span{
            display: none;
            align-items: center;
        }
    }
}

.indicator{
    margin-top: 5px;
    @each $k, $v in $c-indicator{
        &[data-level="#{$k}"]{
            @for $i from 1 to $k + 1{
                .ind-bar i:nth-child(#{$i}){
                    background-color: $v;
                }
            }

            .ind-info span:nth-child(#{$k}){
                display: inline-flex;
            }
        }
    }
}

[class*="-fwd-bar"]{
    height: 4px;
    background-color: $c-grey-border;
}

// Progress Header
$prg-max-step: 4;
.progress{
    [class*="prg-step-"]{
        display: none;
    }
}

.prg{
    &-header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: $c-bg-body;
        z-index: $z-higher;
    }
    &-inner{
        display: flex;
        align-items: center;
        padding: 9px 20px 9px 15px;
        position: relative;

        &::before, &::after{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            font-size: 0;
            border-bottom: 2px solid $c-grey-light;
            position: absolute;
            bottom: -2px;
            left: 0;
        }
        &::after{
            border-bottom: 2px solid $c-primary;
        }
    }
    &-back{
        margin-right: auto;
        a{
            display: inline-block;
        }
    }
    &-title{
        flex: 1;
        @include font-set(h-xsmall);
        padding-left: 10px;
    }
    &-summary{
        margin-left: auto;

        &::before{
            content: attr(data-step);
        }
        &::after{
            content: attr(data-total);
        }
    }
}

// Progress Bar
@for $i from 2 through $prg-max-step{
    @for $e from 1 through $i{
        .progress[data-step="#{$e}"][data-total="#{$i}"] .prg-inner::after{
            width: 100% / $i * $e;
        }
    }
}
// Steps
@for $i from 1 through $prg-max-step{
    .progress[data-step="#{$i}"] .prg-step-#{$i}{
        display: block;
    }
}

// Accordions
.acc-{
    &item{
        border-top: 1px solid $c-grey-light;
        position: relative;

        > button{
            display: block;
            width: 100%;
            padding: 24px 42px 24px 0;
            position: relative;

            &::after{
                content: "";
                display: inline-block;
                @extend .svg;
                @extend .svg-plus;
                position: absolute;
                top: calc(50% - 16px);
                right: 0;
            }
        }
    }

    &badge {
        position: absolute;
        top: 50%;
        right: 42px;
        transform: translateY(-50%);
        font-size: 0;
    }


    &body{
        height: 0;
        overflow: hidden;
    }
}

.accordion{
    padding: 0;
    margin: 0;
    list-style: none;
    
    .active{
        .acc-{
            &item{
                button{
                    font-weight: $fw-bold;

                    &::after{
                        @extend .svg-minus;
                    }
                }
            }
            
            &body{
                padding: 10px 0 20px;
                height: auto;
                overflow: visible;
            }
        }
    }
}

.qm{
    @extend .svg;
    @extend .svg-bubble-qm;
}

// Video
.video{
    text-align: center;

    video{
        max-width: 880px;
    }
}

// Container No Padding
.no-pad{
    padding-left: 0;
    padding-right: 0;
}

// Buttons
.btn {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $btn-h-default;
    padding: 0 50px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: $btn-h-default * $btn-br-factor;
    font-family: $font1;
    @include font-set(body-small);
    color: $body-color;
    font-weight: $fw-normal;
    text-align: left;
    text-decoration: none;
    @include transitions($prop: background-color);
    outline: none;
    cursor: pointer;
    appearance: none;
    user-select: none;

    span{
        visibility: hidden;
    }

    &::before, &::after{
        content: attr(title);
        position: absolute;
        width: 100%;
        text-align: center;
        @include transitions($dur: 'now', $delay: .111s);
        text-indent: 0;
    }

    &::after{
        display: none;
    }

    &-primary {
        background-color: $c-primary;
        border-color: $c-primary;
        color: $c-white;
        &.selected {
            background-color: darken($c-primary, 5%);
            border-color: darken($c-primary, 5%);
            color: $c-white;
        }
    }

    &-secondary {
        background-color: $c-secondary;
        border-color: $c-secondary;
        color: $c-text-light;
        &.selected {
            background-color: darken($c-secondary, 5%);
            border-color: darken($c-secondary, 5%);
            color: $c-text-light;
        }
    }

    &-light {
        background-color: $c-white;
        border-color: $c-white;
        color: $c-text-dark;
        &.selected {
            background-color: darken($c-white, 5%);
            border-color: darken($c-white, 5%);
            color: $c-text-dark;
        }
    }

    // Outline Buttons
    &-outline {
        &-primary {
            border-color: $c-dark;
            background-color: $c-dark;
            color: $c-text-light;
            &.selected {
                background-color: $c-dark;
                color: $c-text-light;
            }
        }

        &-secondary {
            @extend .btn-light;
        }
    
        &-light {
            border-color: $c-white;
            color: $c-text-light;
            &.selected {
                background-color: $c-white;
                color: $c-text-dark;
            }
        }
    
        &-dark {
            border-color: $c-dark;
            color: $c-text-dark;
            &.selected {
                background-color: $c-dark;
                color: $c-text-light;
            }
            &.btn-selection{
                &.selected {
                    background-color: $c-primary;
                    border-color: $c-primary;
                    color: $c-text-light;

                    &[data-sel-title]::before, &[data-sel-title]::after{
                        content: attr(data-sel-title);
                    }
                }
            }
        }
    }

    &-badgy{
        i{
            font-style: normal;
            position: absolute;
            left: 6px;
            top: 50%;
            transform: translateY(-50%);

            .icon{
                font-size: 32px;
            }
        }
    }

    // Sizes
    &-small {
        height: $btn-h-small;
        padding: 0 30px;
        border-radius: $btn-h-small * $btn-br-factor;
    }

    &-narrow{
        padding: 0 25px;
    }

    // page top
    &-page-top {
        display: none;
        position: absolute;
        right: 20px;
        top: #{$dm-control-height + 96px};
        transform: rotate(180deg);
        border: 1px solid #474747;
        border-radius: 50%;
        padding: 3px;
    }

    // Disabled
    &-disabled{
        opacity: .4;
        pointer-events: none;
    }

    // page back
    &-page-back {
        margin-right: 10px;
    }

    // remove
    &-remove{
        display: inline-block;
        @extend .svg;
        @extend .svg-delete;
    }
}

// Price
.prc {
    &-first {
        text-decoration: line-through;
    }
    &-third{
        font-weight: $fw-bold;
    }
}

// Fake Dropdown
.fake-dropdown{
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba($c-grey-border-dark, 0.5);
    padding: 20px 0 15px;
    width: 100%;
    &::after{
        content: "";
        display: inline-block;
        @extend .svg;
        @extend .svg-arrow-b;
    }
    .drp-content{
        padding-right: 50px;
        flex: 1;
        b{
            font-weight: $fw-bold;
            display: block;
            margin-bottom: 5px;
        }
    }
}

// Nav Tabs Fix
.nav{
    flex-wrap: nowrap;
    text-align: center;
    width: 100%;
    position: relative;

    &-link{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &-pills{
        padding: 0 15px;

        .nav-link{
            @include font-set(body-small);
            margin: 15px 5px;
            padding: 11px 22px;
            white-space: nowrap;
        }
    }

    &-tabs{
        .nav-link{
            @include font-set(h-tiny);
        }

        &:not(.tab-alt){
            box-shadow: inset 0px -3px 0px 0px $c-grey-light;

            .nav-link{
                border-bottom-width: 3px;
                border-bottom-color: transparent;
                white-space: nowrap;

                &.active{
                    border-bottom-color: $c-primary;
                }
            }
        }

        &.tab-alt{
            .nav-link{
                border-top-width: 4px;
                background-color: $c-secondary;
                color: $c-light;
                flex: 1;

                &.active{
                    border-top-color: $c-primary;
                    background-color: transparent;
                    color: $c-secondary;
                    @include transitions();
                }
            }
        }

        &.tab-ext{
            align-items: flex-end;
            box-shadow: none;
            .nav-link{
                border-top-width: 4px;
                background-color: $c-grey-border;
                color: $c-text-dark;
                padding: 0 15px;
                height: 40px;
                border: none;

                &.active{
                    background-color: $c-white;
                    color: $c-primary;
                    border-radius: 5px 5px 0 0;
                    padding: 0 20px 0;
                    height: 45px;
                }
                &:not(.active) + .nav-link{
                    margin-left: 1px;
                }
            }
        }
        @at-root {
            // Colored Theme
            .tab-ext-colored{
                .nav-tabs.tab-ext{
                    padding: 0 10px;
                    .nav-link{
                        background-color: $c-bg-light;
                        &.active{
                            background-color: $c-bg-colored;
                            color: $c-text-dark;
                        }
                    }
                }
                .tab-content{
                    .tab-pane{
                        padding: 30px 20px;
                        &.active{
                            background-color: $c-bg-colored;
                        }
                    }
                }
            }

            // Simple Theme
            .tab-simple{
                .nav-tabs{
                    box-shadow: none;
                    .nav-link{
                        border: none;
                        background-color: $c-bg-body;
                        &.active{
                            background-color: $c-bg-light;
                            color: $c-text-dark;
                        }
                    }
                }
                .tab-content{
                    .tab-pane{
                        background-color: $c-bg-light;
                        padding: 30px 20px 20px;
                    }
                }
            }
        }
    }
}

.tab{
    &-pane{
        &.fade.show{
            opacity: 1;
        }
    }
}

// Page Cover
.cover{
    &-media{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: $z-lower;

        .responsive{
            position: absolute;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: auto;
            overflow: visible;

            &::before{
                display: none;
            }
            
            img{
                height: 100%;
                position: static;
                width: auto;
                max-width: none;
            }
        }
    }

    &-body{
        position: relative;
        z-index: $z-low;
        width: 100%;
        text-align: center;
        opacity: 1;
        margin-top: auto;
        @include transitions($dur: 'normal', $func: 'eut');
        padding-bottom: 90px;

        h1{
            color: $c-text-light;
            @include font-set(h-large);
            margin-bottom: 0;
            display: inline-block;
            max-width: 210px;
        }
    }
}

.page-cover{
    position: relative;
    @include transitions($dur: 'normal', $func: 'eio');
    overflow: hidden;
    display: flex;

    &::before{
        content: "";
        display: block;
        width: 100%;
        height: 60px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: $z-low;
        background: linear-gradient(180deg, rgba($c-black, .5) 0%, rgba($c-black, 0) 100%);
    }

    .icon{
        position: absolute;
        bottom: 20px;
        left: calc(50% - 16px);
        color: $c-text-light;
        z-index: $z-low;
    }

    &.static{
        .responsive{
            width: auto;
            height: auto;
        }
    }
    &.cover-left{
        .cover-media .responsive{
            left: 0;
            transform: translate3d(0, -50%, 0);
        }
    }
    &.cover-right{
        .cover-media .responsive{
            left: auto;
            right: 0;
            transform: translate3d(0, -50%, 0);
        }
    }
}

// Section Title
.section-title{
    @include font-set(h);
    text-align: center;
    margin: 50px auto 20px;
    max-width: 360px;
    padding: 0 20px;
}

.seo-text{
    margin: 60px auto;
}

// breadcrumb
.breadcrumb{
    padding: 15px 20px;
    display: flex;
    @include font-set(body-small);

    li{
        display: inline-flex;
        align-items: center;
        
        &:not(:last-child)::after{
            flex: none;
            content: "";
            display: inline-block;
            @extend .svg;
            @extend .svg-arrow2-r;
            transform: scale(.75);
        }

        + li {
            padding-left: 10px;
        }
    }

    &.brd-type-cover{
        position: absolute;
        z-index: $z-normal;

        li::after{
            @extend .svg-arrow2-r-w;
        }

        span{
            color: $c-text-light;
        }
    }
}

// Mini Popup
.mini-popup{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $z-ultra;
    width: 100%;
    // padding: 0 20px;
    border-radius: $br-popup-bottom $br-popup-bottom 0 0;
    background: $c-bg-body;
    color: $body-color;
    opacity: 0;
    transform: translateY(100%);
    transition: all .2s ease;
    overflow: hidden;

    &.activated{
        opacity: 1;
        transform: translateY(0);
    }
}

.mnp{
    &-menu {
        padding: 40px 20px;
        > ul > li {
            > button, > a {
                width: 100%;
                padding: 0 20px;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            + li {
                border-top: 1px solid $c-grey-border;
            }
        }
    }
    &-content{
        padding: 20px 20px 35px;
        max-height: calc(100vh - 60px);
        overflow: auto;
        p{
            margin-bottom: 5px;
        }
    }
    &-title{
        margin-bottom: 20px;
        @include font-set(h);
    }
    &-header{
        position: relative;
        // height: 20px;
        margin: 0 20px;
        button{
            position: absolute;
            top: 10px;
            right: -10px;
            z-index: $z-low;
        }
        .mnp-title{
            padding-top: 20px;
            @include font-set(h-xsmall);
        }
    }
    &-button{
        text-align: center;
        padding-top: 20px;
    }
    &-gallery{
        position: relative;
        .mnp-header{
            height: 50px;
            position: absolute;
            top: -50px;
            left: 20px;
            width: calc(100% - 40px);
            margin: 0;
            .icon{
                color: $c-text-light;
            }
        }
    }
}

.ajx-mnp-ready, .menu-mnp-ready{
    overflow: hidden;
    #overlay {
        z-index: $z-super;
        display: block;
    }
}

// Table
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    th {
        font-weight: $fw-bold;
    }
    th,
    td {
        padding: 5px 10px;
    }
}

.table-responsive{
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    td{
        white-space: nowrap;
    }
    th{
        vertical-align: top;
        text-align: center;
    }
}

// Content Loading
.content-loading {
    text-align: center;
    padding: 90px $container-padding-x;
    .t {
        @include font-set(h-xsmall);
    }
    .md {
        margin: 60px auto 0;
    }
}


// 404
.pnf{
    margin: 95px 0;
    &-block1{
        display: flex;
        margin-bottom: 70px;
        padding: 0 40px;
        justify-content: center;
        align-items: center;
    }
    &-sign{
        margin-right: 20px;
        .icon{
            font-size: 64px;
            color: $c-primary !important;
        }
    }
    &-title{
        b{
            display:block;
            @include font-set(h-large);
        }
        span{
            @include font-set(h-small);
        }
    }
    &-block2{
        text-align: center;
        p{
            padding: 0 40px;
            margin-bottom: 30px;
        }
    }
    &-buttons{
        .btn{
            padding: 0 24px;
            & + .btn{
                margin-left: 20px;
            }
        }
    }
}

// Dark Mode Control
$dm-notifier-height: 170px;
.drk{
    &-control{
        background-color: $c-bg-grey;
        text-align: center;
        padding: 40px 20px 35px;
        border-bottom: 10px solid $c-bg-dark;
        &-title{
            @include font-set(h-paragraph);
            margin-top: 25px;
        }
        &-desc{
            margin-top: 5px;
        }
        &-link{
            margin-top: 30px;
        }
    }
    &-switch{
        .radio-switch{
            display: inline-flex;
            input{
                width: 15.5px;
                &:checked + label{
                    color: $c-text-light;
                }
            }
            label{
                color: $c-dm-off-text;
            }
            .rs-1{
                padding-right: 41px;
                label{
                    &::before{
                        width: 31px;
                        background-color: $c-success;
                    }
                }
                input{
                    right: 15.5px;
                    &:not(:checked) + label::after{
                        @extend .svg;
                        @extend .svg-switch-success;
                        width: 20px;
                        height: 20px;
                    }
                    &:checked + label{
                        &::after{
                            right: 9.5px;
                        }
                        &::before{
                            background-color: $c-switch-off;
                        }
                    }
                }
            }
            .rs-2{
                input{
                    left: -15.5px;
                }
            }
        }
    }
    &-save{
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $c-dm-green;
        .icon{
            color: $c-dm-green;
            font-size: 70px;
            margin-right: 12px;
        }
        span{
            font-size: 40px;
        }
        small{
            font-size: 20px;
            align-self: flex-start;
        }
    }
    &-notify{
        background-color: $c-bg-dark;
        width: 100%;
        height: $dm-notifier-height;
        padding: 40px 25px 24px;
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: $z-high;
        transition: all .5s ease;
        &-media{
            flex: 0 0 46px;
            margin-right: 24px;
            .icon{
                font-size: 46px;
                color: $c-dm-green;
            }
        }
        &-title{
            @include font-set(h-paragraph);
            margin-bottom: 16px;
            color: $c-text-light;
        }
    }
    &-close{
        position: absolute;
        top: 10px;
        right: 10px;
        color: $c-text-light;
    }
    &-notifier-opened{
        .drk-notify{
            display: flex;
        }
        &:not(.header-opaque){
            #site-header{
                height: $dm-notifier-height;
            }
            .header-top{
                top: $dm-notifier-height;
            }
        }
        &.header-opaque{
            .drk-notify{
                transform: translateY(-100%);
            }
        }
    }
}

// Warning
.warn{
    padding: 20px;
    background-color: $c-bg-warn;
    &::before{
        content: "";
        @extend .svg;
        @extend .svg-warn;
        display: block;
        width: 24px;
        height: 24px;
        margin-bottom: 8px;
    }
    &-title{
        @include font-set(h-small);
        margin-bottom: 10px;
    }
    &-desc{
        p{
            margin-bottom: 0;
        }
        p + p, ul + p{
            margin-top: 15px;
        }
    }
}