// Category Page
.brand-list{
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, minmax(auto, 165px));
    grid-template-rows: auto;
    gap: 5px;

    li{
        height: 165px;
        @include responsive($w: 300, $h: 160);
        
        a{
            display: flex;
            align-items: center;
            background-color: $c-bg-light;
            justify-content: center;
            height: 100%;
        }
    }
}

.category-list{
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, minmax(auto, 165px));
    gap: 5px;

    li{
        text-align: center;
    }
}

.category-cover{
    height: 375px;
}


// Product List Page
.top-panel {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px $container-padding-x;
    > div {
        display: flex;
        align-items: center;
    }
    .pnl {
        &-left {
            margin-right: auto;
        }
        &-middle {
            width: 100%;
            margin-top: 20px;
        }
        &-right {
            margin-left: auto;
        }
    }
    h1 {
        @include font-set(h);
        margin: 0;
    }
}

.primary-filters {
    margin: 0 (-$container-padding-x);
    padding: 0 15px;
    > a {
        flex: none;
        margin: 0 5px;
        padding: 0 15px;
    }
}

.btn-filter {
    display: flex;
    align-items: center;
    margin-left: 5px;
    line-height: 1;
    // > svg {
    //     margin-left: 10px;
    // }
}

.btn-sort {
    display: flex;
    align-items: center;
    line-height: 1;
    .icon{
        margin-left: 10px;
        font-size: 14px;
    }
}

.products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(auto, 355px));
    grid-auto-flow: dense;
    gap: 30px;
    justify-content: center;
    padding: 0 10px;
}

// Product Scroller
.product-scroll{
    display: block;
    padding: 2px 15px;
    
    .prd{
        display: inline-block;
        max-width: 315px;

        & + .prd{
            margin-left: 10px;
        }
    }
}



.compare-info {
    display: none;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $z-normal;
    width: 100%;
    height: 60px;
    padding: 0 $container-padding-x;
    animation: toTop .32s ease;
    background: $c-white;

    .btn-compare {
        padding: 0 30px 0 40px;
        .qty-badge {
            background: $c-white;
            color: $c-black;
        }
    }
    
    .compare-activated & {
        display: flex;
    }
}

.category-explorer {
    $i: 50px;
    display: none;
    align-items: center;
    margin: 30px auto;
    padding-top: $i;
    > a {
        flex: 1;
        background: $c-light;
        display: flex;
        align-items: center;
        @include transitions();
    }
    figure {
        width: 200px;
        margin-top: -$i;
        @include transitions($func: 'eut', $dur: 'faster');
    }
    .title {
        margin: 5px 0 0 0;
        @include font-set(h);
    }

    .prev {
        justify-content: flex-start;
    }
    .next {
        border-left: 4px solid $body-bg;
        justify-content: flex-end;
    }
}

// Assistant
.page-product-list{
    #assistant{
        opacity: 1;
        @include transitions();

        .bnr-info{
            display: inline-flex;
            align-items: center;

            //pulse dot
            @at-root {
                .quiz{
                    flex: none;
                    display: inline-block;
                    position: relative;
                    margin-right: 6px;
                    width: 20px;
                    height: 20px;
                    
                    &::before{
                        content: "";
                        display: inline-block;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: $c-primary;
                        position: absolute;
                        top: calc(50% - 2.2px);
                        left: calc(50% - 2.6px);
                        animation: beat 1.4s linear; animation-iteration-count: infinite;
                    }
    
                    &::after{
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        border: 1px solid $c-primary;
                        animation: pulse 1.4s linear; animation-iteration-count: infinite;
                    }
                }
            }
        }

        .bnr-button{
            display: flex;

            .btn{
                flex: 1;
                padding: 0 20px;
            }
        }

        .bnr-footer{
            margin-top: 25px;
            text-align: center;
        }

        .bnr-media{
            display: none;
        }
    }
}

.products{
    #assistant{
        .bnr{
            &-iframe{
                display:none;
            }
        }
    }
}

// .products {
//     #assistant{
//         margin: 0 -10px;
//         width: auto;
//         grid-column: 1 / -1;

//         &.compact{
//             .banner::before{
//                 content:"";
//                 display: inline-block;
//                 width: 32px;
//                 height: 32px;
//                 @extend .svg;
//                 @extend .svg-arrow-b;
//                 position: absolute;
//                 top: 20px;
//                 right: 20px;
//             }

//             .bnr-footer, .bnr-button{
//                 display: none;
//             }
//         }
//     }
// }

// Live Chat
#live-chat{
    margin: 20px auto;
    padding: 0 10px;
}

.lcs{
    background-color: $c-bg-live-chat;
    padding: 20px 42px 13px 13px;
    position: relative;
    .btn-close{
        position: absolute;
        top: 50%;
        right: 10px;
        color: $c-text-light;
        transform: translateY(-50%);
    }
    &-inner{
        padding-left: 100px;
        position: relative;
    }
    &-media{
        @include responsive(105, 37);
        width: 85px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &-info{
        margin-bottom: 10px;
        color: $c-text-light;
    }
    &-button{
        i{
            display: block;
            height: 12px;
            width: 12px;
            border-radius: 100%;
            background-color: $c-online;
            left: 10px;
        }
    }
}

// Category
.page-category{
    .tab-content{
        padding: 30px 20px 50px;
    }
}

// Promotion List
.page-promotion-list{
    .banner{
        margin-bottom: 5px;

        ul{
            padding-left: 15px;
            margin: 10px 0 20px;
        }
    }
}

// Promotion User OTP
.otpromo{
    text-align: left !important;
    display: flex;
    width: calc(100% + 5px);
    margin: 0 0 50px -5px;
    &-block1{
        padding: 0 8px 0 0;
        .icon{
            color: $c-primary;
        }
    }
    &-title{
        @include font-set(h-small);
    }
    &-text{
        margin-top: 20px;
    }
    &-form{
        margin-top: 20px;
        display: flex;
        align-items: center;
        &-input{
            margin-right: 20px;
        }
    }
    &-info{
        margin-top: 20px;
        @include font-set(body-small);
    }
}

// Promotion Detail
.page-promotion-detail{
    .bnr-promo{
        padding: 30px 0 95px;
    }
}

// Compare Page
.page-compare {
    background: $c-bg-light;
    #site-main {
        padding-bottom: 100px;
    }
}
.compare-search-ready {
    .suggested-searches {
        display: none;
    }
    .suggested-results {
        display: block;
    }
}
.compare-holder{
    &.title-block{
        .compare-top-panel{
            background-color: $c-bg-body;
        }
    }
    .swiper-slide{
        width: 160px;
        + .swiper-slide{
            margin-left: 5px;
        }
        &:first-child{
            margin-left: 15px;
        }
        &:last-child{
            margin-right: 15px;
        }
    }
    .swiper-compare {
        &-prev,
        &-next {
          position: fixed;
          top: 50%;
          z-index: $z-lower;
          width: 32px;
          height: 32px;
          margin-top: -16px;
          @extend .svg;
          @extend .svg-swiper-next-dark;
          outline: none;
          display: none;
        }
        &-prev {
          left: calc(((100vw - 16px - 880px) / 2) - 60px);
          transform: scale(-1);
        }
        &-next {
          right: calc(((100vw - 16px - 880px) / 2) - 60px);
        }
    }
}
.compare-top-panel {
    position: sticky;
    top: $header-height-mobile - 20px;
    z-index: $z-low;
    padding-top: 20px;

    .swiper-pagination {
        position: absolute;
        margin-top: 0;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}
.cmp-item {
    border-top: 3px solid $c-primary;
    background: $c-white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $c-bg-light;
    .cmp-prd-title {
        position: relative;
        padding: 10px;
        font-weight: $fw-bold;
        flex: 1;
        height: 60px;
        > span {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    .cmp-remove{
        flex: 0 0 32px;
        margin-right: 10px;
    }
    // button.cmp-prd-title{
    //     &:after {
    //         @extend .svg;
    //         @extend .svg-more;
    //         content: "";
    //         position: absolute;
    //         right: 0;
    //         top: 50%;
    //         transform: translateY(-50%);
    //     }
    // }
}

.cmp-add{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $cmp-add-bg-color;
    text-align: center;
    width: 100%;
    height: 235px;
    span{
        font-weight: $fw-bold;
        display: block;
    }
    .icon{
        margin-bottom: 15px;
        font-size: 80px;
        color: $cmp-add-icon-color;
    }
    &-inner{
        transform: translateY(-32px);
    }
    &-header{
        height: 64px;
        background-color: $cmp-add-bg-color;
        .cmp-add{
            height: 100%;
            width: 100%;
        }
    }
}

#compare-products, #compare-all-features{
    .swiper-pagination {
        display: none;
    }
}

.compare-primary-features {
    margin-top: 5px;
    padding: 0 10px;
    background: $c-white;
    .item {
        position: relative;
        text-align: center;
        padding: 20px 10px;
        + .item {
            border-top: 1px solid $c-bg-light;
        }
        &.no-attr{
            figure span{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                font-size: 24px;
            }
            .t{
                visibility: hidden;
            }
        } 
    }
    .prd-reviews {
        justify-content: center;
    }
    .responsive {
        max-width: 32px;
        margin: auto;
    }
    .t {
        margin-top: 10px;
    }
    .qm {
        position: absolute;
        top: 10px;
        right: 0;
    }
}

#compare-all-features {
    .acc-item {
        margin: 0 $container-padding-x;
    }
    .item {
        position: relative;
        margin-right: 20px;
        + .item {
            margin-top: 20px;
        }
        .qm {
            vertical-align: middle;
        }
        &.same{
            display: none;
        }
        &.no-attr .t{
            visibility: hidden;
        }
    }
}
.btn-open-acc-items {
    text-decoration: underline;
    margin: 20px auto;
    display: block;
}

.compare-bottom-panel {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: $z-low;
    width: 100%;
    background: $c-white;
}

// Search Results Page
.page-search-results {
    #site-main {
        padding-bottom: 60px;
    }
    .page-title {
        text-align: left;
        padding: 0;
        h1 {
            @include font-set(h-small, true);
        }
    }
    .nav {
        margin-top: 10px;
        width: auto;
        &-link {
            flex: 1;
        }
    }
    .tab-content {
        padding: 0;
    }
    .no-result{
        padding: 100px 0;
        text-align: center;
        .icon{
            margin-bottom: 10px;
            color: $c-text-dark;
        }
    }
}



// Product Detail Page
.page-product-detail{
    #site-footer{
        margin-top: 0;
    }
}

.pdp {
    background: #ECEDEF;
    // color: $c-white;
    // padding-top: $header-height-mobile;
    &-breadcrump{
        display: flex;
        align-items: center;
        width: 100%;
        padding-top: 5px;
        .breadcrumb {
            padding-top: 4px;
            padding-bottom: 4px;
            // li::after{
            //     @extend .svg-arrow2-r-w;
            // }
        }
        .btn-more{
            margin-left: auto;
            margin-right: 15px;
        }
    }
    &-more {
        position: relative;
        z-index: $z-low + 1;
        display: none;
    }
    &-gallery {
        width: 100%;
        position: relative;
        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
        .swiper-slide {
            padding: 0 15px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        &-360{
            display: none;
        }
    }
    &-ar {
        height: 0;
        text-align: center;
        overflow: hidden;
        margin-top: 15px;
        @include transitions($dur: 'normal');
        @at-root {
            #btn-ar{
                padding-right: 25px;
                justify-content: end;
                span{
                    visibility: visible;
                    white-space: nowrap;
                }
                &::before{
                    display: none;
                }
                .icon {
                    color: $c-primary;
                }
                .stroke{
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    width: $btn-h-default;
                    height: $btn-h-default;
                    circle{
                        stroke-dasharray: 30 270;
                        stroke-dashoffset: 0;
                        fill: none;
                        transform-origin: center;
                        stroke-width: 3px;
                        stroke: $c-primary;
                        animation: rotating 1s linear infinite;
                    }
                }
            }
            .ar-button-ready{
                .pdp-ar{
                    height: 55px;
                }
                #btn-ar{
                    animation: pdpAR 3s ease forwards;
                }
            }
        }
    }
    &-360 {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: $z-lower + 1;
        @at-root {
            .btn-360 {
                background: $c-white;
                color: $body-color;
                border-radius: 50%;
                padding: 9px;
            }
        }
    }
    &-details {
        padding: 0 $container-padding-x;
    }
    &-energy-label {
        vertical-align: super;
        margin-right: 4px;
        display: inline-block;
    }
    &-name {
        padding: 0 20px;
        margin-top: 15px;
        text-align: center;
        line-height: 30px;
        .title {
            @include font-set(h);
            margin: 0;
            display: inline;
            line-height: 30px;
        }
    }
    &-reviews {
        margin-top: 5px;
        justify-content: center;
    }
    &-sizes {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        a {
            display: inline-block;
            min-width: 55px;
            padding: 8px 5px;
            border: 1px solid currentColor;
            color: $c-grey-dark;
            text-align: center;
            margin: 2.5px;
            &.selected {
                background: $c-light;
                color: $c-text-dark;
                border-color: $c-light;
            }
        }
    }
    &-colors {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        a {
            border: 1px solid transparent;
            border-radius: 50%;
            padding: 4px;
            margin: 2.5px;
            &::before {
                content: "";
                display: block;
                border: 10px solid currentColor;
                border-radius: 50%;
            }
            &.selected {
                border-color: $c-light;
            }
        }
    }
    &-warranty{
        margin-top: 20px;
        // border-top: 1px solid $c-pdp-border;
        @at-root {
            .btn-warranty {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 14px 20px;
                background-color: $c-grey-dark;
                .icon-plus {
                    margin-left: auto;
                }
            }
        }
    }
    &-installation{
        border-top: 1px solid $c-pdp-border;
        @at-root {
            .btn-installation {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 8px 0;
                .icon-plus {
                    margin-left: auto;
                }
            }
        }
    }
    &-purchase {
        // height: 126px;
        padding: 10px 0 15px;
        // border-top: 1px solid $c-pdp-border;
        &-inner {
            display: flex;
            align-items: center;
            .left {
                flex: 1;
                padding-right: 30px;
            }
        }

        $pl: 50px;
        &-fixed {
            .btn-switch {
                display: none;
                position: absolute;
                top: 18px;
                left: 10px;
                @include transitions();
            }
            .t {
                display: none;
                position: absolute;
                top: 15px;
                left: $pl;
                width: 50%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @include font-set(body-small);
                @include transitions();
            }
        }

        &-ready {
            .pdp{
                .pdp-purchase-fixed {
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: $z-high;
                    width: 100%;
                    background: $c-bg-body;
                    color: $body-color;
                    padding: 15px 20px 11px $pl;
                    .btn-switch{
                        display: block;
                    }
                    .left{
                        padding-right: 10px;
                    }
                }
                // .pdp-purchase-inner {
                //     align-items: flex-end;
                // }
                .pdp-add-to-cart{
                    max-width: 205px;
                }
                .pdp-badge1 {
                    display: none;
                }
            }
            @at-root {
                .compare-search-ready .pdp-purchase-fixed{
                    z-index: $z-supnormal !important;
                }
            }
        }

        &-opened {
            overflow: hidden;
            .pdp{
                .pdp-purchase-fixed {
                    padding: 18px 20px 30px;
                    .btn-switch {
                        transform: scale(-1);
                    }
                    .t {
                        display: block;
                        top: 29px;
                        width: calc(100% - #{$pl} - 20px);
                    }
                    .pdp-tab-steps {
                        display: block;
                    }
                    .pdp-purchase-inner {
                        align-items: center;
                        padding-top: 20px;
                        border-top: 1px solid $c-grey-border;
                    }
                    // .pdp-badge1 {
                    //     display: flex;
                    // }
                }
            }
            #overlay {
                display: block;
                z-index: $z-high - 1;
            }
        }

        &.out-of-stock{
            .left{
                display: none;
            }
            .pdp-add-to-cart{
                width: 70%;
                margin: 0 auto;
                .btn{
                    width: 100%;
                }
            }

            @at-root {
                .pdp-purchase-ready{
                    .pdp-purchase.out-of-stock{
                        .pdp-add-to-cart{
                            visibility: hidden;
                        }
                    }
                }
                .pdp-purchase-opened{
                    .pdp-purchase.out-of-stock{
                        .pdp-purchase-inner{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &-badge1 {
        display: flex;
        align-items: center;
        // margin-bottom: 25px;
        > .responsive {
            flex: none;
            margin-right: 5px;
            max-width: 32px;
        }
        > span {
            @include font-set(body-small);
        }
    }
    &-badge2{
        margin-top: 2px;
        .prc-third{
            &:not(:empty){
                font-weight: $fw-bold;
            }
            &-info:not(:empty){
                padding-left: 4px;
                @include font-set(body-small);
            }
        }
    }
    &-price {
        @include font-set(body-medium);
        height: 14px;
        .prc {
            &-first {
                color: $c-grey-dark;
                margin-right: 10px;
            }
            &-last {
                font-weight: $fw-bold;
            }
        }
    }
    &-add-to-cart {
        flex: none;
        margin-left: auto;
        .btn {
            padding: 0 20px;
        }
    }
    &-info1 {
        margin-top: 15px;
        border-top: 1px solid $c-pdp-border;
        border-bottom: 1px solid $c-pdp-border;
        @at-root {
            .btn-info1 {
                display: flex;
                align-items: center;
                width: 100%;
                background: rgba($color: $c-grey-dark, $alpha: .4);
                padding: 14px 20px;
                .icon-clock {
                    margin-right: 8px;
                }
                .icon-arrow-b-small {
                    transform: rotate(-90deg);
                    margin-left: auto;
                }
            }
        }
    }
    &-benefits {
        margin: 30px (-$container-padding-x) 0;
        .swiper-button-prev,
        .swiper-button-next,
        .swiper-pagination {
            display: none;
        }
        .swiper-slide {
            width: 140px;
            + .swiper-slide {
                margin-left: 10px;
            }
            &:first-child {
                margin-left: 20px;
            }
            &:last-child {
                margin-right: 20px;
            }
        }
        .item {
            display: flex;
            align-items: center;
            height: 70px;
            overflow: hidden;
            padding: 10px 15px;
            border: 1px solid rgba($c-text-grey, .4);
            .icon {
                margin-right: 10px;
            }
            &-important {
                background: $c-grey-dark;
            }
        }
    }
    &-features {
        margin-top: 30px;
        padding-bottom: 50px;
        .item {
            display: flex;
            align-items: center;
            .responsive {
                width: 75px;
                margin-right: 10px;
                flex: none;
            }
            .v {
                @include font-set(h);
            }
            .t {
                margin-top: 10px;
            }

            + .item {
                margin-top: 30px;
            }

            &.brd {
                padding-top: 30px;
                border-top: 1px solid $c-pdp-border;
            }
        }
    }
    &-bottom {
        display: none;
        position: sticky;
        bottom: 0;
        z-index: $z-low;
        // border-top: 1px solid $header-top-cover-border-bottom-color;
        height: 70px;
        margin-top: 60px;
        align-items: center;
        justify-content: center;
        background: #ecedef;
        &::after {
            @extend .svg;
            @extend .svg-arrow-b;
            content: "";
            display: block;
        }
    }
    &-tab {
        background: $c-white;
        color: $body-color;
        .accordion > .active {
            background: $c-bg-light;
        }
        .acc-item {
            width: calc(100% - (#{$container-padding-x} * 2));
            max-width: 1200px;
            margin: 0 auto;
        }

        &-steps {
            display: none;
            margin-top: 30px;
            padding: 30px 0;
            > a {
                display: block;
                color: $c-grey-dark;
                padding: 10px 0;
                animation: toTop 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
                &.active {
                    color: $body-color;
                    font-weight: $fw-bold;
                }
            }
        }
    }
    &-awards {
        background-color: $c-bg-body !important;
        .item {
            $w: 100;
            @include responsive($w, 50) {
                width: #{$w}px;
                margin-bottom: 10px;
            }
            .t {
                color: $c-text-grey;
            }
            + .item {
                margin-top: 30px;
            }
        }
    }
    &-featured-content{
        .acc-body{
            padding: 0 !important;
        }
        // AR Content
        .ar{
            &-content{
                max-width: 1200px;
                margin: 0 auto;
                background-color: $c-bg-light;
                text-align: center;
            }
            &-body{
                padding: 40px 30px 20px;
                .icon{
                    color: $c-primary;
                }
            }
            &-title{
                @include font-set(h);
                margin-top: 10px;
            }
            &-text, &-text-d{
                margin-top: 10px;
            }
            &-text-d{
                display: none;
            }
            &-info{
                margin-top: 15px;
                color: $c-grey-dark;
            }
            &-button{
                margin-top: 20px;
                position: relative;
                .btn-ar-hidden{
                    position: absolute;
                    top: 0;
                    left: calc(50% - 75px);
                    height: 100%;
                    width: 150px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            &-media{
                position: relative;
                padding: 13px 14px 0;
                @include responsive(195, 308);
                width: 220px;
                margin: 0 auto;
            }
            &-frame{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include responsive(220, 318);
            }
        }
    }
    &-installments {
        .nav-link {
            flex: 1;
        }
        .item {
            $w: 150;
            @include responsive($w, 50) {
                width: #{$w}px;
                margin: 10px 10px 0;
            }
            + .item {
                margin-top: 20px;
                border-top: 1px solid $c-grey-border;
            }
        }
    }
    &-compare {
        .compare-top-panel {
            position: relative;
            top: 0;
        }
        &-more{
            text-align: center;
            padding: 20px 0 30px;
        }
    }
    &-promotions{
        .acc-body > .tab-holder{
            max-width: 960px;
            margin: 0 auto;
        }
        .tab-ext{
            padding: 0 20px;
        }
        .banner{
            margin: 0 20px;
            .bnr{
                &-body{
                    background-color: $c-white;
                    color: $c-text-dark;
                }
                &-title{
                    @include font-set(h-small, true);
                }
            }
        }
    }
    &-technical-features {
        .section-info {
            margin-top: 50px;
        }
        &-list {
            margin-top: 75px;
            .items-list {
                + .items-list {
                    margin-top: 30px;
                }
            }
            .title {
                @include font-set(h);
                margin-bottom: 20px;
            }
            .item {
                .t {
                    color: $c-grey-dark;
                }
                + .item {
                    margin-top: 10px;
                }
            }
        }
    }
    &-downloads {
        margin-top: 30px;
        .nav-link {
            flex: 1;
        }
        .item {
            a {
                display: flex;
                align-items: center;
                padding: 15px 0;
                border-top: 1px solid $c-grey-border;
                &::after {
                    @extend .svg;
                    @extend .svg-download;
                    content: "";
                    display: block;
                    margin-left: auto;
                    flex: none;
                }
            }
            .v {
                padding-right: 30px;
            }
        }
    }
    &-dimensions {
        &-graph {
            $p: 25px;
            position: relative;
            padding: $p 0 0 $p;
            [class*="ruler-"] {
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .t {
                    position: relative;
                    background: $c-bg-light;
                    padding: 0 15px;
                }
                &::before {
                    content: "";
                    position: absolute;
                }
            }
            .ruler {
                &-h {
                    width: 50%;
                    left: 25%;
                    &::before {
                        border-top: 1px solid $c-grey-border;
                        top: 50%;
                        left: 0;
                        width: 100%;
                    }
                }
                &-v {
                    height: 50%;
                    top: 25%;
                    left: -$p;
                    .t {
                        transform: rotate(-90deg);
                    }
                    &::before {
                        border-left: 1px solid $c-grey-border;
                        top: 0;
                        left: 50%;
                        height: 100%;
                    }
                }
            }
        }
        &-list {
            margin-top: 10px;
            display: flex;
            justify-content: center;
            .item {
                text-align: center;
                .t {
                    margin-top: 5px;
                    color: $c-grey-dark;
                }
                + .item {
                    margin-left: 40px;
                }
            }
        }
    }
    &-store-locator{
        .srv{
            &-list{
                margin: 0 0 70px;
            }
            &-item{
                background-color: $c-bg-body;
            }
            &-address{
                margin-top: 3px;
                padding-right: 50px;
            }
        }
    }

    // Mini View
    &-mini {
        $pr: 200px;
        overflow: hidden;
        &-block1 {
            position: relative;
            padding: 70px $pr 30px $container-padding-x;
            margin: 0 (-$container-padding-x);
        }
        &-container{
            background: $c-bg-colored;
            padding: 0 20px;
        }
        &-media {
            position: absolute;
            top: 0;
            right: -120px;
            z-index: $z-lower;
            width: 320px;
        }
        &-name {
            .title {
                @include font-set(h);
            }
        }
        &-cat {
            margin-top: 5px;
            display: flex;
            align-items: center;
        }
        &-energy-label {
            margin-right: 8px;
        }
        &-features {
            margin: 20px auto 0;
            min-height: 160px;
            padding: 0 $pr 0 20px;
            ul {
                padding-left: 15px;
            }
            li{
                margin-bottom: 10px;
            }
        }
        &-warranty {
            &-bar {
                $bar-h: 6px;
                $bar-thumb: 10px;
                position: relative;
                background: linear-gradient(to right, #6e994e 49%, #b32424);
                height: $bar-h;
                > i {
                    position: absolute;
                    top: ($bar-thumb - $bar-h) / -2;
                    width: $bar-thumb;
                    height: $bar-thumb;
                    border: 1px solid $body-color;
                    border-radius: 50%;
                    background: $c-bg-body;
                }
            }

            &-status {
                position: relative;
                margin-top: 15px;
                padding-left: 14px;
                > small {
                    display: block;
                    margin-top: 3px;
                    @include font-set(body-small);
                    color: $c-grey-dark;
                }
                &::before {
                    position: absolute;
                    top: 3px;
                    left: 0;
                }
            }

            @at-root {
                .btn-warranty2 {
                    margin-top: 20px;
                }
            }
        }
        &-docs {
            .pdp-downloads {
                margin-top: 0;
            }
        }
        &-cta {
            padding: 30px 0;
            .btn {
                padding: 0 15px;
                + .btn {
                    margin-left: 5px;
                }
            }
        }
    }
    &-preorder{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid $c-white;
        color: $preorder-text-color;
        height: 40px;
        width: 100%;
        margin-top: 15px;
        &-info{
            display: flex;
            align-items: flex-start;
            margin-top: 15px;
            border-top: 1px solid $c-pdp-border;
            border-bottom: 1px solid $c-pdp-border;
            padding: 10px 0;
            .icon{
                margin-right: 10px;
                flex: 0 0 32px;
            }
            span{
                @include font-set(body-small);
                line-height: 18px;
            }
        }
    }
    &-badge3{
        display: flex;
        align-items: center;
        figure{
            flex: 0 0 30px;
            margin: 0 5px 0 -5px;
        }
        span{
            @include font-set(body-small);
        }
    }
}

// Rich Content
.ftc{
    &-item{
        overflow: hidden;
        position: relative;
        background-color: $c-bg-dark;
        display: flex;
        flex-direction: column;
    }
    &-info{
        padding: 40px 20px;
    }
    &-title{
        @include font-set(h-large);
        color: $c-text-light;
        margin-bottom: 10px;
    }
    &-text{
        @include font-set(body-large);
        color: $c-text-grey;
        max-width: 540px;
    }
    &-media{
        @include responsive(1280, 850);
        position: relative;
    }
    &-video{
        .responsive{
            height: 100%;
        }
        video{
            width: auto;
        }
    }
    // &-swipe{
    //     width: 200px;
    //     height: 32px;
    //     border: 1px solid $c-white;
    //     border-radius: 16px;
    //     position: absolute;
    //     left: calc(50% - 100px);
    //     bottom: 20px;
    //     display: flex;
    //     // justify-content: center;
    //     align-items: center;
    //     background-color: $c-bg-dark;
    //     span{
    //         display: block;
    //         text-align: center;
    //         position: absolute;
    //         width: 100%;
    //         left: 0;
    //         color: $c-text-light;
    //         @include font-set(h-xsmall);
    //         @include transitions($prop: opacity);
    //         &.ply{
    //             opacity: 0;
    //         }
    //     }
    //     button{
    //         @extend .svg;
    //         @extend .svg-swipe;
    //         position: absolute;
    //         left: -1px;
    //         top: -1px;
    //         border-radius: 50%;
    //         background-color: $c-bg-dark;
    //         @include transitions();
    //         z-index: $z-lower;
    //     }
    //     &.playing{
    //         button{
    //             left: calc(100% - 31px);
    //         }
    //         span{
    //             &.ply{
    //                 opacity: 1;
    //             }
    //             &.pre{
    //                 opacity: 0;
    //             }
    //         }
    //     }
    // }

    // Themes
    &-bg-light{
        background-color: $c-bg-body;
        .ftc-title, .ftc-text{
            color: $c-text-dark !important;
        }
    }

    // Types
    &-full{
        height: 667px;
        flex-direction: row;
        .ftc-media{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: $z-lower;
            @include responsive(){
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                height: 100%;
                width: auto;
                img{
                    height: 100%;
                    width: auto;
                    max-width: none;
                    position: static;
                }
                &::before{
                    display: none;
                }
            }
        }
        .ftc-info{
            position: absolute;
            margin: 0;
            z-index: $z-low;
            bottom: 60px;
            left: 0;
            background-color: rgba($c-dark, .4);
        }
        .ftc-text{
            color: $c-text-light;
        }
        &.ftc-video{
            .responsive{
                width: 100% !important;
                height: 100% !important;
                // video{
                //     width: 100%;
                //     object-fit: cover;
                // }
            }
        }
    }

    &-video{
        .responsive{
            // width: 100% !important;
            // height: 100% !important;
            video{
                width: 100%;
                object-fit: cover;
            }
        }
    }

    &-tab-item{
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: 667px;
        margin: 0 auto;
        background-color: transparent;
        .ftc-tabs{
            position: absolute;
            top: 0;
            left: 0;
            padding: 35px 30px 0;
            display: flex;
            width: 100%;
            z-index: $z-normal;
            button{
                display: inline-block;
                flex: 1;
                text-align: center;
                color: $c-text-light;
                border-bottom: 2px solid rgba($c-bg-dark, .25);
                padding: 10px 0;
                &.active{
                    border-bottom-color: $c-white;
                }
            }
        }
        .ftc-media{
            transform: scale(1.1);
            @include transitions($dur: 'slow', $func: 'eut');
            .responsive{
                height: 100% !important;
                width: auto !important;
                img{
                    height: 100% !important;
                    width: auto !important;
                }
            }
        }
        .ftc-item{
            position: absolute;
            left: 0;
            top: 0;
            z-index: $z-zero;
            width: 100%;
            height: 100%;
            opacity: 0;
            @include transitions($dur: 'faster');
            
            &.ready{
                z-index: $z-lower;
            }
            &.active{
                z-index: $z-low;
                opacity: 1;
                .ftc-media{
                    transform: scale(1);
                }
            }
        }
    }
}

// All Reviews
.rvw{
    &-holder{
        padding: 0 20px;
    }
    &-sum{
        background-color: $c-bg-body;
        padding: 20px;

        &-header{
            display: flex;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid $c-grey-border;
        }
    }
    &-score{
        &-bottom{
            display: flex;
            align-items: center;
        }
    }
    &-average{
        @include font-set(h-small);
        margin-right: 10px;
    }
    &-count{
        margin-left: auto;
    }
    &-lvl{
        display: flex;
        align-items: center;
        padding: 5px 0;
        width: 100%;
        &-title{
            width: 90px;
        }
        &-size{
            margin-left: auto;
        }
    }
    &-fwd-bar{
        width: 100%;
        max-width: 150px;
    }
    &-create{
        margin-top: 30px;
        text-align: center;
    }
    &-list{
        margin-top: 30px;
        &-sort{
            display: flex;
            width: 100%;
            button{
                @include font-set(h-tiny);
                padding: 17px;
                border-bottom: 3px solid $c-grey-light;
                text-align: center;
                flex: 1;
                &.active{
                    border-bottom-color: $c-primary;
                }
            }
        }
        &-item{
            margin-top: 20px;
            padding-bottom: 30px;
            border-bottom: 1px solid $c-grey-border;
        }
    }
    &-item{
        &-stars{
            margin-bottom: 3px;
        }
        &-title{
            @include font-set(h-small);
        }
        &-details{
            display: flex;
            width: 100%;
        }
        &-date{
            margin-left: auto;
            color: $c-grey-dark;
        }
        &-more{
            display: none;
            margin-top: 10px;
        }
        &-text{
            margin-top: 15px;
        }
        &-less{
            p{
                overflow : hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;
                margin-bottom: 0;
            }
            .rvw-item-more{
                display: inline-block;
            }
        }
        &-media{
          margin-top: 10px;
          button{
              margin-bottom: 5px;
              &.video{
                position: relative;
                &::before{
                    content: "";
                    display: inline-block;
                    @extend .svg;
                    @extend .svg-video;
                    position: absolute;
                    top: calc(50% - 16px);
                    left: calc(50% - 16px);
                    width: 64px;
                    height: 64px;
                    transform-origin: top left;
                    transform: scale(.5);
                }
              }
              & + button{
                  margin-left: 5px;
              }
          }  
        }
        &-feedback{
            margin-top: 30px;
            > span{
                display: block;
                margin-bottom: 15px;
            }
            .btn + .btn{
                margin-left: 10px;
            }
        }
        &-reply{
            border-left: 2px solid $c-grey-light;
            padding-left: 20px;
            margin-top: 30px;
        }
    }
    &-reply{
        &-header{
            @include font-set(h-xsmall);
            margin-bottom: 10px;
            padding-left: 40px;
            position: relative;
            height: 30px;
            line-height: 30px;
            &::before{
                content: "";
                display: inline-block;
                width: 16px;
                height: 15px;
                position: absolute;
                left: 7px;
                top: calc(50% - 7.5px);
                background: url(#{$dir-images + 'logo-compact.png'}) center center no-repeat;
                background-size: cover;
            }
            &::after{
                content: "";
                display: inline-block;
                width: 30px;
                height: 30px;
                position: absolute;
                top: calc(50% - 15px);
                left: 0;
                border: 1px solid $c-grey-light;
                border-radius: 50%;
            }
        }
    }
    &-no-review{
        padding-bottom: 30px;
        display: block !important;
        .rvw{
            &-sum{
                background-color: transparent;
                text-align: center;
                padding: 0;
            }
            &-reviews{
                justify-content: center;
            }
            &-info{
                margin-top: 10px;
                span{
                    display: block;
                    margin-bottom: 10px;
                }
                b{
                    @include font-set(h-small);
                }
            }
        }
    }
}

#rvw-mnp{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: none;
    z-index: $z-ultra;
    pointer-events: none;
    .btn-close{
        pointer-events: all;
    }
    img, video{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
    .mnp-content{
        pointer-events: all;
        overflow: visible;
        padding: 0;
    }
    .swiper-slide{
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        max-width: 325px;
        text-align: center;
        + .swiper-slide{
            margin-left: 10px;
        }
        &:first-child{
            margin-left: 20px;
        }
        &:last-child{
            margin-right: 20px;
        }
    }
    .swiper-button-next, .swiper-button-prev{
        display: none;
    }
    &.activated{
        display: flex;
    }
}

// Image Comparison Slider
.cocoen {
    cursor: pointer;
    line-height: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    user-select: none;
  
    img,
    video {
      max-width: none;
    }
  
    > img,
    > video {
      display: block;
      width: 100%;
    }

    .cocoen-title{
        color: $c-text-light;
        @include font-set(body-large);
        position: absolute;
        bottom: 10px;
    }

    > .cocoen-title{
        right: 10px;
        z-index: $z-lower;
    }

    .cocoen-first .cocoen-title{
        left: 10px;
    }
  
    .cocoen-first {
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 50%;
        z-index: $z-low;
      img,
      video {
        height: 100%;
      }
    }
  
    &-drag {
      display: inline-block;
      width: 2px;
      height: 100%;
      background: $c-white;
      cursor: ew-resize;
      position: absolute;
      left: 50%;
      top: 0;
      margin-left: -1px;
      z-index: $z-low;
  
      &::before{
        content: "";
        display: inline-block;
        @extend .svg;
        @extend .svg-cocoen;
        width: 66px;
        height: 66px;
        position: absolute;
        top: calc(50% - 33px);
        right: -32px;
      }
    }
  }

// Rating Bars
$rating-bars: ( 
    '1-5': 5, 
    '5-20': 20, 
    '20-50': 40,
    '50-75': 65, 
    '75-95': 85, 
    '100': 100
);
[data-bar-size]{
    font-size: 0;
    &::before{
        content: "";
        display: inline-block;
        font-size: 0;
        height: 100%;
        width: 0;
        background-color: $c-warning;
    }
}
@each $bar, $size in $rating-bars{
    [data-bar-size="#{$bar}"]{
        &::before{
            width: #{$size + '%'};
        }
    }
}

// Extra Warranty
.wty{
    &-row{
        display:flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px 0;
    }
    &-text{
        padding-left: 10px;
        flex: 1;
    }
    &-price{
        margin-right: 15px;
        font-weight: $fw-bold;
    }
    &-info {
        flex-basis: 100%;
        display: flex;
        margin-top: 10px;
        @include font-set(body-small);
        i {
            margin-top: -5px;
        }
    }
}

// Promotion
.promo{
    &-holder{
       background-color: $c-bg-light;
    }
    &-inner{
        padding-bottom: 60px;
    }
    &-header{
        background-color: $c-bg-body;
        text-align: center;
        margin: 0 20px;
        padding-top: 20px;
    }
    &-title{
        @include font-set(h-small);
        margin-bottom: 10px;
    }
    &-info{
        padding: 0 25px 30px;
    }
    &-tabs{
        .nav-link{
            flex: 1;
        }
    }
    &-tab-contents{
        margin-top: 15px;
        .option-count{
            padding: 0 30px;
            margin-bottom: 15px;
            @include font-set(body-small);
        }
        .swiper-wrapper{
            padding: 1px 0;
        }
        .swiper-slide{
            width: 190px;
            padding: 0 5px;

            &:first-child{
                margin-left: 15px;
            }

            &:last-child{
                margin-right: 15px;
            }
        }

        .swiper-pagination, [class*="swiper-button-"]{
            display: none !important;
        }
    }
    &-selections{
        margin-top: 50px;

        &-title{
            @include font-set(h);
            margin-bottom: 20px;
            text-align: center;
        }
        .prd-row-inner{
            border-bottom: 1px solid $c-grey-border;
        }
    }
    &-current{
        .prd-row{
            background-color: $c-bg-body;
            &-inner{
                border-bottom: none;
            }
        }
    }
    &-bonus{
        .prd-row-info{
            color: $c-primary;
            font-weight: $fw-bold;
            margin-top: 5px;
        }
    }
    &-sum{
        display: flex;
        padding: 0 20px;
        margin-top: 30px;

        &-left{
            flex: 1;
            .promo-sum-title{
                @include font-set(body-small);
                margin-bottom: 10px; 
            }
        }

        &-prices{
            .prc-first{
                @include font-set(body-large);
                margin-right: 10px;
                color: $c-grey-dark;
            }

            .prc-last{
                @include font-set(h-paragraph);
            }
        }

        &-right{
            flex: none;
            .btn{
                padding: 0 20px;
            }
        }
    }
    &-conditions{
        background-color: $c-bg-body;
        padding: 50px 0 80px;

        h6{
            @include font-set(h-small);
            margin-bottom: 20px;
        }
    }
    &-products{
        margin: 0 -20px;
        #product-swiper{
            margin-bottom: 30px;
        }
        #product-swiper>.swiper-wrapper>.swiper-slide:first-child{
            margin-left: 20px;
        }
        #product-swiper>.swiper-wrapper>.swiper-slide:last-child{
            margin-right: 20px;
        }
    }
    &-row{
        margin-top: 30px;
    }
}

.page-promotion-detail{
    .promo{
        &-holder{
           position: relative;
           z-index: $z-low;
        }
        &-header{
            position: relative;
            top: -50px;
        }
        &-tab-contents{
            margin-top: -35px;
        }
    }

    #site-footer{
        margin-top: 0;
    }
}

// Built-in Set
.set{
    &-item{
        + .set-item{
            margin-top: 60px;
        }
    }
    &-title{
        padding: 15px 20px 10px;
        background-color: $c-bg-light;
        @include font-set(h-small);
    }
    &-media{
        margin-bottom: 20px;
        @include responsive(1200, 679);
    }
    &-products{
        #product-swiper{
            margin-bottom: 30px;
        }
        #product-swiper>.swiper-wrapper>.swiper-slide:first-child{
            margin-left: 20px;
        }
        #product-swiper>.swiper-wrapper>.swiper-slide:last-child{
            margin-right: 20px;
        }
    }
    &-sum{
        display: flex;
        align-items: center;
        padding: 0 20px;
        &-left{
            flex: 1;
        }
        &-title{
            @include font-set(body-small);
            margin-bottom: 10px;
        }
        &-price{
            .prc{
                @include font-set(body-large);
            }
            .prc-first{
                margin-right: 10px;
                color: $c-grey-dark;
            }
            .prc-last{
                font-weight: $fw-bold;
            }
        }
    }
    &-more{
        text-align: center;
        margin-top: 60px;
    }
}

// Assistant Page
#assistant-wizard{
    background-color: $c-bg-colored;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100vh - #{$header-height-mobile});
    // States
    &.asw-ready{
        .asw-enter{
            display: block;
        }
        .btn-apply{
            display: none;
        }
    }
    &.asw-completed{
        .btn-asw-next{
            display: none;
        }
    }
}

.asw{
    &-header{
        position: relative;
        flex: 1;
        padding-top: 125px;
    }
    &-media{
        width: 250px;
        position: absolute;
        z-index: $z-lower;
        top: -175px;
        left: calc(50% - 125px);
    }
    &-enter{
        width: 66%;
        max-width: 340px;
        margin: 0 auto;
        @include font-set(h);
        text-align: center;
        display: none;
    }
    &-step{
        display: none;
        &-info{
            padding: 0 20px 20px;
            display: flex;
            align-items: flex-end;
        }
        &-selections{
            height: 183px;
            .swiper-slide{
                width: 145px;
                + .swiper-slide{
                    margin-left: 5px;
                }
                &:first-child{
                    margin-left: 20px;
                }
                &:last-child{
                    margin-right: 20px;
                }
            }
            .rl-box{
                height: 100%;
                label{
                    background-color: rgba($c-bg-light, 0.6) !important; 
                }
                input{
                    &:checked + label{
                        background-color: $c-bg-light !important;
                    }
                }
            }
            .rl-text{
                margin: 2px -10px 0;
                text-align: center;
                justify-content: center;
                height: 36px;
                overflow: hidden;
                display: flex;
                align-items: center;
                @include font-set(body-small);
            }
        }
        &.asw-current{
            display: block;
        }
    }
    &-footer{
        padding: 30px 20px;
    }
    &-button{
        height: 44px;
        display: flex;
        align-items: center;
        .btn{
            margin-left: auto;
        }
        .link-inline{
            margin-left: 10px;
        }
    }
    &-selecteds{
        text-align: right;
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: $z-low;
        li{
            overflow: hidden;
            height: 0;
            @include transitions($dur: 'now');
            button{
                opacity: 0;
                @include transitions($dur: 'faster');
            }
            + li.active{
                margin-top: 10px;
            }
            &.active{
                height: 40px;
                button{
                    opacity: 1;
                }
            }
        }
        button{
            display: inline-flex;
            height: 40px;
            align-items: center;
            position: relative;
            background-color: $c-white;
            border-radius: 20px;
            padding: 0 4px 0 20px;
            &::after{
                content: "";
                display: inline-block;
                width: 36px;
                height: 32px;
                @extend .svg;
                @extend .svg-remove;
                margin-left: 10px;
            }
        }
    }
}

#assistant.asr-cover{
    .bnr{
        &-body{
            padding: 60px 60px 40px;
        }
        &-media{
            bottom: auto !important;
            top: -60%;
            width: 250px !important;
            left: calc(50% - 125px) !important;
        }
        &-header{
            margin-bottom: 30px;
        }
        &-button{
            .btn{
                max-width: 180px;
                display: flex;
                margin: 0 auto 20px;
            }
        }
    }
}

// Call Me Back Form
.cmb{
    background-color: $c-bg-light;
    &-inner{
        max-width: 1440px;
        padding: 40px 0 30px;
        margin: 0 auto;
        position: relative;
    }
    &-close{
        position: absolute;
        top: 10px;
        right: 10px;
    }
    &-title{
        @include font-set(h);
    }
    &-form{
        margin-top: 20px;
        [class*="form-col-"]{
            padding: 0;
        }
        .form-button{
            text-align: right;
        }
    }
}

// Meeting Starter Box
.msb{
    position: relative;
    margin: 20px 0;
    border: 2px solid $c-primary;
    border-radius: 5px;
    &-inner{
        display: flex;
        max-width: 850px;
        margin: 0 auto;
    }
    &-media{
        width: 100px;
        flex: none;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        &::after{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: $z-zero;
            background: url(#{$dir-images + 'arcelik-yaprak.svg'}) center right no-repeat;
            background-size: cover;
            opacity: .03;
        }
        figure{
            display: inline-block;
            position: relative;
            z-index: $z-lower;
            &::after{
                content: "";
                display: block;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 1px solid $c-white;
                background-color: $c-success;
                position: absolute;
                bottom: 7px;
                right: -5px;
                z-index: $z-low;
            }
        }
    }
    &-body{
        padding: 10px 50px 10px 15px;
    }
    &-button{
        margin-top: 10px;
    }
    &-text{
        font-weight: $fw-bold;
    }
    .btn-msb-close{
        position: absolute;
        top: 8px;
        right: 8px;
    }
}

// Coupon Counter
.ccm{
    background-color: $c-bg-light;
    &-inner{
        max-width: 1440px;
        padding: 40px 0 30px;
        margin: 0 auto;
        position: relative;
    }
    &-body{
        max-width: 700px;
    }
    &-close{
        position: absolute;
        top: 10px;
        right: 10px;
    }
    &-title{
        @include font-set(h);
    }
    &-content{
        display: none;
        margin: 20px 0;
    }
    &-revealer{
        margin: 20px 0;
    }
    &-timer{
        flex: 1;
        .time{
            @include font-set(h);
            .digit{
                display: inline-block;
                width: 30px;
                text-align: center;
            }
            .sep{
                animation: blink-animation 1s steps(5, start) infinite;
                padding: 0;
            }
        }
    }
    &-code{
        flex: 1;
        position: relative;
        .copy-code{
            position: absolute;
            right: 0;
            bottom: 8px;
            .icon{
                color: $c-primary;
            }
        }
    }
    &-info{
        @include font-set(body-small);
    }
    &.show-coupon{
       .ccm-content{
           display: flex;
       }
       .ccm-revealer{
            display: none;
        }
    }
}

.menu-ready{
    .pdp-purchase-fixed{
        display: none !important;
    }
}

// PDP Recipies
.apr{
    display: flex;
    justify-content: space-between;
    background-color: $c-bg-body;
    &-body{
        padding: 20px 0 20px 15px;
        display: flex;
        flex-direction: column;
    }
    &-title{
        @include font-set(h-tiny);
        color: $c-primary;
        margin-bottom: 2px;
    }
    &-desc{
        max-width: 265px;
        @include font-set(body-small);
        line-height: 1.3;
    }
    &-cta{
        margin-top: 25px;
    }
    &-media{
        @include responsive(213, 169);
        flex: 0 0 156px;
        display: flex;
        align-items: center;
    }
}