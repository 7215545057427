// Main Slider
#main-slider {
    height: calc(100vw * (680 / 1440));
    .bnr-media {
        picture{
            transform: translateY(0) scale(1.3);
            max-width: 800px;
        }
    }
    .bnr-title {
        @include font-set(h-xl, true);
    }
    .bnr-footer {
        display: flex;
    }
    .bnr-button{
        .btn{
            width: 100%;
            max-width: 140px;
        }
    }

    .bnr-d-bottom {
        .bnr-media {
            position: absolute;
            top: 0;
            bottom: auto;
            picture {
                width: auto;
            }
        }
        .bnr-body {
            padding: 0 0 100px 0;
            margin-top: auto;
            height: auto;
        }
    }

    .bnr-d-left,
    .bnr-d-right {
        .bnr-media {
            position: absolute;
            top: 0;
            bottom: auto;
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
        }
        .bnr-body {
            padding: 0;
        }
    }

    .bnr-d-left {
        padding: ($header-height-desktop) 120px ($header-height-desktop) 180px;
        .bnr-media {
            right: 0;
            left: auto;
            padding-right: 72px;
        }
    }

    .bnr-d-right {
        padding: ($header-height-desktop) 180px ($header-height-desktop) 120px;
        .bnr-media {
            right: auto;
            left: 0;
            padding-left: 72px;
        }
    }

    .bnr-full-img {
        .bnr-media {
            width: 100%;
            padding: 0;

            picture{
                max-width: none;
            }
        }
    }

    .bnr-middle {
        .bnr-body {
            padding: 0;
        }
    }

    .swiper-slide-active{
        .bnr-media {
            picture{
                transform: translateY(0) scale(1);
            }
        }
    }

    .swiper-pagination {
        width: auto !important;
        bottom: 25px;
        left: 33px;
        transform: none;
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: block;
    }

    &::after {
        display: none;
    }
}

// Miracle Flavors
#miracle-flavors{
    margin-top: 50px;
    display: flex;

    > *{
        width: calc(100% / 3);
    }

    .bnr-cover{
        .bnr-body{
            padding-top: 90px;
            padding-bottom: 90px;
        }

        .bnr-footer{
            display: none;
        }

        .bnr-media{
            @include responsive(405, 595);
        }

        .bnr-title{
            @include font-set(h-xxxl, true);
            margin-top: 80px;
        }

        .bnr-header{
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .bnr-button{
            margin-top: auto;
        }
    }

    .cont-sect{
        padding: 40px 60px;
        display: flex;
        flex-direction: column;

        &-title{
            @include font-set(h);
            margin-bottom: 30px;
        }
        &-button{
            margin-top: auto;
            text-align: center;
        }
    }

    // Events
    .events{
        background-color: $c-grey-lighter;
    }

    // Recipes
    .recipes{
        background-color: $c-bg-light;

        .bnr{
            &-body{
                padding: 10px 0;
                height: auto;
            }

            &-media{
                height: 300px;
                overflow: hidden;
                figure{
                    width: 100%;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
                img{
                    width: 100%;
                }
            }

            &-info{
                @include font-set(body-text);
            }
            &-title{
                width: auto;
                margin: 0;
            }
            // &-button{
            //     text-align: right;
            // }
        }
    }

    &.ml-2-col{
        > .banner{
            width: calc(100% / 3 * 2);
            max-height: 670px;
        }
    }
}

// Assistant
.page-home{
    #assistant{
        margin-top: 30px;
        // margin-bottom: 20px;

        .banner{
            height: auto;
        }

        .bnr-info{
            max-width: none;
        }

        .bnr-button{
            .btn{
                width: 140px;
            }
        }

        .bnr-media{
            margin: 0;
            left: 2%;
            bottom: -90%;
        }
    }

    #live-chat{
        max-width: 1600px;
        padding: 0 40px;
    }
    .lcs{
        padding: 12px 22px;
    }
}

// Featured Technology
#featured-technology{
    margin-top: 20px;
    // margin-bottom: 50px;
    
    .banner{
        display: flex;
        align-items: center;
    }

    .bnr-media{
        width: 50%;
    }

    .bnr-body{
        padding: 60px;
        width: 50%;
    }

    .bnr-title{
        @include font-set(h-large, true);
    }

    .bnr-info{
        max-width: 450px;
        margin-top: 15px;
    }

    .bnr-button{
        margin-top: 30px;
    }

    .bnr-media{
        @include responsive(680, 480);
    }
}

// Deal Countdown
#deal-countdown{
    margin-top: 50px;
    .swiper-button-next{
        top: auto;
        bottom: 40px;
        right: 80px;
    }

    .swiper-button-prev{
        top: auto;
        bottom: 40px;
        left: auto;
        right: 144px;
    }
}

.dcd{
    padding: 0;
    display: flex;

    &-bg{
        left: 30%;
        top: -10%;
    }

    &-media{
        order: 2;
        flex: 1;
        max-width: 640px;
        display: flex;
        align-items: center;
        margin: 0 auto;
    }

    &-content{
        padding: 0 60px;
        order: 1;
        width: 580px;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: $z-low;
    }
    
    &-header{
        position: static;
        text-align: left;
        margin: 70px 0 30px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &-hd-left{
        display: block;
        @include font-set(h-large);
    }

    &-hd-right{
        flex: 1;
        padding-left: 20px;
        .subt{
            @include font-set(body-large);
        }

        .time{
            @include font-set(h-large, true);

            .digit{
                width: 45px;
                &.day{
                    width: 70px;
                }
                i{
                    @include font-set(h-xsmall, true);
                    padding-right: 20px;
                    font-style: normal;
                    padding-left: 3px;
                }
            }
        }
    }

    &-body{
       margin-top: auto; 
    }

    &-inner{
        display: block;
    }

    &-title{
        position: relative;
        width: 100%;
        
        a{
          margin-right: 0;
          position: absolute;
          left: 0;
          top: -25px;
        }

        > span{
            @include font-set(h-large);
        }
    }

    &-reviews{
        margin-left: 0;
    }

    &-features{
        margin-top: 20px;
        margin-bottom: 40px;

        li{
            @include font-set(body-large);
        }
    }
    
    &-footer{
        background-color: $c-white;
        padding: 0 30px;
        align-items: center;
        height: 110px;
    }

    &-ft-left{
        flex: 1;

        .promo{
            margin-bottom:5px;
            color: $body-color;
            display: inline-flex;
            align-items: center;
        }

        .responsive{
            display:inline-block;
            width: 32px;
        }

        .prc-first{
            @include font-set(body-large);
            color: $c-grey-dark;
        }

        .prc-last{
            @include font-set(h-paragraph);
            color: $body-color;
        }


        .prc-third{
            @include font-set(h-paragraph);
            color: $c-primary;
            &-info{
                color: $c-text-dark;
            }
        }
    }

    &-ft-right{
        width: 150px;

        .btn{
            background-color: $c-primary;
            color: $c-text-light;

            &:hover{
                background-color: darken($c-primary, 10%);
            }
        }
    }
}
