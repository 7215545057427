//https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping
//100	Thin (Hairline)
//200	Extra Light (Ultra Light)
//300	Light
//400	Normal
//500	Medium
//600	Semi Bold (Demi Bold)
//700	Bold
//800	Extra Bold (Ultra Bold)
//900	Black (Heavy)
//950   Extra Black (Ultra Black)

// Graphik
// @include font-face('Graphik', 'Graphik-Regular', 400, normal);
// @include font-face('Graphik', 'Graphik-Semibold', 600, normal);

// Heron Sans
// @include font-face('HeronSans', 'HeronSans-SemiBold', 600, normal);
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');