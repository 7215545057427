// Directories
$dir-images: '../images/';
$dir-fonts: 'fonts/';

// Fonts
$font0: 'sans-serif';

// 1rem default 16px = xx/16
$px-to-rem: 16;

// z-index
$z-zero:0;
$z-lower:1;
$z-low:2;
$z-normal:3;
$z-supnormal:4;
$z-high:5;
$z-higher:6;
$z-highest:7;
$z-super:8;
$z-ultra:9;
$z-master:10;

// Breakpoints
$breakpoint0: 0;
$breakpoint1-2: 480px;
$breakpoint1: 576px;
$breakpoint2: 768px;
$breakpoint3: 1025px;
$breakpoint4: 1300px;

// Transitions
$transition-ease-in: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$transition-ease-out: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$transition-ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1.000);

// Containers
$containers: (540, 720, 880, 1200, 1360);
$container-padding-x: 20px;

// Misc in Site
$scroll-width-lg: 16px;
$header-height-mobile: 51px;
$header-height-desktop: 61px;