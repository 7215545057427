.svg {
	background: url(#{$dir-images + 'sprite-bg.svg'}) no-repeat;
	width: 32px;
	height: 32px;
}

.svg-arrow-b {
	background-position: 0 0;
}

.svg-arrow-b-w {
	background-position: 0 2.780191138140747%;
}

.svg-arrow2-r {
	background-position: 0 5.560382276281494%;
}

.svg-arrow2-r-w {
	background-position: 0 8.340573414422241%;
}

.svg-bubble-qm {
	background-position: 0 11.120764552562989%;
}

.svg-check {
	background-position: 0 13.900955690703736%;
}

.svg-check-full {
	background-position: 0 16.695652173913043%;
}

.svg-cocoen {
	background-position: 0 20.143240823634734%;
}

.svg-compare {
	background-position: 0 25.28236316246742%;
}

.svg-completed {
	background-position: 0 28.062554300608166%;
}

.svg-delete {
	background-position: 0 30.842745438748914%;
}

.svg-download {
	background-position: 0 33.622936576889664%;
}

.svg-gift {
	background-position: 0 36.40312771503041%;
}

.svg-input-error {
	background-position: 0 39.18331885317116%;
}

.svg-minus {
	background-position: 0 41.9635099913119%;
}

.svg-more {
	background-position: 0 44.74370112945265%;
}

.svg-plus {
	background-position: 0 47.523892267593396%;
}

.svg-plus-c {
	background-position: 0 50.30408340573415%;
}

.svg-radio {
	background-position: 0 53.08427454387489%;
}

.svg-radio-full {
	background-position: 0 55.86446568201564%;
}

.svg-remove {
	background-position: 0 58.644656820156385%;
}

.svg-remove-w {
	background-position: 0 61.424847958297136%;
}

.svg-star {
	background-position: 0 63.7618636755824%;
}

.svg-star-full {
	background-position: 0 65.83261432269198%;
}

.svg-stars-alt-empty {
	background-position: 0 68.375325803649%;
}

.svg-stars-alt-full {
	background-position: 0 71.15551694178974%;
}

.svg-stars-empty {
	background-position: 0 73.93570807993049%;
}

.svg-stars-full {
	background-position: 0 76.71589921807124%;
}

.svg-swipe {
	background-position: 0 79.496090356212%;
}

.svg-swiper-next {
	background-position: 0 82.27628149435273%;
}

.svg-swiper-next-dark {
	background-position: 0 85.05647263249348%;
}

.svg-switch-success {
	background-position: 0 86.93035253654342%;
}

.svg-video {
	background-position: 0 92.13583556747096%;
}

.svg-video-type {
	background-position: 0 95.13466550825369%;
}

.svg-video-type-dark {
	background-position: 0 97.91485664639444%;
}

.svg-warn {
	background-position: 0 100%;
}

