//ABSTRACTS
@import 'abstracts/variables';
@import 'abstracts/brand-settings';
@import 'abstracts/mixins';
@import 'abstracts/app-shell-source';

//VENDORS
@import 'vendors/bootstrap-custom-base';
@import 'vendors/bootstrap-custom';
@import 'vendors/swiper-custom';
@import 'vendors/nouislider-custom';
@import 'vendors/threesixty-custom';
@import 'vendors/pikaday-custom';

//BASE
@import 'base/typography';
@import 'base/icons';
@import 'base/icons-bg';
@import 'base/animations';
@import 'base/global';


//BLOCKS
@import 'layouts/elements';
@import 'blocks/modal';
@import 'blocks/header';
@import 'blocks/footer';

//MODULES
@import 'modules/banner';
@import 'modules/product';
@import 'modules/swipers';

//LAYOUT
@import 'layouts/home';
@import 'layouts/product-pages';
@import 'layouts/content-pages';
@import 'layouts/member-pages';
@import 'layouts/support-pages';
@import 'layouts/purchase-pages';

// PAGE LOAD INTERACTIONS
@import 'layouts/pageload-interactions';

// MOBILE ONLY (< 1025px)
@media (max-width: 1024px) {
  @import 'layouts/mobile-only';
}

// TABLET (> 480px)
@media (min-width: $breakpoint1-2) {
  @import 'layouts/tablets-fix';
}

// DESKTOP (> 1024px)
@media (min-width: $breakpoint3) {
  // BLOCKS
  @import 'layouts/elements-desktop';
  @import 'blocks/modal-desktop';
  @import 'blocks/header-desktop';
  @import 'blocks/footer-desktop';

  // MODULES
  @import 'modules/banner-desktop';
  @import 'modules/product-desktop';
  @import 'modules/swipers-desktop';

  // LAYOUT
  @import 'layouts/home-desktop';
  @import 'layouts/product-pages-desktop';
  @import 'layouts/content-pages-desktop';
  @import 'layouts/member-pages-desktop';
  @import 'layouts/support-pages-desktop';
  @import 'layouts/purchase-pages-desktop';

  // PAGE LOAD INTERACTIONS
  @import 'layouts/pageload-interactions-desktop';
  
  // MID-SIZE DEVICES FIX (> 1024px && <= 1300px)
  @media (max-width: $breakpoint4) {
    @import 'layouts/midsize-devices-fix';
  }
}

// EXTRA PAGES
@import 'vendors/arcelik-custom';