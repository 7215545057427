// Banner
.bnr{
    &-info{
        @include font-set(body-large, true);
    }

    &-price{
        @include font-set(body-large, true);
    }

    &-promo{
        display: flex;

        .bnr {
            &-media {
                order: 2;
                height: 100%;
                max-width: 750px;
            }

            &-body{
                padding: 70px 70px 60px;
                order: 1;
                height: auto;
            }

            &-title{
                @include font-set(h-large, true);
            }

            &-info{
                margin-top: 20px;
            }

            &-button{
                margin-top: 40px;
            }

            &-footer{
                display: block;
                opacity: .6;
                margin-top: auto;
            }
        }
    }
}

.bnr-d {
    // default to bottom text
    &-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        .bnr-media {
            position: static;
        }
    }

    // default to left text
    &-left {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        text-align: left;
        .bnr-media {
            position: static;
        }
        .bnr-body {
            height: auto;
            padding: 0;
        }
    }

    // default to right text
    &-right {
        display: flex;
        align-items: center;
        text-align: right;
        .bnr-media {
            position: static;
        }
        .bnr-body {
            height: auto;
            padding: 0;
        }
    }
}

// Category Banners
#category-banners {
    padding: 0 35px;
    
    .swiper-slide {
        max-width: none;
        flex: 1;
        &:first-child{
            margin-left: 0;
        }
        &:last-child{
            margin-right: 0;
        }
    }
    .swiper-pagination {
        display: none;
    }

    .bnr-body {
        padding: 60px 40px;
    }
    .bnr-info {
        @include font-set(h-tiny, true);
    }

    &.item-1 {
        .banner {
            height: 320px;
        }
        .bnr-body {
            padding: 60px 80px;
        }
        .bnr-media {
            $w: 496;
            max-width: #{$w}px;
            @include responsive($w, 320);
        }
    }

    &.item-2 {
        .bnr-body {
            padding: 60px;
        }
    }
}