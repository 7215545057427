// User Orders
.ord{
    .ord-extra-warranty{
        .col-lg-1{
            display: none;
        }
    }
}

// Software & Guide Finder
.spt-search-ready .sgf-search .spt-search{
    position: fixed;
}

// Main Menu
#main-menu {
    .lv1-li {
        &.selected {
            animation: none;
            opacity: 1;
            > a {
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 82px);
                background-color: $c-white;
                padding: 15px 0;
                margin: 0 15px;
                &::after{
                    right: auto;
                    left: 0;
                    transform: translateY(-50%) scale(-1);
                }
                span{
                    margin-left: 32px;
                }
            }
        }
    }
}
.menu-category {
    .sub{
        > .banner{
            display: none;
        }
    }
    .sublv{
        display: none;
    }
    // lv 2
    .lv-2{
        > li {
            > a {
                padding: $menu-lv-1-padding;
                border-top: 1px solid $menu-lv-1-border-color;
                width: calc(100% - (#{$menu-space-x} * 2));
                margin: 0 auto;
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
                > .responsive {
                    display: none;
                }
            }
            &.selected{
                .sublv{
                    display: block;
                }
            }
        }
    }
    // lv 3
    .lv-3 {
        display: flex;
        flex-wrap: wrap;
        padding: 0 10px 20px;
        > li {
            width: calc(100% / #{$menu-lv-2-row-item});
            padding: 10px;
            > a {
                flex-direction: column;
                text-align: center;
                > .responsive {
                    max-width: $menu-lv-2-img-width;
                }
                > span {
                    margin-top: 15px;
                }
            }
        }
    }
    &.lv1-active{
        > li{
            display: none;
            &.selected{
                display: block;
            }
        }
    }
}