// page top
.btn-page-top {
    top: #{$dm-control-height + 30px};
}

// menu
.menu-category {
    .banner {
        width: 300px;
        margin-right: 30px;
    }
}

// main slider
#main-slider {
    height: 680px;
}

.top-panel {
    padding-left: 40px;
    padding-right: 40px;
}

// Miracle Flavors
#miracle-flavors:not(.ml-2-col){
    flex-wrap: wrap;

    > *{
        width: 50%;
    }

    .banner{
        width: 100%;
        height: 400px;

        .bnr-media{
            @include responsive(1300, 600);
        }
    }
}

// Page Cover
// .cover{
//     &-media{
//         .responsive{
//             width: 100%;
//             height: auto;
//             img{
//                 width: 100%;
//                 height: auto;
//             }
//         }
//     }
// }

// .page-cover{
//     &.static{
//         .responsive{
//             width: 100%;
//             height: auto;
//         }
//     }
// }