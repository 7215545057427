.banner-list{
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 590px));
    column-gap: 20px;
    row-gap: 40px;

    &.list-col-3{
        grid-template-columns: repeat(3, minmax(auto, 393px));
        column-gap: 11px;
    }
}

// Page Title
.page-title{
    text-align: left;
    padding: 30px 0;

    h1{
        @include font-set(h-large, true);
    }

    &.centered{
        text-align: center;
    }

    &.detail{
        text-align: center;
        max-width: 520px;
    }
}

.text-content{
    margin-top: 50px;

    p{ 
        @include font-set(body-large);
    }   
    
    .hero{
        @include font-set(body-xlarge);
        
        & + p{
            margin-top: 40px;
        }
    }
}

.video-content{
    margin-top: 60px;
}

.page-corporate-detail{
    .page-cover{
        height: 540px;
    }
}

// Technology Detail Page
.technology-cover{
    height: calc((100vw * (640 / 1440)) - #{$header-height-desktop});
    .cover-media{
        .responsive{
            width: 100%;
            height: auto;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}

.techno-image{
    padding: 0 20px;
    max-width: 870px;
    margin: 40px auto 0;
    display: flex;

    figure + figure{
        margin-left: 10px;
    }

    &.gallery{
        display: grid;
        grid-template-columns: 410px 200px 200px;
        grid-template-rows: 200px 200px;
        gap: 10px;

        figure{
            max-width: none;
            margin: 0;

            &:first-child{
                grid-row: 1 / 3;
            }
        }
    }
}

.related-categories{
    .swiper-wrapper{
        justify-content: center;
    }
}

.related-products{
    .show-all{
        padding-top: 40px;
    }
}

// Mucize Lezzetler
.page-ml {
    .page-cover {
        height: 640px !important;
    }
    .cover-img {
        color: $c-text-light;
        @include font-set(h-xxxl);
        max-width: 450px;
    }
    
    .tab-content {
        padding: 0;
    }

    .top-panel {
        padding: 14px 40px;
    }

    .events {
        padding: 40px 20px 40px 40px;
        .evt {
            &-details {
                display: flex;
                height: 80px;
            }
            &-title {
                margin-right: 10px;
                margin-bottom: 0;
                width: 180px;
                max-width: none;
            }
            &-time {
                margin-bottom: 0;
                margin-left: auto;
                width: 100px;
            }
            &-location {
                margin-right: auto;
                margin-left: 10px;
                width: 180px;
            }
        }
    }

    .gallery {
        margin-top: 40px;
        .cont-sect-title {
            padding: 0 20px;
        }
        .swiper-container {
            @include cont-aligner(1200px);
        }
        .swiper-button-prev,
        .swiper-button-next {
            display: block;
        }
        .swiper-button-prev {
            // (1200 + 32 + 32 + 10 + 10) / 2 = 642
            left: calc(50% - 642px);
        }
        .swiper-button-next {
            right: calc(50% - 642px);
        }
        .swiper-slide {
            width: 480px;
            cursor: pointer;
            &:last-child{
                margin-right: calc((100vw - 1200px - 16px) / 2);
            }
        }
        @at-root {
            .gallery-opened{
                .gallery{
                    padding-bottom: 480px;
                }
                .swiper-gallery{
                    position: fixed;
                    left: 0;
                    top: 0;
                    z-index: $z-higher;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    pointer-events: none;
                    *{
                        pointer-events: all;
                    }
                    .swiper-container{
                        align-self: center;
                        padding-left: calc((100% - (720px * 1.5)) / 2) !important;
                        figure{
                            pointer-events: none !important;
                        }
                    }
                    .swiper-slide{
                        width: 75vh !important;
                        max-width: 720px;
                        + .swiper-slide{
                            margin-left: 20px;
                        }
                    }
                    .swiper-button-prev {
                        left: 30px !important;
                    }
                    .swiper-button-next {
                        right: 30px !important;
                    }
                    .btn-gallery-close{
                        display: inline-block;
                        position: absolute;
                        right: 30px;
                        top: calc(((100vh - 720px) / 2) - 60px);
                    }
                }
                #overlay{
                    display: block;
                    z-index: $z-high;
                }
            }
        }
    }

    .all-events {
        margin-top: 40px;
        background: $c-bg-light;
    }

    .recipes {
        padding: 0;
        .bnr {
            &-info {
                @include font-set(body-text, true);
            }
            &-media{
                height: 300px;
            }
        }
    }
    .recipe {
        &-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px 30px;
        }
    }
}

// Content Pages (mucize lezzetler)
.page-ml-detail{
    .page-cover{
        height: 640px;
    }
    #product-swiper > [class*="swiper-button-"]{
        display:block;
        &.swiper-button-next{
            right: 0;
        }
        &.swiper-button-prev{
            left: 0;
        }
    }
}

.cnt {
    &-cover-image, &-cover-video {
        min-height: 640px;
        height: calc(100vh - #{$header-height-desktop + 100px});
    }
    &-inner {
        padding: 30px 0;

        > div {
            padding: 0;
            + div {
                margin-top: 30px;
            }
        }

        .cnt {
            &-title {
                margin-top: 30px;
            }

            &-text {
                @include font-set(body-large, true);
            }

            &-media {
                text-align: center;
            }
        }
    }

    &-products {
        max-width: 1055px;
        margin: 0 auto;

        #product-swiper {
            .swiper-slide {
                &:first-child {
                    margin-left: 20px;
                }
                &:last-child {
                    margin-right: 20px;
                }
            }
        }

        .show-all {
            margin-top: 40px;
        }
    }

    &-gallery {
        &.gallery {
            .swiper-container {
                @include cont-aligner(720px);
            }
            .swiper-button-prev {
                // (720 + 32 + 32 + 10 + 10) / 2 = 402
                left: calc(50% - 402px);
            }
            .swiper-button-next {
                right: calc(50% - 402px);
            }
        }
        .cont-sect-title {
            display: block;
        }
    }
}

// Catalogues
.page-catalogues {
    .page-title {
        text-align: center;
    }
}
.ctl-list {
    padding: 20px 0;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px 40px;
    .banner {
        flex-direction: column;
        margin: 0;
    }
    .bnr {
        &-media {
            max-width: none;
        }
        &-body {
            padding: 15px 0;
        }
    }
}

// Legal Text
.page-legal-text{
    .text-content{
        max-width: 940px;
        margin-top: 40px;
    }
    h1{
        @include font-set(h-large, true);
        margin-bottom: 40px;
    }
}

// Dark Mode Landing
.dml{
    &-info{
        margin-top: 35px;
        padding-bottom: 85px;
        &-users{
            padding: 40px 0 0 45px;
        }
        &-hours{
            padding: 40px 0 0 45px;
        }
    }
    &-content{
        margin: 50px auto 0;
        max-width: 800px;
        padding-bottom: 0;
        &-img{
            margin-left: 0;
            margin-right: 0;
        }
        &-cta{
            margin-top: 50px;
        }
    }
}