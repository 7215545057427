.banner-list{
    .bnr-media{
        @include responsive(1006, 618);
    }

    .bnr-info{
        margin-top: 5px;
        @include line-clamp(5);
        height: 88px;
        @include font-set(body-text);
    }

    .bnr-body{
        background-color: $c-bg-light;
        padding-bottom: 40px;
    }
}

// Page Title
.page-title{
    text-align: center;
    padding: 30px;
    margin: 0 auto;

    p{
        margin-bottom: 10px;   
    }

    h1{
        @include font-set(h);
        margin: 0;
        
        + p{
            margin-top: 10px;
            margin-bottom: 0;
        }
    }

    > a {
        margin-top: 20px;
    }

    &.detail{
        h1{
            @include font-set(h-large, true);
        }
    } 
}

.text-content{
    margin: 30px auto 0;
    padding: 0 30px;
    max-width: 780px;

    .hero{
        @include font-set(body-large);
    }

    p{ 
        margin-bottom: 0;

        + p{
            margin-top: 20px;
        }
    }
}

.video-content{
    margin: 40px auto 0;
    max-width: 880px;
}

.content-cover{
    height: 300px;
}

// Technology Detail Page
.technology-cover{
    height: calc(100vh - #{$header-height-mobile});
    .cover-media{
        .responsive{
            width: auto;
            height: 100%;
            img{
                width: auto;
                height: 100%;
            }
        }
    }
}

.techno-image{
    margin-top: 20px;
    padding: 0 15px;
    font-size: 0;

    &.gallery{
        figure{
            max-width: 315px;
            margin: 0 5px;
            display: inline-block;
        }
    }
}

.related-products{
    .show-all{
        padding-top: 20px;
        text-align: center;
    }
}

// Miracle Flavors
.evt{
    &-item{
        position: relative;
        display: flex;
        margin-bottom: 20px;
    }

    &-date{
        flex: none;
        width: 80px;
        border-right: 1px solid $c-grey-light;

        b{
            display: block;
            @include font-set(h-large);
            color: $c-brand;
        }
    }

    &-details{
        padding: 7px 42px 0 10px;
        position: relative;
        flex: 1;
        height: 110px;
    }

    &-title{
        height: 36px;
        margin-bottom: 5px;
        max-width: 195px;
    }

    &-time{
        margin-bottom: 15px;
    }

    &-location{
        color: $c-grey-dark;
    }

    &-more{
        position: absolute;
        top: 0;
        right: 0;
        z-index: $z-normal;
        @extend .svg;
        @extend .svg-more;
    }

    &-link {
        @extend .bnr-link;
    }
}

// Mucize Lezzetler
.page-ml {
    .page-cover {
        height: 500px;
    }
    .cover-body {
        display: none;
    }
    .cover-img { 
        // @include responsive(215, 83);
        z-index: $z-low;
        // width: 215px;
        margin: auto;
        color: $c-text-light;
        @include font-set(h-xl);
        text-align: center;
    }

    .tab-content {
        padding: 10px 0 0;
    }
    
    .cont-sect-title {
        @include font-set(h);
        margin-bottom: 30px;
    }

    .events {
        padding: 30px 20px 10px;
        background: $c-bg-light;
    }

    .gallery {
        margin-top: 30px;
        .cont-sect-title {
            margin-bottom: 20px;
        }
        .swiper-button-prev,
        .swiper-button-next,
        .swiper-pagination {
            display: none;
        }
        .swiper-slide {
            width: 325px;
            max-width: 90%;
            + .swiper-slide {
                margin-left: 5px;
            }
        }
        .btn-gallery-close{
            display: none;
            .icon{
                color: $c-white;
            }
        }
    }

    .all-events {
        background: none;
    }

    .recipes {
        padding: 10px 20px 30px;
        .bnr {
            &-media{
                // @include responsive(335, 220);
                height: 264px;
                overflow: hidden;
                figure{
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &-body {
                padding: 10px 0 40px;
            }
            &-info {
                @include line-clamp(4);
                height: 71px;
                margin-top: 5px;
            }
        }
    }
}

// Content Pages (mucize lezzetler)
.cnt {
    &-cover-inner{
        height: 100%;
    }
    &-cover-image {
        height: 500px;
        overflow: hidden;
        figure{
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            img{
                height: 100%;
                max-width: none;
            }
        }
    }
    &-cover-video {
        height: 500px;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: $c-dark;
        }
    }
    &-inner {
        padding: 20px 0;

        > div {
            padding: 0 10px;
            + div {
                margin-top: 20px;
            }
        }

        .cnt {
            &-share {
                text-align: right;
                @at-root {
                    .btn-share {
                        display: inline-flex;
                        align-items: center;
                        margin-left: auto;
                    }
                }
            }

            &-title {
                @include font-set('h-large');
                margin-top: 10px;
            }

            &-subtitle {
                margin-top: 10px;
                color: $c-grey-dark;
            }

            &-evt {
                margin-bottom: 40px;
                .evt {
                    &-item {
                        margin-bottom: 0;
                    }
                    &-details {
                        height: auto;
                    }
                    &-date {
                        border-right: none;
                        width: auto;
                        min-width: 40px;
                    }
                    &-time {
                        margin-bottom: 10px;
                    }
                }
            }

            &-text {
                font-size: 16px;
                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &-products {
        #product-swiper {
            margin: 0;
            padding: 0;
            .swiper-slide {
                &:first-child {
                    margin-left: 20px;
                }
                &:last-child {
                    margin-right: 20px;
                }
            }
        }

        .show-all {
            margin-top: 20px;
            text-align: center;
        }
    }

    &-gallery {
        .cont-sect-title {
            display: none;
        }
    }
}

// Catalogues
.ctl-list {
    padding: 30px 0 60px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    .banner {
        display: flex;
    }
    .bnr {
        &-media {
            max-width: 120px;
            @include responsive(120, 168);
        }
        &-body {
            padding: 0 20px;
        }
        &-title {
            @include font-set(h-xsmall, true);
        }
        &-button {
            display: flex;
            align-items: center;
            @at-root {
                .btn-view {
                    padding: 0 20px;
                }
                .btn-download {
                    padding: 0 15px;
                    &:hover {
                        i {
                            filter: invert(1);
                        }
                    }
                }
            }
        }
    }
}

// Legal Text
.page-legal-text{
    .text-content{
        margin-top: 20px;
        b{
            font-weight: $fw-bold;
        }
    }
    h1{
        @include font-set(h);
        margin-bottom: 20px;
        text-align: center;
    }
}

// Dark Mode Landing
// .dml{
//     padding-top: 60px;
//     &-header{
//         text-align: center;
//         .icon{
//             color: $c-dm-green;
//             font-size: 70px;
//         }
//     }
//     &-title{
//         margin-top: 20px;
//         @include font-set(h-large);
//     }
//     &-info{
//         margin-top: 45px;
//         background-color: $c-secondary;
//         padding: 10px;
//         &-inner{
//             max-width: 700px;
//             margin: 0 auto;
//         }
//         &-block1{
//             padding: 20px 0;
//             border-bottom: 1px solid $c-pdp-border;
//             text-align: center;
//         }
//         &-energy{
//             display: inline-block;
//             position: relative;
//             margin-top: 30px;
//             color: $c-dm-green;
//             span{
//                 font-size: 70px;
//             }
//             small{
//                 font-size: 20px;
//                 position: absolute;
//                 top: 0;
//                 right: 0;
//                 transform: translateX(100%);
//             }
//         }
//         &-label{
//             margin-top: 10px;
//             color: $c-dm-green;
//         }
//         &-block2{
//             width: 100%;
//             max-width: 400px;
//             margin: 0 auto;
//             display: flex;
//             b{
//                 font-weight: 400;
//                 font-size: 30px;
//                 display: block;
//             }
//             span{
//                 color: $c-dm-off-text;
//             }
//         }
//         &-users{
//             border-right: 1px solid $c-pdp-border;
//             padding: 20px 10px;
//             flex: 1;
//         }
//         &-hours{
//             padding: 20px;
//             flex: 1;
//         }
//     }
//     &-content{
//         margin-top: 40px;
//         padding-bottom: 25px;
//         &-img{
//             margin: 40px -20px;
//             @include responsive(799, 560);
//         }
//         &-text{
//             max-width: 700px;
//             margin: 0 auto;
//             padding: 0 10px;
//             .hero{
//                 @include font-set(body-large);
//                 margin: 0;
//             }
//             p{
//                 margin-bottom: 0;
//                 + p{
//                     margin-top: 20px;
//                 }
//             }
//         }
//         &-cta{
//             text-align: center;
//             margin-top: 40px;
//         }
//     }
// }