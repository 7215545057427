// Popup Base
.modal{
    padding:0 !important;

    &-dialog{
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        min-height: 100vh;
        min-height: -webkit-fill-available;
        width: 100%;
    }

    &-header{
        align-items: center;
        padding-bottom: 10px;
        margin: 0 20px;
        justify-content: flex-start;
        border-bottom: 1px solid $c-grey-light;

        &-right{
            margin-left: auto;
            flex: none;
            .close-modal{
                display: inline-block;
            }
        }
        &-icon{
            display: inline-block;
            margin-right: 15px;
        }
    }

    &-title{
        @include font-set(h-xsmall);
    }

    &-body{
        padding: 0 20px;
    }

    &-footer{
        background-color: $c-bg-body;
        padding: 8px 20px;
        justify-content: center;

        & > *{
            margin: 0;
        }

        .mf-left{
            margin-right: auto;
        }
    }

    // Modal Themes
    &-light{
        .modal-content{
            background-color: $c-bg-body;
        }
    }
}

// MODAL FILTER
// A FILTER GROUP
.filter-group{
    &:first-child{
        .acc-item{
            border-top: none;
        }
    }

    .fg-clear{
        display:none;
    }

    .fg-more{
        margin-top: 20px;
        display: none;
    }

    // SHOW MORE/LESS
    &.fg-less{
        .fg-body{
            height: 125px;
            overflow: hidden;
        }

        .fg-more{
            display: inline-block;
        }
    }

    &.fg-type-range{
       .rs-holder{
           padding:60px 22px 20px;
       } 
    }

    &.fg-type-button{
        .btn{
            margin-bottom: 5px;
        }
    }

    &.fg-type-order{
        .fg-body{
            padding-bottom: 20px;
        }
    }

    // WHEN A SELECTION MADE
    &.fg-sel{
        .acc-body{
            padding-top: 0;
        }

        .fg-clear{
            display: inline-block;
            margin-bottom: 20px;
        }

        .fg-body{
            height: auto;
        }

        .fg-more{
            display: none;
        }
    }
}

// A FILTER OPTION
.filter-option{
    padding: 5px;
    display: flex;
    label{
        padding-left: 42px;
    }
    input:checked + label{
        font-weight: $fw-bold;
    }
}

.filter-inputs{
    display: flex;
    padding-bottom: 10px;

    .min{
        margin-right: 10px;
    }
}

// Delivery Date Calculator
.ddc{
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    &-form-info{
        margin-bottom: 30px;
    }
    &-input{
        position: relative;
    }
    &-suggestion{
        position: absolute;
        top: 66px;
        z-index: $z-lower;
        background-color: $c-bg-light;
        padding: 5px 10px 0;
        max-height: 325px;
        overflow: auto;
        display: none;
        button{
            padding: 12px 0;
            border-bottom: 1px solid $c-grey-border;
        }
    }
    &-button{
        margin-top: 20px;
        .btn{
            width: 100%;
        }
    }
    &-body{
        margin: 30px 0;
    }
    &-footer{
        margin-top: auto;
        padding: 30px 0 0;
        &::before{
            content: "";
            @extend .svg;
            @extend .svg-bubble-qm;
            display: inline-block;
        }
        p{
            margin: 0;
        }
    }
    &-product{
        display: flex;
    }
    &-prd-media{
        width: 100px;
    }
    &-prd-text{
        flex: 1;
        padding-top: 10px;
    }
    &-info{
        display: flex;
        padding-top: 25px;
    }
    &-text{
        padding-left: 10px;
    }
    // Events
    &.searched{
        .ddc-suggestion{
            display: block;
        }
    }
}

// Post a Comment
.pcm{
    padding: 30px 0;
    &-item{
        margin-bottom: 20px;
        &-qs{
            margin-bottom: 10px;
        }
        &-opt{
            .btn + .btn{
                margin-left: 10px;
            }
        }
    }
    &-rating{
        float: left;
        input{
            display: inline-block;
            width: 24px;
            height: 24px;
            padding: 0;
            margin: 0;
            display: none;
        }
        label{
            float: right;
            width: 24px;
            height: 24px;
            @extend .svg;
            @extend .svg-star;
            margin: 0 4px 0 0;
            cursor: pointer;
        }
        &:not(:checked) > label:hover, &:not(:checked) > label:hover ~ label, & > input:checked ~ label{
            @extend .svg-star-full;
        }
    }
    &-form{
        .btn-post-comment{
            margin-top: 10px;
            width: 100%;
        }
        .form-send{
            margin-bottom: 0;
        }
    }
    &-result{
        display: none;
    }
    // Actions
    &.sended{
        .pcm-comment{
            display: none;
        }
        .pcm-result{
            display: block;
        }
    }
}

.file{
    &-upload{
        padding: 20px 0;
    }
    &-items{
        &-title{
            @include font-set(body-small);
            margin-bottom: 10px;
        }
        &-list{
            margin-bottom: 20px;
        }
    }
    &-item{
        display: flex;
        align-items: center;
        + .file-item{
            margin-top: 10px;
        }
        // Statuses
        &.uploaded, &.uploading{
            .file-button{
                display: none;
            }
            .file-status{
                display: inline-block;
            }
        }
        &.uploaded{
            .file-status{
                @extend .svg;
                @extend .svg-completed;
            }
            .file-thumb{
                display: inline-block !important;
            }
        }
        &.uploading{
            .file-status{
                background: url(#{$dir-images + 'preloader.svg'}) center center no-repeat;
                background-size: contain;
                width: 32px;
                height: 32px;
            }
        }
    }
    &-name{
        flex: 1;
        margin-left: 10px;
    }
    &-thumb{
        width: 40px;
        height: 40px;
        overflow: hidden;
        margin-left: 10px;
        display: none;
    }
    &-status{
        display: none;
    }
    &-select{
        position: relative;
        display: inline-block;
        input{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: $z-lower;
            cursor: pointer;
            &::-webkit-file-upload-button{
                visibility: hidden;
            }
            &:hover + button{
                background-color: $c-dark;
                color: $c-text-light;
            }
        }
    }
    &-info{
        margin-top: 20px;
        color: $c-text-weak;
    }
}

// Addressbook
#addressbook{
    .radio-list{
        margin: 10px -20px;
    }
    .rl-item{
        position: relative;
        label{
            padding: 27px 20px 20px 62px;
            &::before, &::after{
                top: 20px;
                left: 20px;
            }
        }
        input{
            left: 20px;
            top: 20px;
            &:checked + label{
                background-color: $c-bg-light;
            }
        }
        
        button{
            position: absolute;
            top: 27px;
            right: 20px;
        }
        b{
          @include font-set(h-xsmall);
          display: block;
          margin-bottom: 5px;
          padding-right: 100px;  
        }
    }
    .btn{
        width: 100%;
        margin: 40px 0;
    }
}

// Click & Collect
#click-collect-stores{
    .srv{
        &-list{
            margin: 0 -20px;
        }
        &-item{
            display: flex;
            border-left: none;
            border-right: none;
            &::after{
                display:none;
            }
        }
        &-left{
            padding-right: 5px;
        }
        &-status{
            display: none;
        }
        &-address{
            margin-top: 3px;
        }
        &-stock, &-map{
            margin-top: 10px;
        }
    }
}

// (Add New / Edit / Guest) Address
.adr{
    &-info{
        margin-top: 25px;
    }
    &-types{
        margin-top: 30px;
    }
    &-form{
        display: none;
        padding: 30px 10px;
        .btn{
            width: 100%;
        }
        &.active{
            display: block;
        }
    }
}


// Stock Reminder
#stock-reminder{
    .sr{
        &-result{
            display: none;
        }
        &-form{
            .form-send{
                text-align: center;
            }
        }
        &-prd{
            &-title{
                margin-top: 15px;
                text-align: center;
                @include font-set(h-small);
            }
            padding-bottom: 30px;
            margin-bottom: 50px;
            border-bottom: 1px solid $c-grey-light;
        }
        &-info{
            margin-bottom: 30px;
        }
        &-result{
            padding-top: 30px;
        }
    }
    &.sended{
        .sr-form{
            display: none;
        }
        .sr-result{
            display: block;
        }
    }
}

.otp{
    &-holder{
        padding-top: 30px;
    }
    &-info{
        margin-bottom: 10px;
    }
    &-form{
        .retry{
            padding-top: 10px;
            align-self: center;
        }
        .confirm{
            padding-top: 10px;
            text-align: right;
        }
    }
}

// information message
.msg {
    display: none;
    position: fixed;
    top: 10px;
    left: 50%;
    z-index: $z-ultra;
    transform: translateX(-50%);
    background: $c-bg-light;
    padding: 30px 30px 20px;
    width: calc(100% - 20px);
    max-width: 355px;
    border-radius: $br-popup-middle;
    text-align: center;
    .btn-close {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .t {
        margin-top: 10px;
        @include font-set(h-small);
    }
    .p {
        margin-top: 5px;
    }
    .b {
        margin-top: 20px;
        .btn + .btn{
            margin-left: 10px;
        }
    }
    &.activated {
        display: block;
    }
    &-opened {
        #overlay {
            display: block;
            z-index: $z-ultra - 1;
        }
    }
}

#top-stripe {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 50%;
    z-index: $z-higher;
    transform: translateX(-50%);
    background: $c-bg-light;
    padding: 0 60px 0 20px;
    width: 100%;
    height: $top-stripe-height;
    .p {
        margin-top: 0;
        @include font-set(body-small);
        height: $top-stripe-height;
        display: inline-flex;
        align-items: center;
        > span {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    .btn-str-close {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

.top-stripe-opened {
    #site-main {
        padding-top: $top-stripe-height;
    }
    .header-top {
        top: $top-stripe-height;
    }
}

// Modal Text
.modal-text{
    margin-top: 30px;
    b{
        font-weight: $fw-bold;
    }
}

// Assistant
#modal-assistant{
    .modal{
        &-content{
            background-color: $c-bg-colored;
        }
        &-body{
            padding: 10px 10px 0;
        }
    }
    .banner{
        display: flex;
        flex-direction: column;
    }
    .bnr{
        &-body{
            padding: 00;
            font-size: 0;
        }
        &-media{
            margin: 50px auto 0;
            max-width: 320px;
            mix-blend-mode: darken;
        }
    }
}
