.tss{
  position: relative;
  &-inner{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &-slider {
    position: relative;
    z-index: $z-lower;
    overflow: hidden;
    margin: 0;
    width: 100%;
    height: 100% !important;
    .nav_bar {
      display: none;
    }
  }
  &-images {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: auto;
      &.previous-image {
        visibility: hidden;
        /*
                 Do not put here any other styles.
                 It causes massive recalc / repaints and image flickering,
                 especially on Firefox.
              */
      
      }
      &.current-image {
        visibility: visible;
        /*
                 Do not put here any other styles.
                 It causes massive recalc / repaints and image flickering,
                 especially on Firefox.
              */
      
      }
    }
  }
  &-spinner {
    margin: 0 !important;
    width: 53px;
    height: 53px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: $z-normal;
    position: absolute;
    top: calc(50% - 53px/2);
    left: calc(50% - 53px/2);
    z-index: $z-low;
    background-color: $c-white;
    border-radius: 50%;
    .stroke{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: $z-lower;
      circle{
          stroke-dasharray: 30 270;
          stroke-dashoffset: 0;
          fill: none;
          transform-origin: center;
          stroke-width: 3px;
          stroke: $c-primary;
          animation: rotating 1s linear infinite;
      }
    }
    span{
      @include font-set(h-tiny);
      position: relative;
      z-index: $z-low;
    }
  }
  &-bar{
    position: absolute;
    width: 320px;
    left: calc(50% - 160px);
    bottom: 20px;
    z-index: $z-low;
    height: 32px;
    border: 1px solid $c-white;
    border-radius: 16px;
    padding-left: 32px;
    background-color: transparent;
    opacity: 0;
    .noUi-handle{
      @extend .svg;
      @extend .svg-swipe;
      position: absolute;
      right: 0;
      top: -1px;
      border: none;
      z-index: $z-low;
      cursor: ew-resize;
    }
    & > span{
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      color: $c-text-light;
      @include font-set(h-xsmall);
      z-index: $z-lower;
    }
  }
  &-loaded{
    .tss{
      &-bar{
        opacity: 1;
      }
      &-spinner{
        display: none !important;
      }
    }
    .noUi-handle{
      animation: tssHandle 2.5s 2 $transition-ease-in-out;
    }
    &:hover{
      .noUi-handle{
        animation:none;
      }
    }
  }
}