svg.icon {
    flex: none;
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    font-size: 32px;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;

    &.icon-whats-app {
        color: #00d856;
    }
    &.icon-warning {
        color: $c-warning;
    }
    &.icon-error {
        color: $c-error;
    }
    &.icon-success {
        color: $c-success;
    }
}