// Purchase Pages
.purchase-pages{
    padding-bottom: 0;
    .progress{
        .prg{
            &-header{
                position: static;
                height: auto;
                margin: 30px 0;
            }
            &-inner{
                padding: 0;
                &::before, &::after{
                    display: none;
                }
            }
            &-back{
                margin-right: 0;
            }
            &-title{
                flex: none;
                @include font-set(h);
            }
            &-summary{
                margin-left: 20px;
            }
        }
    }
    #site-footer{
        display: block;
    }
}

.purchase{
    &-container{
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
    }
    &-grid{
        display: flex;
        flex-wrap: wrap;
        > .cart-sum-confirm{
            order: 3;
            width: calc(100% - 490px);
        }
    }
    &-panel{
        order: 2;
        width: 440px;
        position: sticky;
        top: $header-height-desktop + 10;
        align-self: flex-start;
    }
    &-body{
        margin: 0 50px 0 0;
        width: 100%;
        max-width: calc(100% - 490px);
    }
}
.cart{
    &-grid{
        margin-top: 0;
    }
    &-sum{
        position: static;
        padding: 40px 50px 30px;
        background-color: $c-bg-light;
        &-lines{
            display: block;
            padding: 0 10px 20px;
        }
        &-line{
            opacity: 1;
        }
        &-open{
            display: none;
        }
        &-bottom{
            padding: 33px 0 0;
            &::before{
                display: block;
                width: 100%;
                left: 0;
            }
        }
        &-button{
            .btn{
                padding: 0 45px;
            }
        }
        &-confirm{
            display: block;
            padding-top: 20px;
            label{
                padding-left: 42px;
            }
        }
    }
    &-row-promos{
        .nav-tabs.tab-ext{
            margin-left: 20px;
            margin-right: 20px;
        }
        .promo{
            &-selections{
                padding: 0 30px;
            }
            &-header{
                margin: 0 20px;
            }
            &-tabs{
                max-width: none;
            }
            &-title{
                padding: 0 25px;
            }
            &-info{
                padding: 0 25px 30px;
            }
            &-sum{
                padding: 0 40px;
                &-right .btn{
                    background-color: transparent;
                    color: $c-text-dark;
                }
            }
        }
    }
    &-extra{
        .swiper-container{
            max-width: 1200px;
            margin: 0 auto;
        }
        .swiper-pagination{
            display: none;
        }
        .swiper-slide{
            +.swiper-slide{
                margin-left: 20px;
            }
            &:first-child{
                margin-left: 0;
            }
            &:last-child{
                margin-right: 0;
            }
        }
        .section-title{
            @include font-set(h, true);
        }
    }
}

// Masterpass
.mpass{
    &-save-input{
        margin: 0;
    }
    &-account-notify{
        margin: 0 0 15px;
    }
}

// Result
.result {
    &-grid {
        min-height: auto;
        &-header {
            padding: 25px;
        }
        &-footer {
            margin-top: 80px;
        }
    }
    &-steps {
        margin-bottom: 0;
    }
}

// Payment
.pay{
    &-body{
        padding: 0;
    }
    &-delivery{
        margin: 0 0 30px;
    }
    &-payments{
        padding: 0 30px;
        .pay-section-title{
            padding: 0;
        }
    }
}

// Cart Empty
.cart-plh{
    padding-bottom: 80px;
    &-top{
        flex-direction: row;
        margin-top: 130px;
        .icon{
            font-size: 72px;
            color: $c-primary;
        }
        figure{
            border: 5px solid $c-primary;
            width: 120px;
            height: 120px;
            margin-bottom: 0;
        }
        b{
            padding-left: 55px;
            @include font-set(h-large, true);
        }
    }
    &-bottom{
        text-align: left;
        margin-top: 60px;
        p{
            @include font-set(body-xlarge);
            & + p{
                margin-top: 40px;
            }
        }
    }
    &-buttons{
        margin-top: 60px;
        .btn{
            padding: 0 44px;
            width: auto;
            + .btn{
                margin-left: 20px;
            }
        }
    }
}

