// Main Slider
#main-slider {
    // background-color: $c-bg-dark;
    height: 90vh;

    .banner {
        height: 100%;
    }
    .bnr-body {
        padding: 120px 30px 30px;
        transition: transform 1s $transition-ease-out, opacity .666s linear;
        transform: translateY(20px);
        opacity: 0;
    }
    .bnr-media{
        transition: none;
        picture{
            transition: transform 1s $transition-ease-out, opacity .666s linear;
            transform: translateY(50px);
            opacity: 0;
        }
    }
    .bnr-button{
        @include transitions($func: 'eut', $dur: 'slow', $delay: .333s);
        transform: translateY(20px);
        opacity: 0;
    }
    .bnr-header {
        .bnr-info {
            margin-top: 10px;
        }
    }
    .bnr-footer {
        display: none;
        align-items: center;
        justify-content: center;
        border-top: 1px solid rgba($color: $c-white, $alpha: .3);
        min-height: 60px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: $z-normal;
        width: 100%;
        .bnr-info {
            margin: 0;
            @include font-set(body-text);
        }
    }

    .swiper-slide-active {
        .bnr-media {
            picture{
                transform: translateY(0);
                opacity: 1;
            }
        }
        .bnr-body {
            transform: translateY(0);
            opacity: 1;
        }
        .bnr-button {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .swiper-pagination {
        position: absolute;
        bottom: 55px;
        left: 50%;
        z-index: $z-lower;
        transform: translateX(-50%);
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }

    &::after {
        @extend .svg;
        @extend .svg-arrow-b-w;
        content: "";
        position: absolute;
        bottom: 20px;
        left: calc(50% - 16px);
        z-index: $z-lower;
        animation: 2s goToBottom infinite;
    }

    .msv-inline{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z-normal;
        background-color: $c-bg-dark;
        video{
            height: 100%;
        }
    }
    .msv-close{
        @extend .svg;
        @extend .svg-remove-w;
        position: absolute;
        top: $header-height-mobile + 20px;
        right: 20px;
    }
    &.msv-ready{
        .swiper-button-prev, .swiper-button-next, .swiper-pagination{
            display: none !important;
        }
    }
}

// Miracle Flavors
#miracle-flavors{
    display: none;
    margin-top: 30px;
    .bnr-media{
        @include responsive(375, 460);
    }

    .bnr-info{
        margin-top: 20px;
    }

    .bnr-title{
        // @include responsive(215, 83);
        // width: 215px;
        margin: 0 auto;
    }

    // Events
    .events{
        display: none;
    }

    // Recipes
    .recipes{
        display: none;
    }
}

// Deal Countdown
#deal-countdown{
    .swiper-button-next{
        top: 40%;
        right: 20px;
    }

    .swiper-button-prev{
        top: 40%;
        left: 20px;
    }

    .swiper-slide-active{
        .dcd-media{
            transform: scale(1);
        }
    }

    .swiper-pagination{
        display: none;
    }

    .dcd-prd-reviews{
        .rating{
            @extend .svg-stars-alt-empty;
    
            &::before{
                @extend .svg-stars-alt-full;
            }
        }
    }
}

.dcd{
    position: relative;
    padding: 70px 0 30px;
    color: $c-text-light;
    overflow: hidden;

    // &-bg{
    //     width: 1051px;
    //     height: 994px;
    //     position: absolute;
    //     z-index: $z-lower;
    //     left: -110%;
    //     top: -100%;
    //     opacity: .1;

    //     &.bg-loaded{
    //         background: url(#{$dir-images + 'arcelik-yaprak.svg'}) center center no-repeat;
    //     }
    // }

    @each $k, $v in $c-deal-countdown{
        &-bg-#{$k}{
            background-color: $v;
        }
    }

    &-media{
        @include transitions($dur: 'slower', $func: 'eut');
        transform: scale(.8);
        position: relative;
        z-index: $z-low;
    }

    &-content{
        padding: 0 20px;
    }
    
    &-header{
        position: absolute;
        top: 30px;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: $z-low;
    }

    &-hd-left{
        display: none;
    }

    &-hd-right{
        .time{
            @include font-set(h);

            .digit{
                display: inline-block;
                width: 30px;
                text-align: center;
                &.day{
                    width: 60px;
                }
                i{
                    @include font-set(h-tiny, true);
                    padding-right: 20px;
                    font-style: normal;
                    padding-left: 3px;
                }
            }

            .sep{
                animation: blink-animation 1s steps(5, start) infinite;
                padding: 0;
            }
        }
    }

    &-inner{
        display: flex;
        align-items: flex-start;
    }

    &-title{
        width: 60%;
        
        a{
          margin-right: 15px;
          vertical-align: text-bottom;
        }

        > span{
            @include font-set(h);
        }
    }

    &-prd-reviews{
        margin-left: auto;
    }

    &-features{
        margin: 5px 0 20px;

        ul{
            padding-left: 15px;
        }  
    }
    
    &-footer{
        display: flex;
    }

    &-ft-left{
        flex: 1;

        .promo{
            margin-bottom:5px;
        }

        .responsive{
            display:none;
        }

        .prc-first{
            margin-right: 10px;
        }

        .prc-last{
            font-weight: $fw-bold;
        }

        .prc-third{
            color: $c-text-light;
            &-info{
                @include font-set(body-small);
                color: $c-text-light;
            }
        }
    }

    &-ft-right{
        width: 120px;

        .btn{
            padding: 0 30px;
            width: 100%;
        }
    }
}

// Assistant
.page-home{
    #assistant{
        margin-top: 30px;
        // margin-bottom: 50px;

        .banner{
            height: 270px;
        }

        .bnr-body{
            padding-top: 40px;
        }

        .bnr-info{
            max-width: 150px;
            margin: 5px auto 0;
        }
    }
}

#featured-technology{
    margin-top: 40px;
    .bnr-body{
        padding-bottom: 80px;
    }
    .bnr-media{
        @include responsive(680, 482);
    }
    .btn{
        padding: 0 30px;
        height: 34px;
    }
}
