$footer-bottom-padding-x: 20px;

#site-footer {
    position: relative;
    background: $c-bg-grey;
    color: $c-white;
    margin-top: 40px;
}

// top footer
.social-links {
    margin-top: 50px;
    > ul {
        display: flex;
        justify-content: center;
        > li {
            padding: 0 10px;
        }
    }
}

.footer-menu {
    margin-top: 40px;
    color: $c-grey-lighter;
    text-align: center;
    > ul {
        > li {
            > a {
                display: inline-flex;
                align-items: center;
                padding: 8px;
            }
        }
    }
}

.customer-support {
    margin-top: 30px;
    text-align: center;
    > ul {
        > li {
            + li {
                margin-top: 40px;                
            }
        }
    }
    .title {
        color: $c-grey-dark;
    }
    .desc {
        display: inline-flex;
        align-items: center;
        margin-top: 10px;
        @include font-set(h);
    }
    .icon {
        margin-right: 10px;
    }
}

.footer-menu-d {
    $py: 20px;
    color: $c-grey-dark;
    column-count: 4;
    column-gap: 40px;
    margin-top: -$py;
    > ul {
        break-inside: avoid;
        padding-top: $py;
        > li {
            padding: 4.2px 0;
            > a {
                display: inline-flex;
                align-items: center;
            }
            &.title {
                @include font-set(h-xsmall);
                color: $c-white;
                margin-bottom: 10px;
            }
        }
    }
}

// middle footer
.footer-middle {
    margin: 40px auto 0;
    max-width: calc(100% - #{$footer-bottom-padding-x} * 2);
    padding: 20px 10px;
    border-top: 1px solid #3f3f3f;
    display: flex;
    align-items: center;
    .col-right {
        margin-left: auto;
    }
}

// bottom footer
.footer-bottom {
    background: $c-bg-footer-bottom;
    padding: 30px;
    margin-top: 30px;
}
.footer-links {
    color: $c-text-light;
    > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        > li {
            padding: 10px 7px;
            // > a {
            //     display: flex;
            //     align-items: center;
            // }
        }
    }
}
.copyright {
    margin-top: 15px;
    text-align: center;
}


// FOOTER HIDDEN
// .no-footer {
//     #site-footer {
//         > *:not(.btn-page-top) {
//             display: none;
//         }
//     }
//     .btn-page-top {
//         display: block;
//         position: static;
//         margin: 0 auto;
//         padding: 19px;
//         border: none;
//     }
// }