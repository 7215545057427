@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes toTop {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes toTopDelayed{
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    80% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes toBottom {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    100% {
        transform: translateY(100%);
        opacity: 1;
    }
}
@keyframes goToBottom {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    40%{
        transform: translateY(0);
        opacity: 1;
    }
    60%{
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}
@keyframes toRight {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes logo {
    0% {
        transform: rotateX(90deg)
    }

    100% {
        transform: rotateX(0)
    }
}

@keyframes rotating {
    to {
        transform: rotate(360deg)
    }
}

@keyframes blink-animation {
    to {
      visibility: hidden;
    }
}

@keyframes pulse{
    0%  { opacity: 0; transform: scale(.8); }
    0%  { opacity: 0; transform: scale(.9); }
    40% { opacity: 1; transform: scale(1); }
    80% { opacity: 0; transform: scale(1.1); }
    100% { opacity: 0; transform: scale(.9); }
}
@keyframes beat{
    0%  { transform: scale(1.2); }
    50% { transform: scale(1.5); }
    100%{ transform: scale(1.2); }
}

@keyframes pdpAR {
    0%,
    33%{
        opacity: 0;
        padding: 0 0 0 44px;
        max-width: 44px;
        overflow: hidden;
    }
    60% {
        opacity: 1;
        padding: 0 0 0 44px;
        max-width: 44px;
        overflow: hidden;
    }
    100% {
        padding: 0 25px 0 50px;
        max-width: 150px;
    }
}

@keyframes preloader{
    from {
      stroke-dashoffset: 50;
    }
    to {
      stroke-dashoffset: -40;
    }
}

@keyframes tssHandle {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(40%);
    }
    100% {
        transform: translateX(0);
    }
}