// Popup Base
.modal{
    &-dialog{
        min-height: auto;
        height: 100vh;    
    }

    &-header{
        margin: 0 35px;
    }

    &-body{
        padding: 0 50px 30px;
    }

    &-footer{
        padding: 8px 50px;
    }
}

// Delivery Date Calculator
.ddc{
    &-suggestion{
        button{
            &:hover{
                font-weight: $fw-bold;
            }
        }
    }
}

// Addressbook
#modal-select-address{
    .modal-body{
        flex: unset;
    }
}
#addressbook{
    .radio-list{
        margin: 10px -50px;
    }
    .rl-item{
        label{
            padding: 27px 20px 20px 82px;
            &::before, &::after{
                left: 40px;
            }
        }
        input{
            left: 40px;
        }
        button{
            right: 50px;
        }
    }
}

// Click & Collect
#click-collect-stores{
    .srv{
        &-list{
            margin: 0 -50px;
        }
        &-item{
            padding: 20px 40px;
        }
    }
}

// (Add New / Edit / Guest) Address
.adr{
    &-form{
        padding: 30px 0;
    }
}

// information message
#top-stripe {
    text-align: center;
    justify-content: center;
}

.top-stripe-opened {
    .search-input .btn-back {
        top: #{$top-stripe-height + 9px};
    }
}