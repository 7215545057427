.holding{
    // Page Cover
    .page-cover:not(.static){
        height: calc(100vh - #{$header-height-mobile});

        .cover-body{
            padding-bottom: 0;
            opacity: 0;
        }
    }

    // Nav Tabs
    .tab-holder{
        &.tab-alt{
            .nav-link{  
                &.active{
                    border-top-color: transparent;
                    background-color: $c-secondary;
                    color: $c-light;
                }
            }
        }
    }

    // Assistant
    #assistant{
        opacity: 0;
    }
}