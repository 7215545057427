// Purchase Pages
.purchase-pages{
    padding-bottom: 71px;
    .progress{
        .prg{
            &-header{
                z-index: $z-high;
            }
        }
    }
    #site-footer{
        display: none;
    }
}

// Cart
.cart{
    &-grid{
        margin-top: 8px;
    }
    &-clubs{
        .nav-link figure{
            min-width: 40px;
            text-align: center;
        }
        .nav-link img{
            max-width: 40px;
        }
    }
    &-sum{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: $z-highest;
        width: 100%;
        &-lines{
            background-color: $c-bg-light;
            padding: 20px 30px;
            border-radius: 10px 10px 0 0;
            display: none;
            position: relative;
            &::before{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                border-top: 1px solid $c-grey-border;
                display: block;
            }
        }
        &-line{
            display: flex;
            padding: 10px 0;
            opacity: 0;
            @include transitions($prop: opacity);
            &-cta{
                text-align: right;
                *{
                    @include font-set(body-small);
                }
            }
        }
        &-price{
            margin-left: auto;
        }
        &-bottom{
            background-color: $c-bg-light;
            padding: 13px 20px;
            display: flex;
            align-items: center;
        }
        &-total{
            flex: 1;
            padding-left: 10px;
            display: flex;
            align-items: center;
        }
        &-total-left{
            margin-right: 10px;
            small{
                display: block;
                margin-bottom: 5px;
            }
            .prc{
                @include font-set(h-xsmall);
            }
        }
        &-button{
            .btn{
                padding: 0 30px;
            }
        }
        &-open{
            @extend .svg;
            @extend .svg-arrow-b;
            display: inline-block;
            @include transitions();
        }
        &-confirm{
            display: none;
        }
    }
    &-sum-opened{
        .cart-sum{
            &-open{
                transform: scale(-1);
            }
            &-lines{
                display: block;
            }
            &-line{
                opacity: 1;
                animation: toTop 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
            }
        }
        #overlay{
            display: block;
            z-index: $z-higher;
        }
    }
    &-prd{
        display: flex;
        padding: 10px 0 15px 5px;
        &-img{
            width: 80px;
            margin-right: 10px;
        }
        &-details{
            flex: 1;
            padding-top: 10px;
        }
        &-title{
            @include font-set(h-xsmall);
            overflow : hidden;
            height: #{(map-get(map-get($font-sets, h-xsmall), line-height) * 2) + 'px'};
        }
        &-price{
            .prc-first{
                margin-right: 10px;
                color: $c-grey-dark;
            }
            .prc-last{
                font-weight: $fw-bold;
            }
            &-third{
                height: 20px;
                margin-bottom: 5px;
                .prc-third{
                    color: $c-primary;
                    &-info{
                        padding-left: 5px;
                        @include font-set(body-xsmall);
                    }
                }
            }
        }
        &-controls{
            // margin-top: 20px;
            display: flex;
            align-items: center;
        }
        &-qty{
            display: flex;
            border: 1px solid $c-grey-light;
            border-radius: 20px;
            height: 32px;
            padding: 0 3px;
            align-items: center;
            margin-right: 10px;
            .btn-minus, .btn-plus{
                display: inline-block;
                @extend .svg;
                transform: scale(.7);
            }
            .btn-minus{
                @extend .svg-minus;
            }
            .btn-plus{
                @extend .svg-plus;
            }
            input{
                border: none;
                text-align: center;
                width: 20px;
                padding: 0;
                background-color: transparent;
                color: $c-text-dark;
            }
        }
        &-fav{
            @include font-set(body-small);
        }
        &-remove{
            padding-top: 10px;
        }
    }
    &-rows{
        margin: 20px 10px 0;
    }
    &-row{
        padding: 0 10px;
        border: 1px solid $c-grey-border;
        + .cart-row{
            margin-top: 20px;
        }
        &-option{
            border-top: 1px solid $c-grey-border;
            > button{
                height: 50px;
                display: flex;
                align-items: center;
                padding-left: 10px;
                width: 100%;
                &::after{
                    content: "";
                    display: inline-block;
                    @extend .svg;
                    @extend .svg-plus;
                    margin-left: auto;
                }
                &.btn-ddc{
                    padding-left: 5px;
                    &::after{
                        display: none;
                    }
                    span{
                        text-decoration: underline;
                        padding-left: 5px;
                    }
                }
            }
            &-sel{
                height: 50px;
                display: none;
                align-items: center;
                padding-left: 10px;
                width: 100%;
                span{
                    flex: 1;
                }
            }
            &.selected{
                > button{
                    display: none;
                }
                .cart-row-option-sel{
                    display: flex;
                }
            }
            &.ddc-option{
                .cart-row-option-sel{
                    align-items: flex-start;
                    height: auto;
                    padding: 15px 0 15px 5px;
                    span{
                        padding: 5px 0 0 5px;
                    }
                    small{
                        display: block;
                        padding-top: 10px;
                        @include font-set(body-small);
                    }
                }
            }
            &.cpo-option{
                .cart-row-option-info{
                    display: flex;
                    align-items: flex-start;
                    height: auto;
                    padding: 15px 0 15px 5px;
                    span{
                        padding: 5px 0 0 5px;
                    }
                    small{
                        display: block;
                        padding-top: 10px;
                        @include font-set(body-small);
                    }
                    .icon{
                        flex: 0 0 32px;
                    }
                }
            }
        }
        &-promo-stripe{
            background-color: $c-bg-prd-footer;
            display: flex;
            height: 36px;
            align-items: center;
            padding: 0 10px;
            margin: 0 -10px;
            .cart-rpf-icon{
                width: 24px;
            }
            .cart-rpf-text{
                @include font-set(body-small);
            }
        }
        &-promos{
            margin: 0 -10px;
            .accordion > li{
                &.active{
                    background-color: $c-bg-light;
                }
            }
            .acc-item{
                margin: 0 10px;
                button{
                    display: flex;
                    padding: 0 50px 0 0;
                    width: 100%;
                    height: 50px;
                    align-items: center;
                    &::before{
                        content: "";
                        display: block;
                        @extend .svg;
                        @extend .svg-gift;
                        flex: 0 0 32px;
                    }
                    span{
                        padding-top: 3px;
                    }
                    b{
                        margin-right: 3px;
                        color: $c-primary;
                    }
                }
            }
            .nav-tabs.tab-ext{
                padding-left: 10px;
                padding-right: 10px;
            }
            .promo{
                &-sum-right .btn{
                    background-color: transparent;
                    color: $c-text-dark;
                }
                &-header{
                    margin: 0 10px;
                    text-align: left;
                    padding-top: 30px;
                }
                &-title{
                    padding: 0 15px;
                    margin-bottom: 5px;
                }
                &-info{
                    padding: 0 15px 30px;
                }
                &-tab-contents .option-count{
                    padding: 0 20px;
                }
                &-tab-contents .swiper-slide:first-child{
                    margin-left: 10px;
                }
                &-tab-contents .swiper-slide:last-child{
                    margin-right: 10px;
                }
                &-sum{
                    padding: 0 10px;
                    .prc{
                        @include font-set(body-text, true);
                    }
                }
            }
            .prd-row-inner{
                margin: 0 10px;
            }
            &-gift{
                border-top: 1px solid $c-grey-border;
                padding: 10px 10px 15px 15px;
                .prc-gift{
                    color: $c-primary;
                    font-weight: $fw-bold;
                }
            }
        }
        &-set{
            margin: 0 -10px;
            padding: 0 10px;
            + .cart-row-set{
                border-top: 1px solid $c-grey-border-dark;
            }
        }
    }
    &-extra{
        background-color: $c-bg-light;
        padding: 30px 0 40px;
        margin: 50px 0;
        .section-title{
            margin: 0 auto 30px;
            padding: 0;
            max-width: none;
        }
        .swiper-button-prev, .swiper-button-next{
            display: none;
        }
        .swiper-slide{
            width: 180px;
            + .swiper-slide{
                margin-left: 10px;
            }
            &:first-child{
                margin-left: 20px;
            }
            &:last-child{
                margin-right: 20px;
            }
        }
    }
}

.club{
    &-info{
        position: relative;
        padding-left: 40px;
        .quiz{
            position: absolute;
            top: 0;
            left: 10px;
            margin: 0;
        }
        .btn{
            margin-top: 15px;
        }
    }
    &-form{
        padding: 20px 20px 10px 40px;
        .btn{
            margin-top: 15px;
        }
        &-bottom{
            margin-top: 10px;
        }
    }
    &-form-hidden{
        .club-form{
            display: none;
        }
        &.visible{
            .club-form{
                display: block;
            }
            .club-info .btn{
                display: none;
            }
        }
    }
    &-title{
        @include font-set(h-small);
        margin-bottom: 20px;
    }
    &-promos{
        margin-bottom: 30px;
        .club-promo-exp{
            padding-left: 32px;
        }
    }
    &-promo{
        margin-bottom: 10px;
        &-exp{
            @include font-set(body-small);
        }
    }
    &-sum{
        background-color: $c-bg-body;
        padding: 20px;
        margin-top: 30px;
        .club-title{
            margin-bottom: 5px;
        }
        &-info{
            color: $c-grey-dark;
        }
        &-form{
            margin-top: 20px;
        }
        &-lines{
            margin: 10px 0;
        }
        &-line{
            display: flex;
            padding: 10px 0;
        }
        &-price{
            margin-left: auto;
        }
        .btn{
            width: 100%;
        }
    }
    &-etk{
        margin-top: 40px;
        &-warning{
            margin-bottom: 10px;
            b{
                text-decoration: underline;
            }
        }
        &-confirm{
            .checkbox{
                margin-bottom: 5px;
            }
        }
        &-cancel{
            margin-top:20px;
            text-align: right;
            @include font-set(body-small);
        }
    }
    &-coupons-link{
        text-align: right;
        margin-top: 10px;
    }
    &-coupons-head{
        position: relative;
        .hide-cpns{
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    &-coupons-list{
        display:none;
        .cpn{
            &-grid{
                border-color: $c-grey-light;
            }
            &-status{
                display:none;
            }
        }
        .club-title{
            margin: 10px 0;
        }

    }
    &-holder{
        &.coupons-listed{
            .club-coupons-link{
                display:none;
            }
            .club-coupons-list{
                display:block;
            }
        }
    }
}

// Result
.result {
    &-grid {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - #{$header-height-mobile});
        &-header {
            padding: 50px 25px;
            text-align: center;
            .t {
                @include font-set(h);
            }
            .p {
                margin-top: 10px;
            }
            .icon {
                margin-top: 20px;
            }
        }
        &-footer {
            //margin-top: auto;
            padding: 30px 10px;
            display: flex;
            justify-content: center;
            .btn-1 {
                padding: 0 40px;
                + .btn-2 {
                    margin-left: auto;
                    padding: 0 20px;
                }
            }
        }
    }
    &-out-of-stock-list {
        margin-top: 20px;
        .prd-row {
            margin-top: -1px;
            border-top: 1px solid $c-grey-border;
            border-bottom: 1px solid $c-grey-border;
            &-inner {
                margin: 0;
            }
        }
    }
    &-bank-transfer {
        text-align: center;
        p {
            margin-bottom: 5px;
        }
        .btn-iban-no {
            margin-top: 15px;
            text-decoration: underline;
            display: inline-flex;
            align-items: center;
            .icon {
                margin-right: 10px;
            }
        }
    }
    &-new-request {
        @extend .user-page-empty-message;
        margin-top: 0;
    }
    &-cpn {
        padding: 30px 20px;
        background: $c-bg-colored;
        text-align: center;
        .b {
            margin-top: 20px;
        }
        .i {
            margin-top: 20px;
            @include font-set(body-xsmall);
        }
    }
    &-steps {
        margin-top: 50px;
        margin-bottom: 50px;
        .t {
            @include font-set(h);
            text-align: center;
        }
        .w {
            margin-top: 30px;
            display: flex;
            > div {
                flex: 1;
                position: relative;
                text-align: center;
                figure{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 55px;
                    img{
                        max-height: 100%;
                    }
                }
                &.step-1 figure{
                    justify-content: flex-start;
                }
                &.step-3 figure{
                    justify-content: flex-end;
                    img{
                        height: 35px;
                    }
                }
                // @include responsive(90, 55) {
                //     width: 90px;
                //     margin: 0 auto;
                // }
                i {
                    @include font-set(body-xsmall);
                    font-style: normal;
                    color: $c-text-light;
                    position: relative;
                    z-index: $z-lower;
                    display: inline-block;
                    width: 22px;
                    line-height: 22px;
                    text-align: center;
                    background: $c-grey-border;
                    border-radius: 50%;
                    margin-top: 12px;
                }
                &::before,
                &::after {
                    content: "";
                    border-top: 2px solid $c-grey-border;
                    position: absolute;
                    bottom: 10px;
                    left: 0;
                    width: 100%;
                }
                &:first-child {
                    text-align: left;
                    .responsive {
                        margin-left: 0;
                    }
                }
                &:last-child {
                    text-align: right;
                    .responsive {
                        margin-right: 0;
                    }
                }
                &.completed {
                    i {
                        background: $c-primary;
                    }
                    &::after {
                        border-width: 4px;
                        border-color: $c-primary;
                        bottom: 9px;
                        width: calc(100% - 22px);
                    }
                }
            }
        }
        .p {
            margin-top: 10px;
        }
    }
    &-pwd{
        max-width: 425px;
        margin: 30px auto;
        padding: 0 10px;
        .pwd{
            &-title{
                @include font-set(h);
                margin-bottom: 10px;
                text-align: center;
            }
            &-info{
                text-align: center;
            }
            &-form{
                margin-top: 15px;
            }
        }
        .form-row{
            margin-bottom: 0;
            &.submit-btn{
                text-align: right;
            }
        }
    }
}

// Payment
.pay{
    &-grid{
        .cart-sum-confirm{
            display: block;
            padding: 20px 20px 30px;
            background-color: $c-bg-light;
        }
    }
    &-body{
        padding: 10px;
    }
    &-delivery{
        margin: 0 -10px 30px;
        padding: 30px;
        background-color: $c-bg-light;
    }
    &-section-title{
        @include font-set(h-xsmall);
    }
    &-address{
        &-del{
            margin-bottom: 20px;
        }
        &-inv{
            display: none;
            margin-top: 20px;
        }
        &.inv-address-ready{
            .pay-address-inv{
                display: block;
            }
        }
    }
    &-payments{
        padding: 0 10px;
        .pay-section-title{
            padding: 0 20px;
            margin-bottom: 25px;
        }
    }
    &-click-collect{
        margin-bottom: 20px;
    }
    .acc-body{
        padding-top: 0 !important;
    }
}

.form-card-view{
    display: none;
    transform: scale(.80);
}

.credit-card-active{
    .form-card-view{
        display: block;
        margin-bottom: 20px;
    } 
}

// Payment Type: Card
.card{
    &-promo{
        background-color: $c-bg-colored;
        &-title{
            padding: 20px;
            border-bottom: 1px solid $c-colored-border;
        }
        &-info{
            padding: 20px;
            display: flex;
            align-items: center;
            &.gift::before{
                content: "";
                display: inline-block;
                @extend .svg;
                @extend .svg-gift;
                margin-right: 10px;
            }
            p{
                flex: 1;
                margin: 0;
            }
        }
    }
    &-form{
        margin-top: 30px;
        padding: 0 10px;
        display: none;
    }
    &-installments{
        .card-inst-logo{
            margin-bottom: 10px;
        }
        .card-inst-table{
            padding-bottom: 30px;
        }
        th{
            @include font-set(body-small);
            color: $c-grey-dark;
            font-weight: $fw-normal;
        }
        th, td{
           padding: 8px 0;
        }
    }
    &-security{
        padding: 20px 10px;
        img{
            width: 110px;
        }
        p{
            margin: 10px 0 0;
        }
    }
    
}

// Payment Type: Cash
.cash{
    &-form{
        .form-button{
            text-align: right;
        }
    }
}

// Masterpass
.mpass{
    &-saved-list{
        display: none;
        .rl{
            &-item{
                position: relative;
                label{
                    padding-right: 50px !important;
                }
                .btn-remove{
                    display: inline-block;
                    @extend .svg;
                    @extend .svg-delete;
                    position: absolute;
                    top: calc(50% - 16px);
                    right: 10px;
                }
            }
            &-media{
                width: 40px;
            }
        }
    }
    &-save-input{
        background-color: $c-bg-light;
        padding: 15px 30px;
        margin: 0 -20px;
        .btn-holder{
            margin-top: 15px;
            text-align: right;
        }
    }
    &-account-notify{
        background-color: $c-bg-light;
        padding: 15px 20px;
        margin: 0 -20px 15px;
        b{
            display: block;
            margin-bottom: 7px;
        }
        .btn-holder{
            margin-top: 15px;
            text-align: right;
        }
    }
    &-confirmation{
        .textbox{
            margin-top: 20px;
        }
        .mnp-button{
            margin-top: 20px;
        }
    }
    &-retry{
        text-align: center;
        margin-top: 20px;
    }
}

.card-form-ready{
    .card-form{
        display: block;
    }
}
.saved-cards-ready{
    .mpass-saved-list{
        display: block;
    }
}

// Payment Type: Multi Card
// .mcard{
//     padding: 0 10px 10px;
//     &-steps{
//         margin-top: 20px;
//     }
//     &-step{
//         position: relative;
//         padding: 0 0 45px 20px;
//         &::before{
//             content: attr(data-step);
//             width: 22px;
//             height: 22px;
//             background-color: $c-grey-light;
//             display: inline-block;
//             text-align: center;
//             color: $c-white;
//             @include font-set(body-small);
//             line-height: 22px;
//             border-radius: 50%;
//             position: absolute;
//             top: -4px;
//             left: -11px;
//         }
//         &[data-step="1"]{
//             border-left: 2px solid $c-grey-light;
//         }
//         &-title{
//             margin-bottom: 20px;
//         }
//         &-form{
//             display: none;
//             .form-button{
//                 padding-top: 10px;
//             }
//         }
//         &-complete{
//             display: none;
//             .amount{
//                 @include font-set(h-paragraph);
//                 margin-bottom: 5px;
//             }
//         }
//         &-info{
//             color: $c-grey-dark;
//         }

//         &.active{
//             &::before{
//                 background-color: $c-primary;
//             }
//             .mcard-step-form{
//                 display: block;
//             }
//             .mcard-step-info{
//                 display: none;
//             }
//         }
//         &.completed{
//             &::before{
//                 content: "";
//                 @extend .svg;
//                 @extend .svg-completed;
//                 top: -9px;
//                 left: -16px;
//             }
//             .mcard-step-form{
//                 display: none;
//             }
//             .mcard-step-complete{
//                 display: block;
//             }
//         }
//     }
//     &-timer{
//         margin-top: 20px;
//         .time{
//             font-weight: $fw-bold;
//             color: $c-primary;
//         }
//     }
// }

// // Payment Type: Bank Credit
// .credit{
//     &-calc{
//         margin-top: 15px;
//         &-title{
//             text-decoration: underline;
//             margin-bottom: 10px;
//         }
//         &-table{
//             td{
//                 text-align: center;
//                 padding: 10px;
//             }
//         }
//     }
//     &-info{
//         margin-top: 15px;
//         p{
//             color: $c-grey-dark;
//         }
//     }
// }

// // Payment Type: EFT / Bank Transfer
// .accounts{
//     .rl{
//         &-media{
//             width: 120px;
//         }
//     }
//     &-error{
//         color: $c-error;
//         margin-top: 30px;
//     }
//     &-info{
//         margin-top: 40px;
//         p{
//             color: $c-grey-dark;
//         }
//     }
// }

// Cart Empty
.cart-plh{
    &-top{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        .icon{
            font-size: 48px;
            color: $c-primary;
        }
        figure{
            display: flex;
            align-items: center;
            justify-content: center;
            border: 3px solid $c-primary;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-bottom: 20px;
        }
        b{
            @include font-set(h-xsmall);
        }
    }
    &-bottom{
        text-align: center;
        margin-top: 30px;
        p + p{
            margin-top: 30px;
        }
    }
    &-buttons{
        display: flex;
        justify-content: center;
        .btn{
            padding: 0 24px;
            width: 140px;
            + .btn{
                margin-left: 10px;
            }
        }
    }
}
