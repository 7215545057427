// Your variable overrides

// Color system l:6
$theme-colors: ();

// Spacing l:124
$spacers: ();

$sizes: ();

// Body l:160
$body-bg: $c-bg-body;
$body-color: $c-text-dark;

// Font
$font-family-base: $font1;

// Links l:168
$link-color: inherit;
$link-hover-color: inherit;
$link-hover-decoration: none;
// $link-color: inherit;
// $link-decoration: none;
// $link-hover-color: inherit;
// $emphasized-link-hover-darken-percentage: 0;

// Grid breakpoints l:186
$grid-breakpoints: (
  xs: $breakpoint0,
  // sm: $breakpoint1,
  // md: $breakpoint2,
  lg: $breakpoint3
);

// Grid containers l:203
$container-max-widths: ();

// Grid columns l:217
$grid-gutter-width: 0;

// Typography l:268
$font-family-sans-serif: $font1;
$font-family-monospace: $font1;

//1rem default 16px >> 14/16
$font-size-base: 1rem;
$font-weight-bold: $fw-bold;
$font-weight-base: $fw-normal;
$line-height-base: $lh-base;

// Modals l:927
$modal-inner-padding: 0;
$modal-dialog-margin: 0;
$modal-dialog-margin-y-sm-up: 0;
// $modal-content-color: $dark;
$modal-content-bg: $c-bg-light;
$modal-content-border-color: transparent;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
// $modal-backdrop-bg: $dark;
$modal-backdrop-opacity: .3;
$modal-header-border-width: 0;
$modal-header-border-color: transparent;
$modal-header-padding-y: 10px;
$modal-header-padding-x: 0;
$modal-fade-transform: translate(calc(100% + 50px));
$zindex-modal-backdrop: $z-higher;
$zindex-modal: $z-highest;

// Modal sizes
$modal-lg: 500px;

// Alerts
$alert-border-radius: 0;
$alert-padding-y: 1.25rem;
$alert-padding-x: 1.25rem;

// Tabs
$nav-link-padding-y: .875rem;
$nav-link-padding-x: 22/$px-to-rem#{rem};
$nav-tabs-border-width: 0;
$nav-tabs-border-radius: 0;
$nav-tabs-border-color: transparent;
$nav-tabs-link-hover-border-color: $c-primary;
$nav-tabs-link-active-border-color: $c-primary;
$nav-tabs-link-active-color: $c-text-dark;
$nav-tabs-link-active-bg: transparent;

$nav-pills-border-radius: 22.5px;
$nav-pills-link-active-color: $c-light;
$nav-pills-link-active-bg: $c-primary;

// ...
// Include parts of Bootstrap
// Required Files
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

// Edit mixins
@mixin bg-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    background-color: $color;
  }
}

@mixin hover(){
  @content;
}