/* CSS RESET */
.text-right {
    text-align: right;
}
.border-bottom {
    border-bottom: 1px solid #b7b7b7;
}
.d-none {
    display: none;
}

.mt-10 {
    margin-top: 10px;
}
/* Cart */
.arc-gift-h1 {
    font-size: 14px;
    padding: 16px 0px;
}
.arc-form {

    padding: 21.5px;
}
.arc-btn {
    color: #222 !important;
}
.arc-weight {
    font-weight: 500 !important;
}
.arc-row {
    display: flex;
    align-items: center;
    padding-top: 38px;
}
.arc-row .arc-gift-point {
   margin-left: auto;
}
.arc-notification {
    color: #d93131;
    line-height: 16px;
    font-size: 12px;
    padding-top: 14px;
}
.arc-flex {
    display: flex;
    align-items: center;
}
.arc-iptal {
    text-decoration: underline;
    color: #ff0000;
    font-size: 12px;
    display: block;
    cursor: pointer;
    padding-left: 12px;
}
#gift-point {
    background: transparent;
    border-right: none;
    border: none;
    border-bottom: 1px solid #d8d8d8 !important;
}

.edit-button {
    font-size: 9px;
    text-align: right;
}
.ems-btn {
    width: 95px !important;
    height: 24px !important;
}
.gift-puan{ 
    color: #fff;
}
.ems-user-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.my-20 {
    margin: 75px 0px;
}
.item.total {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #e5e5e5;
}
.gift-price {
    font-size: 16px;
    
}
/* Desktop */
@media only screen and (min-width: 1025px) {
    .ems-center .ord-block2 {
        justify-content: end !important;
    }
    .ems-center {
        text-align: end;
    }
    .ems-flex {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 16px;
    }
}