$bnr-body-padding: 30px;

.banner {
    position: relative;
    overflow: hidden;
    text-align: center;
    p {
        margin: 0;
        + p {
            margin-top: 30px;
        }
    }
    .btn {
        + .btn {
            margin-left: 10px;
        }
    }
}
.bnr {
    &-media {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: $z-lower;
        width: 100%;
        @include transitions($func: 'eut', $dur: 'normal');

        img,
        video {
            width: 100%;
        }
        video {
            &[poster] {
                object-fit: cover;
                object-position: center;
            }
        }

        &-info {
            &-video {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 10px 10px 20px;
                @include font-set(body-small);
                color: $c-text-light;
                &::after {
                    @extend .svg;
                    @extend .svg-video-type;
                    content: "";
                    display: block;
                }
            }
        }
    }

    &-body {
        position: relative;
        top: 0;
        left: 0;
        z-index: $z-low;
        width: 100%;
        height: 100%;
        padding: $bnr-body-padding;
        display: flex;
        flex-direction: column;
    }

    &-footer {
        margin-top: auto;
    }

    &-title{
        @include font-set(h);
    }

    &-info {
        margin-top: 5px;
        &:first-child {
            margin-top: 0;
            margin-bottom: 5px;
        }
    }

    &-button {
        margin-top: 20px;
    }

    &-price {
        margin-top: 30px;
        .prc {
            &-first {
                color: #989898;
                margin-right: 10px;
            }
        }
    }

    &-link{
        position: absolute;
        top: 0;
        left: 0;
        z-index: $z-low;
        width: 100%;
        height: 100%;
    }

    // THEME
    @each $i, $clr in $c-banner{
        &-bg-#{$i} {
            @each $p, $v in $clr{
                #{$p}: $v;
            }       
        }
    }
    
    @at-root {
        [class*="bnr-gr"] {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: $z-lower;
                width: 100%;
                height: 100%;
            }
        }
    }
    &-gr-1 {
        color: $c-white;
        &:after {
            background: rgba(0, 0, 0, 0.35);
        }
    }
    &-gr-2 {
        color: $c-white;
        &::after {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 12%, rgba(0, 0, 0, 0.5));
        }
    }
    &-icon {
        .bnr-footer {
            position: relative;
            padding-right: 60px;

            &::after {
                @extend .svg;
                @extend .svg-plus-c;
                content: "";
                position: absolute;
                right: -10px;
                top: calc(50% - 16px);
                filter: invert(1);
            }
        }
    }

    // ALIGNMENT
    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }

    // LAYOUT
    &-static {
        .bnr {
            &-media {
                position: relative;
            }
            &-info {
                margin-top: 20px;
            }
        }
    }

    &-cover {
        .bnr {
            &-media {
                position: relative;
            }
            &-body {
                position: absolute;
            }
        }
    }

    &-middle {
        .bnr {
            &-header {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }

    &-hrz {
        .bnr {
            &-media {
                max-width: 250px;
                top: 0;
                right: 0;
                bottom: auto;
                left: auto;
                transform: none;
            }
            &-title {
                @include font-set(h);
            }
        }
    }

    &-full-img{
        .bnr {
            &-media {
                height: 100%;
                * {
                    height: 100%;
                }
                img {
                    object-fit: cover;
                }
            }
        }
    }

    &-promo{
        .bnr {
            &-media {
                position: static;
            }

            &-body{
                text-align: left;
            }

            &-info{
                margin-top: 10px;
                @include font-set(body-text);
            }

            &-footer{
                display: none;
            }
        }
    }
}

// Category Banners
#category-banners {
    .swiper-slide {
        max-width: 325px;
        + .swiper-slide{
            margin-left: 10px;
        }
        &:first-child{
            margin-left: 15px;
        }
        &:last-child{
            margin-right: 15px;
        }
    }

    .banner {
        height: 250px;
    }
}

// Assistant
#assistant{
    .banner{
        background-color: $c-bg-assistant;
    }
    
    .bnr-info{
        @include font-set(body-text);
    }

    .bnr-media{
        margin: 0 auto;
        width: 320px;
        bottom: -60%;
        left: calc(50% - 160px);
        text-align: center;
        mix-blend-mode: darken;
    }
}