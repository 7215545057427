    // Category Swiper
#category-swiper{
    .swiper-container{
        padding: 0 40px;
    }

    .swiper-scrollbar{
        width: calc(100% - 80px);
        display: block;
        max-width: 1520px;
    }

    .swiper-slide{
        width: 190px;
        height: 205px;
        padding: 0 10px;
    }
    &.c-aligned{
        .swiper-wrapper{
            justify-content: center;
        }
    }
}

.page-home{
    #category-swiper{
        @include cont-aligner(1600px);
    }
}

// Product Swiper
#product-swiper{
    margin-bottom: 120px;
    @include cont-aligner(1600px);
    
    > .swiper-wrapper {
        > .swiper-slide {
            width: 325px;
            + .swiper-slide {
                margin-left: 20px;
            }
            &:first-child {
                margin-left: 40px;
            }
            &:last-child {
                margin-right: 40px;
            }
        }
    }
}

// Popular Products
#popular-products {
    margin-top: 20px;
    // margin-bottom: 50px;
    @include cont-aligner(1600px);

    .swiper-slide {
        width: calc(100% / 3);
        max-width: 500px;
        &:first-child{
            margin-left: 40px;
        }
        &:last-child{
            margin-right: 40px;
        }
    }
    [class*="swiper-button-"]{
        display: inline-block;
        top: auto;
        bottom: 0;
    }
    .swiper-button-prev{
        left: #{"calc(max(100% + ((100% - 1600px) / 2), 100%) / 2 - 110px)"};
    }
    .swiper-button-next{
        right: #{"calc(min(100% - ((100% - 1600px) / 2), 100%) / 2 - 110px)"};
    }

    .swiper-pagination{
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .banner {
        height: 680px;
    }

    .bnr-body{
        padding: 60px;
    }

    .bnr-title{
        @include font-set(h-large, true);
    }

    .bnr-header{
        height: 200px;
    }

    .bnr-footer {
        margin-top: 0;
    }
}

// Featured Techs
#featured-techs{
    // margin-bottom: 20px;

    &.in-view .swiper-slide-active{
        .bnr-media{
            transform: scale(1);
        }

        .bnr-footer{
            &::after{
                transform: rotate(0);
            }
        }
    }

    .banner{
        width: 100%;
        height: auto;

        .bnr-media, .bnr-footer{
            @include transitions($prop: transform, $dur: 'faster', $func: 'eut');
        }

        &:hover{
            .bnr-media{
                transition-duration: 1.5s;
                transform: scale(1.2);
            }

            .bnr-footer::after{
                transition-duration: 1.5s;
                transform: rotate(180deg);
            }
        }
    }

    .swiper-slide{
        padding: 0;
        width: 25%;
        margin: 0 !important;
    }

    .swiper-pagination{
        display: none;
    }
}

// Best Sellers
#best-sellers{
    margin: 0 -10px;
}
