// Category Swiper
#category-swiper{
    padding: 15px 15px 0;
    // margin-bottom: 30px;
    
    .swiper-slide{
        width: 130px;
        height: 180px;
        text-align: center;
        padding: 0 5px;
    }

    .swiper-scrollbar, .swiper-pagination, [class*="swiper-button-"]{
        display: none;
    }
}

// Product Swiper
#product-swiper{
    margin-bottom: 60px;

    > .swiper-wrapper {
        > .swiper-slide {
            width: 315px;
            margin-top: 2px;
            + .swiper-slide {
                margin-left: 10px;
            }
            &:first-child {
                margin-left: 15px;
            }
            &:last-child {
                margin-right: 15px;
            }
        }
    }

    [class*="swiper-button-"]{
        display:none;
    }
}

// Popular Products
#popular-products {
    .swiper-slide {
        max-width: 305px;
        + .swiper-slide{
            margin-left: 10px;
        }
        &:first-child{
            margin-left: 15px;
        }
        &:last-child{
            margin-right: 15px;
        }
    }

    [class*="swiper-button-"]{
        display:none;
        // @extend .svg-swiper-next;
    }

    .banner {
        height: 400px;
    }

    .bnr-media{
        width: 120%;
        left: -10%;
        transform: translateY(30%);
    }

    .bnr-title{
        @include font-set(h);
    }

    .bnr-price{
        margin-top: 20px;
        &-third{
            margin-top: 20px;
        }
    }

    .bnr-header{
        opacity: 0;
        transform: translateY(20px);
        @include transitions($dur: 'normal', $func: 'eut');
    }

    .bnr-footer{
        opacity: 0;
        transform: translateY(20px);
        @include transitions($dur: 'normal', $func: 'eut', $delay: .111s);
    }

    &.in-view .swiper-slide-active{
        .bnr-header, .bnr-footer{
            opacity: 1;
            transform: translateY(0);
        }
    }

    // [class*="swiper-button-"]{
    //     display:none;
    // }
}

// Featured Techs
#featured-techs{
    // margin-bottom: 40px;

    .banner{
        width: 295px;
        height: 400px;
    }

    .bnr-media{
        @include responsive(340, 575);
        @include transitions($prop: transform, $dur: 'slower', $func: 'eut');
    }

    .bnr-body{
        padding: 30px 20px;
    }

    .bnr-footer{
        opacity: 0;
        transform: translateY(20px);
        @include transitions($dur: 'normal', $func: 'eut');

        &::after{
            @include transitions($dur: 'normal', $func: 'eut', $delay: .333s);
            right: 0;
        }
    }

    &.in-view .swiper-slide-active{
        .bnr-media{
            transform: scale(1.2);
        }

        .bnr-footer{
            opacity: 1;
            transform: translateY(0);

            &::after{
                transform: rotate(180deg);
            }
        }
    }

    .swiper-slide{
        width: auto;

        + .swiper-slide{
            margin-left: 5px;
        }
        &:first-child{
            margin-left: 15px;
        }
        &:last-child{
            margin-right: 15px;
        }
    }

    [class*="swiper-button-"]{
        display:none;
    }
}

// Best Sellers
#best-sellers{
    margin: 0 -20px;
    padding: 0 10px;
    .swiper{
        &-slide{
            width: 180px;
            margin: 0 10px;
        }
    }
    [class*="swiper-button-"]{
        display:none;
    }

}