// Category Page
.brand-list{
    grid-template-columns: repeat(4, 235px);
    gap: 30px;

    li{
        height: 235px;
    }
}

.category-list{
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, minmax(auto, 250px));
    gap: 30px;
}

// .category-cover{
//     height: 375px !important;
// }

// Product List Page
.top-panel {
    flex-wrap: nowrap;
    padding-top: 30px;
    padding-bottom: 30px;
    .pnl {
        &-left {
            order: 1;
        }
        &-middle {
            width: auto;
            margin-top: 0;
            order: 2;
        }
        &-right {
            order: 3;
        }
    }
}

.btn-filter {
    margin-left: 20px;
}

.primary-filters {
    margin: 0;
    > a{
        margin-bottom: 10px;
    }
}

// Assistant
.page-product-list{
    #assistant:not(.assistant-in-list){
        max-width: 1440px;
        padding: 0 40px;
        margin: 0 auto;
        .bnr{
            &-body{
                flex-direction: row;
                align-items: center;
                padding: 60px 80px;
            }
            &-footer{
                margin: 0;
            }
            &-button{
                margin: 0 auto;
                .btn{
                    padding: 0 44px;
                }
            }
            &-media{
                display: block;
                top: 30px;
                left: 25%;
            }
        }
    }

    .category-cover{
        height: 500px;
    }
}

.products {
    grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
    gap: 40px 20px;
    padding: 0 40px;

    #assistant{
        grid-column: auto;
        margin: 0;
        padding: 0;
        .banner{
            height: 100%;
        }

        .bnr{
            &-button{
                margin-top: 65px;
                display: block;
                text-align: center;

                .btn{
                    min-width: 140px;
                    margin: 0 10px 20px;
                }
            }
            &-footer{
                margin-top: 30px;
            }
            &-media{
                display: block;
                bottom: -20%;
            }
        }
        &.iframed{
            height: $product-card-height;
            overflow: hidden;
            background-color: $c-bg-colored;
            .banner{
                height: 100%;
            }
            .bnr{
                &-body{
                    padding: 20px;
                }
                &-media{
                    bottom: -30%;
                }
                &-header{
                    display:none;
                }
                &-footer{
                    display: none;
                }
                &-iframe{
                    display:block;
                }
            }
        }
    }
}

// Product Scroller
.product-scroll{
    display: flex;
    justify-content: center;
    padding: 0;

    .prd{
        max-width: 325px;
    }
}

.category-explorer {
    display: flex;

    > a {
        &:hover{
            background: darken($c-light, 10%);

            figure {
                transform: scale(1.1);
            }
        }
    }
}

// Category
.page-category{
    .tab-content{
        padding: 50px 40px;
    }
    .category-list{
        margin: 15px 0 35px;
    }
}

// Live Chat
#live-chat{
    max-width: 1440px;
    padding: 0 40px;
}

.lcs{
    padding: 22px;
    &-inner{
        display: flex;
        align-items: center;
        max-width: 600px;
        margin: 0 auto;
        padding: 0;
    }
    &-media{
        width: 105px;
        margin-right: 15px;
        position: static;
        transform: none;
    }
    &-info{
        flex: 1;
        margin-bottom: 0;
        text-align: center;
    }
    &-button{
        margin-left: 15px;
    }
}

// Promotion List
.page-promotion-list{
    .page-title{
        padding: 40px 0;
    }

    .tab-holder{
        max-width: 1260px;
        margin: 0 auto 130px;
        text-align: center;
    }

    .nav-tabs, .nav-pills{
        display: inline-flex;
        width: auto;
    }

    .banner{
        margin-bottom: 10px;
    }
}

// Promotion User OTP
.otpromo{
    width: 100%;
    margin: 0 0 50px 0;
    &-block1{
        padding: 0 8px;
        .icon{
            font-size: 38px;
        }
    }
    &-title{
        @include font-set(h);
    }
    &-form{
        &-input{
            width: 250px;
        }
    }
}

// Promotion Detail
.page-promotion-detail{
    .bnr-promo{
        padding: 60px 0;
        @include cont-aligner(1200px);
        max-height: 60vh;
        .bnr-media{
            max-width: 60vh;
            align-self: center;
        }
    }
}

// Promotion
.promo{
    &-inner{
        max-width: 960px;
        margin: 0 auto;
    }
    &-header{
        margin: 0;
        padding-top: 60px;
    }
    &-title{
        @include font-set(h-large, true);
        margin-bottom: 45px;
    }
    &-tabs{
        max-width: 540px;
        margin: 0 auto;

        .nav-link{
            flex: 1;
        }
    }
    &-tab-contents{
        .option-count{
            padding: 0;
            text-align: center;
        }

        .swiper-slide{
            width: 195px;
            padding: 0 7.5px;
            &:first-child{
                margin-left: 0;
            }

            &:last-child{
                margin-right: 0;
            }
        }
    }
    &-selections{
        margin-top: 60px;
    }
    &-sum{
        &-right{
            .btn{
                padding: 0 45px;
            }
        }
    }
    &-conditions{
        padding: 60px 0 120px;
    }
    &-products{
        margin: 0;
        #product-swiper{
            padding: 0 90px;
            &::before, &::after{
                content: "";
                display: inline-block;
                background-color: $c-bg-body;
                height: 100%;
                width: 90px;
                position: absolute;
                top: 0;
                z-index: $z-low;
            }
            &::before{
                left: 0;
            }
            &::after{
                right: 0;
            }
        }
        #product-swiper>.swiper-wrapper>.swiper-slide:first-child{
            margin-left: 0;
        }
        #product-swiper>.swiper-wrapper>.swiper-slide:last-child{
            margin-right: 0;
        }
        #product-swiper > [class*="swiper-button-"]{
            display: block !important;
            z-index: $z-normal;
        }
        #product-swiper > .swiper-button-prev{
            left: 0;
        }
        #product-swiper > .swiper-button-next{
            right: 0;
        }
    }
    &-row{
        margin-top: 60px;
        .promo-title{
            padding: 0 90px;
            margin-bottom: 30px;
        }
    }
}

// Compare Page
.page-compare {
    .page-title {
        text-align: center;
    }
    .compare-holder{
        margin: 0 -20px;
        .swiper-slide{
            &:first-child{
                margin-left: 20px;
            }
            &:last-child{
                margin-right: 20px;
            }
        }
    }
}

.compare-holder{
    .swiper-slide{
        width: 280px;
        + .swiper-slide{
            margin-left: 20px;
        }
        &:first-child{
            margin-left: 0;
        }
        &:last-child{
            margin-right: 0;
        }
    }

    .swiper-compare {
        &-prev,
        &-next {
          display: inline-block;
        }
      }
}

.compare-top-panel {
    top: $header-height-desktop;
}

#compare-products, .compare-top-panel {
    .swiper-pagination {
        display: none;
    }
}

#compare-all-features {
    .acc-item {
        margin: 0;
    }
}
.btn-open-acc-items {
    margin: 30px auto;
}

.compare-bottom-panel {
    position: static;
    background: transparent;
    margin: 0 170px;
    width: auto;
}

.cmp-add{
    height: 425px;
}

// Search Results Page
.page-search-results {
    .nav {
        &-link {
            flex-grow: 0;
            min-width: 160px;
        }
    }
}

// Product Detail Page
.pdp {
    // background: #ecedef;
    // padding-top: $header-height-desktop;
    .breadcrumb {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    &-breadcrump{
        position: sticky;
    }
    &-grid {
        display: grid;
        grid-template-columns: minmax(20px, 1fr) minmax(auto, calc(100vh - (#{$header-height-desktop + 170px}))) minmax(100px, .7fr) 440px minmax(20px, 1fr);
        grid-template-areas: ". ga1 ga1 ga1 ." ". ga2 . ga3 ." "ga4 ga4 ga4 ga4 ga4";
    }
    &-more {
        grid-area: ga1;
        height: 25px;
        display: block;
        .btn-more {
            position: absolute;
            top: 25px;
            right: 0;
        }
    }
    &-gallery {
        grid-area: ga2;
        align-self: flex-start;
        position: sticky;
        top: $header-height-desktop;
        overflow: hidden;
        margin-bottom: 150px;
        padding-bottom: calc((50px - 32px) / 2); // for 360 icon
        .swiper-controls{
            position: relative;
            height: 32px;
        }
        .swiper-button-prev,
        .swiper-button-next {
            display: block;
            top: calc(100% - 16px);
        }
        .swiper-button-prev {
            left: 30%;
        }
        .swiper-button-next {
            right: 30%;
        }
        .swiper-pagination {
            // margin-bottom: calc((32px - 7px) / 2);
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
        }
        &.view-360-ready{
            .swiper-container, .swiper-controls{
                visibility: hidden;
            }
            .pdp-360 .icon-close{
                display: inline-block;
            }
            .pdp-360 .icon-360{
                display: none;
            }
            #view-360{
                display: block;
                position: absolute;
                height: calc(100% - 60px);
                width: 100%;
                top: 0;
                left: 0;
            }
            // #model-viewer{
            //     width: 100%;
            //     height: 100%;
            // }
        }
        // &.view-360-loading{
        //     .pdp-360 .stroke{
        //         opacity: 1;
        //         animation: rotating 1s linear infinite;
        //     }
        //     .pdp-360 .icon-360{
        //         visibility: hidden;
        //     }
        //     #view-360{
        //         z-index: $z-normal;
        //         display: block;
        //     }
        // }

        &-360{
            display: block;
            position: absolute;
            top: calc(50% - 40px);
            left: calc(50% - 40px);
            background-color: $c-white;
            border-radius: 50%;
            padding: 20px;
            z-index: $z-lower;
            cursor: pointer;
            .icon{
                font-size: 60px;
                color: $c-text-dark;
            }
            .swiper-slide-zoomed &{
                display: none;
            }
        }
    }
    &-ar {
        display: none;
    }
    &-360 {
        display: block;
        .icon-close{
            display: none;
        }
        .stroke{
            position: absolute;
            top: -2.5px;
            left: -2.5px;
            width: 55px;
            height: 55px;
            opacity: 0;
            @include transitions($prop: opacity);
            circle{
                stroke-dasharray: 30 270;
                stroke-dashoffset: 0;
                fill: none;
                transform-origin: center;
                stroke-width: 3px;
                stroke: $c-primary;
            }
        }
    }
    &-details {
        grid-area: ga3;
        padding: 0;
        &::before {
            display: none;
        }
    }
    &-name {
        margin-top: 0;
        justify-content: flex-start;
        text-align: left;
        padding: 0 100px 0 0;
    }
    &-reviews {
        justify-content: flex-start;
        .qty{
            cursor: pointer;
        }
    }
    &-sizes {
        justify-content: flex-start;
    }
    &-colors {
        justify-content: flex-start;
    }
    &-warranty {
        margin-top: 30px;
    }
    &-installation{
        border: none;
        .btn-installation {
            padding: 14px 20px;
        }
    }
    &-purchase {
        margin-top: 30px;
        padding: 0;
        border: none;
        &-ready{
            .pdp{
                .pdp-purchase-fixed {
                    .t{
                        display: block;
                    }
                }
                .pdp-purchase-inner{
                    // .left > *{
                    //     display: inline;
                    // }
                    .pdp-price{
                        margin-right: 20px;
                    }
                }
            }
        }
        &-opened {
            .pdp{
                .pdp-purchase-inner{
                    .left > *{
                        display: block;
                    }
                    .pdp-price{
                        margin-right: 0;
                    }
                }
            }
        }
    }
    &-add-to-cart {
        width: 40%;
        .btn {
            width: 100%;
        }
    }
    &-info1 {
        margin-top: 20px;
        border: none;
    }
    &-benefits {
        margin: 30px -20px 0;
        .swiper-slide {
            max-width: 140px;
        }
        .swiper-button-next{
            display: inline-block;
            right: 5px;
        }
        .swiper-button-prev{
            display: inline-block;
            left: 5px;
            &.swiper-button-disabled{
                display: none;
            }
        }
    }
    &-features {
        margin-top: 50px;
        padding-bottom: 0;
    }
    &-bottom {
        grid-area: ga4;
        display: flex;
    }
    &-awards {
        &-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px 130px;
            .item {
                display: flex;
                align-items: flex-start;
                .responsive {
                    margin-bottom: 0;
                    flex: none;
                    margin-right: 30px;
                }
                + .item {
                    margin-top: 0;
                }
            }
        }
    }
    &-installments {
        .nav-link {
            flex-grow: 0;
            min-width: 160px;
        }
        &-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px 130px;
            .item {
                .responsive {
                    margin-top: 0;
                }
                + .item {
                    margin-top: 0;
                    border: none;
                }
            }
        }
    }
    &-promotions{
        .tab-ext{
            padding: 0;
        }
        .banner{
            margin: 0;
        }
    }
    &-featured-content{
        // AR Content
        .ar{
            &-content{
                text-align: left;
                min-height: 400px;
                display: flex;
            }
            &-body{
                padding: 70px 60px;
                width: 60%;
            }
            &-text, &-button, &-info{
                display: none;
            }
            &-text-d{
                display: block;
            }
            &-media{
                align-self: flex-end;
            }
        }
    }
    &-compare{
        .compare-holder{
            max-width: 880px;
            margin: 0 auto;
        }
    }

    // Mini View
    &-mini {
        $pr: 350px;
        $mw: 760px;
        &-block1 {
            max-width: $mw;
            margin: 0 auto;
            padding-right: $pr;
            padding-left: 20px;
        }
        &-container{
            padding: 0;
        }
        &-name {
            .title {
                @include font-set(h-large, true);
            }
        }
        &-features {
            max-width: $mw;
            padding-right: $pr;
        }
    }
    &-preorder{
        margin-top: 20px;
        &-info{
            align-items: center;
            margin-top: 30px;
            border-top: none;
            border-bottom: none;
            padding: 15px;
            background-color: rgba($c-grey-dark, .4);
        }
    }
}

// Rich Content
.ftc{
    &-item{
        flex-direction: row;
        align-items: center;
    }
    &-info{
        padding: 70px 150px 100px;
        flex: 1;
        align-self: center;
    }
    &-media{
        flex: 1;
        width: 39%;
    }

    // Types
    &-text-only{
        &.ftc-item{
            justify-content: center;
        }
        .ftc-info{
            text-align: center;
            max-width: 880px;
        } 
    }
    &-full{
        .ftc-media{
            max-width: none;
            .responsive{
                width: 100%;
                height: auto;

                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
        .ftc-info{
            bottom: 100px;
            left: 150px;
            padding: 40px;
        }
        .ftc-text{
            color: $c-text-light;
        }
    }

    &-left{
        .ftc-media{
            margin-left: auto;
        }
        .ftc-info{
            padding: 0 150px;
        }
        & + .ftc-right{
            border-top: 1px solid $c-pdp-border;
        }
    }
    &-right{
        .ftc-info{
            order: 2;
            padding: 0 100px;
        }
        .ftc-media{
            order: 1;
        }
        & + .ftc-left{
            border-top: 1px solid $c-pdp-border;
        }
    }

    &-tab-item{
        position: relative;
        margin-top: 75px;
        .ftc-tabs{
            justify-content: center;
            button{
                padding: 10px 30px;
                flex: none;
            }
        }
       // .ftc-item{
       //     
       //     .ftc-info{
       //         margin-bottom: 60px;
       //         padding: 0 0 0 60px;
       //     }
       // }
    }
}

// All Reviews
.rvw{
    &-holder{
        display: flex;
    }
    &-panel{
        order: 2;
        width: 330px;
        margin-left: 100px;
        align-self: flex-start;
        position: sticky;
        top: $header-height-desktop + 20px;
    }
    &-list{
        order: 1;
        flex: 1;
        margin-top: 0;
    }
}

#rvw-mnp{
    .swiper-slide{
        max-width: 650px;
        height: 80vh;
        + .swiper-slide{
            margin-left: 20px;
        }
        &:first-child{
            margin-left: 60px;
        }
        &:last-child{
            margin-right: 60px;
        }
    }
    .swiper-button-next{
        display: inline-block;
        right: 10px;
    }
    .swiper-button-prev{
        display: inline-block;
        left: 10px;
    }
}

// Built-in Set
.set{
    &-container{
        max-width: 1200px;
        margin: 0 auto;
    }
    &-item{
        + .set-item{
            margin-top: 80px;
        }
    }
    &-title{
        padding: 25px 30px;
    }
    &-products{
        #product-swiper{
            padding: 0 90px;
            &::before, &::after{
                content: "";
                display: inline-block;
                background-color: $c-bg-body;
                height: 100%;
                width: 90px;
                position: absolute;
                top: 0;
                z-index: $z-low;
            }
            &::before{
                left: 0;
            }
            &::after{
                right: 0;
            }
        }
        #product-swiper>.swiper-wrapper>.swiper-slide:first-child{
            margin-left: 0;
        }
        #product-swiper>.swiper-wrapper>.swiper-slide:last-child{
            margin-right: 0;
        }
        #product-swiper > [class*="swiper-button-"]{
            display: block !important;
            z-index: $z-normal;
        }
        #product-swiper > .swiper-button-prev{
            left: 0;
        }
        #product-swiper > .swiper-button-next{
            right: 0;
        }
    }
    &-sum{
        margin-left: 70px;
        max-width: 375px;
    }
    &-more{
        margin-top: 80px;
    }
}

// Assistant Page
#assistant-wizard{
    height: calc(100vh - #{$header-height-desktop});
}

.asw{
    &-header{
        width: 100%;
        max-width: 1240px;
        padding: 50px 20px 0;
        margin: 0 auto;
    }
    &-media{
        width: 340px;
        left: calc(50% - 170px);
    }
    &-enter{
        margin: 0;
        @include font-set(h-large, true);
        text-align: left;
    }
    &-body{
        width: 100%;
        max-width: 1240px;
        padding: 0 20px;
        margin: 0 auto;
    }
    &-step{
        &-info{
            padding: 0 0 55px;
        }
        &-selections{
            height: 280px;
            padding-bottom: 60px;
            .swiper-slide{
                width: 195px;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    &-footer{
        border-top: 1px solid $c-assistant-border;
        padding: 30px 20px;
        flex: 1;
    }
    &-button{
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        .link-inline{
            margin-left: 0;
        }
    }
    &-selecteds{
        bottom: -30px;
    }
}

#assistant.asr-cover{
    .bnr{
        &-body{
            padding: 90px 60px 40px;
        }
        &-media{
            top: -75%;
            width: 340px !important;
            left: calc(50% - 170px) !important;
        }
    }
}

// Call Me Back Form
.cmb{
    &-inner{
        padding: 40px 40px 30px;
    }
    &-close{
        right: 50px;
    }
    &-title{
        @include font-set(h-large, true);
    }
    &-form{
        .form-col-3-1{
            padding-right: 10px;
        }
        .form-col-3-2{
            padding-right: 10px;
            padding-left: 10px;
        }
        .form-col-3-3{
            padding-left: 10px;
        }
    }
}

// Meeting Starter Box
.msb{
    &-media{
        width: 172px;
    }
    &-body{
        padding: 0 0 0 15px;
        display: flex;
        align-items: center;
    }
    &-button{
        margin-top: 0;
    }
    &-text{
        padding-right: 50px;
    }
    .btn-msb-close{
        top: 12px;
        right: 12px;
    }
}

// Coupon Counter
.ccm{
    &-title{
        @include font-set(h-large);
    }
    &-timer{
        flex: none;
        margin-right: 60px;
        .subt{
            @include font-set(body-large);
        }
        .time{
            @include font-set(h-large);
            .digit{
                width: 45px;
            }
        }
    }
    &-code{
        flex: none;
    }
}

// PDP Recipies
.apr{
    padding-right: 30px;
    &-body{
        padding: 25px 30px;
    }
    &-title{
        margin-bottom: 5px;
    }
    &-cta{
        margin-top: auto;
    }
    &-media{
        flex: 0 0 213px;
    }
}