// Login & Signup
.signing{
    display: flex;
}
.sgn-holder{
    width: 635px;
    position: relative;
    .signup{
        height: 100%;
    }
}
.sgn-media{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    min-height: 750px;
    flex: 1;
}

// Login
.login{
    padding: 80px 0;

    h1{
        @include font-set(h-large, true);
        margin-bottom: 40px;
    }
}

// Signup
.signup{
    padding: 80px 0;

    .prg{
        &-header{
            position: absolute;
            z-index: $z-lower;
            background-color: $c-bg-light;
        }
        &-title, &-inner::after, &-inner::before{
            display: none;
        }
        &-back{
            button span{
                display: inline;
            }
        }
        &-inner{
            padding: 30px 50px;
        }
    }
}

// Guest
.guest{
    padding: 80px 0;
    .prg{
        &-header{
            position: absolute;
            z-index: $z-lower;
            background-color: $c-bg-light;
        }
        &-title, &-inner::before{
            display: none;
        }
        &-back{
            button span{
                display: inline;
            }
        }
        &-inner{
            padding: 30px 50px;
        }
    }
}

// Social OTP
.social-otp{
    padding: 80px 0;
    .prg{
        &-header{
            position: absolute;
            z-index: $z-lower;
            background-color: $c-bg-light;
        }
        &-title, &-inner::before{
            display: none;
        }
        &-back{
            button span{
                display: inline;
            }
        }
        &-inner{
            padding: 30px 50px;
        }
    }
}

// User Pages
.page-user {
    .header-top {
        .col-left-alt {
            display: none;
        }
        .col-left,
        .col-center {
            display: flex;
        }
    }
}

.user-page-header {
    position: static;
    height: auto;
    padding: 25px 0;
    .t {
        @include font-set(h, true);
    }
}

.user-page-bg{
    background-color: $c-bg-colored;
    .user-page-header{
        background-color: transparent;
    }
}

.p-user {
    display: flex;
}

// Grid Configs
[class*="-grid"] {
    .pl-lg-20 {
        padding-left: 20px;
    }
    .mt-lg-0 {
        margin-top: 0;
    }
    .col-lg-right {
        text-align: right;
    }
}

// Orders
.ord-grid.lp {
    margin-left: 0;
    margin-right: 0;
    .ord{
        &-preorder{
            align-items: center;
            margin-top: 30px;
        }
        &-extra-warranty{
            .ord-bar{
                margin-top: 0;
            }
        }
    }
}

// Order Detail
.ord-grid.dp {
    .ord {
        &-payment-type, &-block1, &-status, &-email {
            margin-top: 0;
        }
        &-addresses {
            display: flex;
            justify-content: space-between;
            padding: 20px 30px;
            .item {
                flex: 1;
                border: none;
                .p {
                    padding-right: 60px;
                }
            }
        }
        &-chk-grid {
            margin: 0;
            .item {
                &:first-child {
                    border-color: transparent;
                }
            }
        }
        &-purchase {
            margin: 30px 30px 0;
        }
        &-docs {
            margin: 30px 30px 0;
            display: flex;
            .item {
                margin-right: 30px;
            }
        }
    }
}

// Notifications
.ntf-grid {
    #product-swiper {
        margin: 0;
        > .swiper-wrapper {
            > .swiper-slide {
                width: 285px;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

// Requests
.req-grid.lp {
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
    > .row {
        align-items: center;
    }
    .req {
        &-block1 {
            margin: 0;
            padding: 0;
            border-top: none;
        }
        &-service {
            margin-top: 0;
        }
    }
}

// Request Detail
.req-grid.dp {
    .req {
        &-map-embed {
            margin-left: 0;
            margin-right: 0;
        }
        &-title {
            margin-top: 0;
        }
    }
}

// Favorites
.fav {
    &-grid {
        margin-left: 0;
        margin-right: 0;
        padding: 5px;
        .row {
            align-items: center;
        }
    }
    &-block1 {
        margin-top: 0;
        max-width: none;
        justify-content: flex-end;
    }
    &-price {
        &-block1{
            flex-direction: row;
            .prc-first {
                margin-right: 10px;
            }
        }
    }
    &-add-to-cart {
        margin-left: 30px;
    }
    &-remove {
        position: static;
        text-align: center;
    }
}

// Coupons
.cpn {
    &-grid {
        margin-left: 0;
        margin-right: 0;
    }
}

// Profile Informations
.inf {
    &-grid {
        margin-top: 30px;
    }
}

.usr-profile-informations {
    .delete-account{
        background-color: transparent;
        padding: 0;
        margin: 40px 0 0;
        text-align: left;
    }
}

// Forgot Password
.page-forgot-password{
    background-color: $c-bg-body;
    .spt-header {
        background: none;
        margin-top: 110px;
    }
    .spt-hdr-title{
        @include font-set(h, true);
    }
}

#forgot-password{
    padding: 0 0 40px;
    .form{
        &-button{
            padding-top: 20px;
        }
    }
}

.fpr{
    &-email{
        margin-bottom: 20px;
        font-weight: $fw-bold;
        text-align: center;
    }
    &-info{
        text-align: center;
    }
    &-button{
        text-align: center;
        margin-top: 45px; 
    }
    &-desc{
        margin-bottom: 50px;
        text-align: center;
    }
}