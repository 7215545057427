// Product
.prd {
    &-media {
        .swiper-slide{
            background-color: $c-bg-body;
        }
    }
}

// PDP Quick View
.pdp-qview{
    margin: -20px -50px 0;
    &-header{
        .pdp-name{
            text-align: center;
        }
    }
    &-body{
        padding: 45px;
        .pdp{
            &-dimensions{
                margin-top: 50px;
            }
        }
    }
}