@mixin button-appshell {
    button{
        padding: 0;
        background: none;
        border: none;
        color: inherit;
        text-align: left;
    
        &:focus{
            outline: none;
        }
    }
}

@mixin d-elements-appshell {
    // desktop elements
    .d {
        display: none;
    }
}

@mixin qty-badge-appshell {
    .qty-badge {
        background: $c-primary;
        color: $c-text-light;
        border-radius: 50%;
        width: 22px;
        line-height: 22px;
        font-size: 10px;
        text-align: center;
        &[data-qty="0"]{
            display: none;
        }
    }
}

@mixin header-appshell {
    // top header
    .header-top {
        position: fixed;
        top: 0;
        left: 0;
        z-index: $z-high;
        width: 100%;
        background: $header-top-bg;
        color: $header-top-color;
        transition: all .5s ease;
        > .container {
            max-width: calc(100% - #{$header-top-padding-x} * 2);
            > .row {
                border-bottom: $header-top-border-bottom;
                align-items: center;
                height: $header-height-mobile;
                > * {
                    display: flex;
                    align-items: center;
                }
            }
            .col-left-alt {
                display: none;
            }
            .col-center {
                justify-content: center;
            }
            .col-right {
                justify-content: flex-end;
            }
        }
    }
    
    .btn-notification {
        position: relative;
        margin-left: 10px;
        .qty-badge {
            position: absolute;
            top: 0;
            right: -6px;
            width: 16px;
            height: 16px;
            line-height: 16px;
        }
    }

    .btn-cart{
        position: relative;
        .qty-badge {
            position: absolute;
            top: 0;
            right: -6px;
            width: 16px;
            height: 16px;
            line-height: 16px;
        }
    }
    
    .logo-alt {
        display: none;
    }
    
    .btn-cart {
        margin-left: 10px;
    }

    .hum, .hsm{
        position: relative;
        &-body{
            display: none;
        }
    }
}


// DESKTOP APPSHELL MIXINS
@mixin m-elements-appshell-desktop {
    // mobile elements
    .m {
        display: none !important;
    }
}

@mixin header-appshell-desktop {
    // top header
    .header-top {
        > .container {
            max-width: calc(100% - 20px * 2);
            > .row {
                height: $header-height-desktop;
                padding: 0 20px;
                > * {
                    > .d {
                        display: flex;
                        align-items: center;
                        > * {
                            margin-right: $d-header-link-space;
                            white-space: nowrap;
                        }
                        .f-input{
                            margin-right: 0;
                        }
                    }
                } 
            }
            .f-input{
                background-color: $c-header-input-bg;
                height: 36px;
                border-radius: 20px;
                .icon-holder{
                    .icon-close{
                        display: none;
                    }
                }
            }
        }
    }

    .btn-search.d{
        width: 100%;
        max-width: 170px;
        margin-right: $d-header-link-space;
        @include transitions($func: eut);
    }

    .btn-menu {
        margin-right: $d-header-item-space;
        > .d {
            display: flex;
            align-items: center;
        }
    }

    .btn-notification {
        margin-left: 0;
    }

    // .btn-cart {
    //     margin-left: $d-header-item-space;
    // }
}