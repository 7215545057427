// Service Item
.srv {
    &-item {
        border-left: 1px solid $c-grey-border;
        border-right: 1px solid $c-grey-border;
        &.selected {
            box-shadow: 5px 0 $c-primary inset;
        }
    }
}

// Support
.page-support {
    margin-bottom: 0;
}

.page-support-main {
    .page-title {
        text-align: center;
    }
    .category-list {
        margin-top: 25px;
        li {
            margin: 15px 25px;
        }
    }
    #site-main {
        padding-bottom: 80px;
    }
}

.spt-search {
    position: relative;
    max-width: 720px;
    margin: 10px auto 0;
    padding: 0;
    &-holder{
        &.spt-search-mdl{
            background-color: $c-bg-colored;
            padding: 60px 0 80px;
            margin-top: 100px;
            .spt-search-title{
                display: block;
                @include font-set(h);
                text-align: center;
                margin-bottom: 20px;
            }
        }
    }
    &-input{
        input{
            max-width: none;
        }
    }

    .btn-spt-back {
        display: none !important;
    }

    &-suggest {
        position: absolute;
        width: 100%;
        border-radius: 0 0 5px 5px;
        background: $c-bg-body;
        height: auto;
        max-height: 400px;

        @at-root {
            .spt-sug-row {
                &-empty {
                    display: flex;
                    padding: 30px 10px;
                    > div {
                        flex: 1;
                        + div {
                            margin-top: 0;
                        }
                    }
                    .col-left {
                        padding-right: 30px;
                    }
                    .md {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    &-ready {
        overflow: auto;
        .spt-search {
            height: auto;
            &-input {
                border-radius: 5px 5px 0 0;
                input {
                    padding: 0 20px;
                }
            }
        }
    }
}

.page-support-article-detail .spt-bnf-slider{
    margin-bottom: -100px;
}

.spt-bnf-list {
    display: flex;
}
.spt-bnf {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px 30px 40px;
    .icon {
        position: static;
    }
    .t {
        margin-top: 40px;
    }
    .b {
        margin-top: auto;
        padding-top: 50px;
    }

    + .spt-bnf {
        margin-left: 10px;
        border-top: none;
    }

    &.m-view {
        display: block;
        text-align: left;
        padding: 30px 20px 20px 45px;
        .icon {
            position: absolute;
        }
        .t {
            margin-top: 0;
        }
    }
}

.spt-bnf-list2 {
    margin-top: 30px;
    display: flex;
    .spt-bnf {
        flex: none;
        width: 375px;
    }
    .spt-map-embed {
        flex: 1;
        margin-top: 0;
    }
}

// Support Article Pages
.spt{
    &-header{
        .breadcrumb{
            display: flex;
            position: absolute;
        }
    }
    &-hdr{
        &-inner{
            height: 260px;
            width: 720px;
            margin: 0 auto;
        }
        &-media{
            width: 400px;
            right: -250px;
        }
        &-content{
            height: 100%;
            justify-content: center;
            padding: 0;
            .btn-spt-back{
                display: none;
            }
        }
        &-title{
            @include font-set(h-large, true);
        }
    }
    &-article{
        &-list{
            padding: 25px 0;
        }
        &-header{
            height: auto;
            padding: 0;
            .btn-spt-back{
                display: none;
            }
            .breadcrumb{
                display: flex;
            }
        }
        &-title{
            @include font-set(h-large, true);
            margin: 45px 0 40px;
        }
        &-tags{
            padding: 0;
        }
        &-feedback{
            padding: 0;
        }
        &-categories{
            text-align: center;
            .nav{
                display: inline-flex;
                width: auto;
                min-width: 720px;
            }
        }
    }
    &-video{
        &-info{
            text-align: center;
        }
    }
    &-bnf{
        &-title{
            @include font-set(h);
        }
        &-slider{
            padding: 40px 0 70px;
            margin-top: 60px;
            .swiper-slide{
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
            .swiper-pagination{
                display: none !important;
            }
            .swiper-wrapper{
                justify-content: center;
            }
        }
    }
}

// Buying Guide
.page-support-buying-guide {
    .spt-header {
        background: $c-bg-colored;
    }
    .spt-hdr-inner {
        height: 260px;
    }
    .spt-hdr-content {
        padding: 0;
    }
}
.spt-guide-list {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 25px 0;
    .bnr {
        &-info {
            @include font-set(body-text, true);
        }
    }
}

// Services
.page-services {
    #site-main {
        .col-left {
            padding: 50px;
        }
        .col-right {
            align-self: flex-start;
            position: sticky;
            top: $header-height-desktop;
        }
    }

    .page-title {
        text-align: center;
        padding: 0;
        h1 {
            @include font-set(h, true);
        }
    }

    .srv-list {
        margin-top: 50px;
    }

    &.map-view-activeted {
        .map-view-cover,
        .map-view-header,
        .map-view-content,
        .store-gallery {
            display: none;
        }
        
        .map-view-container {
            position: relative;
            z-index: auto;
            #Map {
                height: calc(100vh - #{$header-height-desktop});
            }
        }
    }

    .nav-tabs {
        margin-top: 30px;
    }
    .tab-content {
        padding: 0;
        .srv-list {
            margin-top: 0;
        }
    }
}

.find-service {
    padding: 0;
    margin-top: 30px;
    @at-root {
        .btn-srv-search {
            width: auto;
        }
        .btn-loc-search {
            width: auto;
            margin-left: 45px;
        }
    }
    .spt-bnf {
        margin-top: 50px;
    }
}

.store-gallery {
    picture {
        max-height: calc(100vh - #{$header-height-desktop});
        img {
            width: 100%;
        }
    }
}

.srv-information {
    padding: 30px 20px;
}

.map-view-cover {
    display: block;
}

// Contact Us
.contact{
    padding: 0 20px;
    h1.ctc-title{
        margin: 5px 0 85px;
        @include font-set(h-large, true);
    }
}

.ctc{
    &-inner{
        display: grid;
        grid-template-columns: repeat(2, minmax(400px, 1fr));
        column-gap: 120px;
        grid-template-rows: max-content 1fr;
        grid-template-areas: "ga1 ga2" "ga3 ga2";
    }
    &-support{
        grid-area: ga1;
        text-align: left;
        padding: 40px;
        .ctc-title{
            text-align: left;
        }
        p{
            margin-bottom: 40px;
        }
    }
    &-form{
        grid-area: ga2;
        padding: 0;
        margin: 0;
        &-inner{
            > p{
                text-align: left;
            }
        }
        .ctc-title{
            text-align: left;
        }
    }
    
    &-info{
        grid-area: ga3;
        padding: 0 40px;
    }
}

// Service Appointment & Support Form
.page-support-service-app, .page-support-service-request{
    .spt-header{
        margin: 50px 0 20px;
    }
}

#app{
    &-wizard{
        background-color: transparent;
        .nav-link{
            flex: 1;
        }
    }
    &-result{
        background-color: transparent;
        padding: 50px 0;
        .form-result-title{
            @include font-set(h-large, true);
        }
    }
}

.app{
    &-steps{
        padding: 30px 0;
    }
}

.time-picker{
    .swiper-button-next, .swiper-button-prev{
        display: inline-block;
        z-index: $z-normal;
        left: 0;
    }
    .swiper-button-next{
        left: auto;
        right: 0;
    }
    .swiper-slide{
        margin-left: 50px;
    }
    .swiper-container{
        &::before, &::after{
            content: "";
            display: block;
            height: 100%;
            width: 30px;
            position: absolute;
            top: 0;
            background-color: $c-white;
            z-index: $z-low;
            left: 0;
        }
        &::after{
            left: auto;
            right: 0;
        }
    }
}

.app-form-completed{
    .spt-header{
        display: none;
    }
}

// TV Software Finder
.page-support-software-finder, .page-support-software-manual-finder{
    .spt{
        &-header{
            background-color: $c-bg-colored;
        }
        &-hdr-inner {
            height: 150px;
        }
    }
}

.tsf{
    margin: 50px 0;
    &-content{
        display: flex;
    }
    &-label{
        width: 50%;
        margin-bottom: 0;
    }
    &-form{
        width: 50%;
        padding-left: 30px;
        .submit{
            text-align: right;
        }
    }
}

// Software & Guide Finder
.sgf{
    margin: 55px 0 260px;
    &-desc{
        @include font-set(body-medium);
    }
    &-tabs{
        .nav-tabs{
            margin: 25px 0 0;
            width: 100%;
        }
    }
    &-info{
        &-media{
            margin-top: 20px;
            padding: 20px 45px;
        }
    }
    &-result{
        margin-top: 25px;
    }
    &-downloads{
        padding: 20px 35px;
    }
    &-search{
        .spt-search{
            border-radius: 5px;
            &-suggest{
                padding-bottom: 0;
            }
        }
    }
}

.spt-search-ready{
    .sgf-search{
        .spt-search{
            &-input{
                background-color: $c-bg-light;
                border-radius: 5px;
            }
            &.searching{
                .spt-search-input{
                    border-radius: 5px 5px 0 0;
                }
            }
        }
    }
}