// Product
$prd-padding-x: 20px;
$prd-padding-x-small: 10px;
$prd-padding-y: 10px;

.prd {
    &-inner {
        position: relative;
        overflow: hidden;
        background: $c-bg-body;
        border: $border-product-card;
        border-radius: $br-product-card;
        @include transition();
    }
    &-block1,
    &-block2,
    &-block3,
    &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-block1 {
        padding: $prd-padding-y $prd-padding-x-small 0;
    }
    &-media{
        .swiper-button-prev, .swiper-button-next{
            display: none;
        }
    }
    &-compare {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;

        input {
            @extend .svg;
            @extend .svg-compare;
            appearance: none;
            cursor: pointer;
            border: none;
            background-color: transparent;
            &:not(:checked) + label {
                .t1 {
                    display: none;
                }
            }
            &:checked + label {
                .t0 {
                    display: none;
                }
            } 
        }
        label {
            @include font-set(body-small);
            margin: 0;
            padding: 0 0 0 5px;
            cursor: pointer;
        }
    }
    &-more {
        display: flex;
        margin-left: auto;
        
        @at-root {
            .btn-more {
                @extend .svg;
                @extend .svg-more;
            }
        }
    }
    &-energy-label {
        position: relative;
        z-index: $z-lower;
        .energy-label{
            position: absolute;
            left: $prd-padding-x;
            top: calc(50% - 25px);
        }
    }
    &-media {
        .responsive {
            max-width: 265px;
            margin: 0 auto;
        }
    }
    &-body {
        padding: 10px $prd-padding-x 0;
        display: flex;
        flex-direction: column;
        height: 290px;
    }
    &-name {
        @include line-clamp(2);
        height: 51px;
        @include font-set(h);
    }
    &-block2 {
        margin-top: 10px;
    }
    &-doc {
        @include font-set(body-small);
    }
    &-features {
        margin-top: 10px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        ul {
            margin: 0;
            padding-left: 15px;
        }
    }
    &-block3 {
        $h: 80px;
        margin: auto (-$prd-padding-x-small) 0;
        border-top: 1px solid $c-grey-lighter;
        padding: 0 $prd-padding-x-small;
        height: $h;
        min-height: $h;
    }
    &-price {
        flex: none;
        > span {
            display: block;
        }
        .prc {
            &-first {
                color: $c-grey-dark;
            }
            &-last {
                @include font-set(h-paragraph);
            }
        }
    }
    &-badge1 {
        flex: 0 0 55%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        > .responsive {
            flex: none;
            margin-right: 5px;
            max-width: 32px;
        }
        > span {
            @include font-set(body-small);
        }
    }
    &-badge2{
        .prc-third{
            @include font-set(h-paragraph);
            color: $c-primary;
            &-info{
                padding-left: 4px;
                @include font-set(body-small);
            }
        }
    }
    &-footer {
        position: relative;
        margin-top: auto;
        padding: 8px #{$prd-padding-x};
        background: $c-bg-prd-footer;
        &::before {
            $size: 5px;
            content: "";
            position: absolute;
            left: 30px;
            top: -$size;
            border-width: 0 $size $size;
            border-style: solid;
            border-color: transparent transparent $c-bg-prd-footer transparent;
        }
    }
    &-full-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .swiper-pagination-inner{
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0;
        z-index: $z-lower;
        width: 100%;
    }

    // Row View
    &-row {
        &-inner {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 10px 0;
            margin: 0 20px;
        }
        &-media {
            width: 80px;
        }
        &-name {
            font-weight: $fw-bold;
        }
        &-price{
            margin-top: 5px;
            .prc-first{
                color: $c-grey-dark;
                margin-right: 10px;
            }
            .prc-last{
                font-weight: $fw-bold;
            }
        }
        &-category {
            margin-top: 2px;
            color: rgba($color: $c-text-dark, $alpha: .7);
        }
        &-block1 {
            flex: 1;
            padding: 0 15px 0 10px;
        }
    }

    // Mini View
    &-mini {
        &-inner {
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            background: $c-bg-prd-mini;
            padding: 20px;
        }
        &-name {
            display: none;
            @include font-set(h-xtiny);
            height: #{(map-get(map-get($font-sets, h-xtiny), line-height) * 2) + 'px'};
            overflow: hidden;
        }
        &-warranty {
            display: none;
            margin-top: 5px;
            @include font-set(body-small);
            &::before {
                margin-right: 6px;
            }
        }
        &-price {
            margin-top: 5px;
            text-align: center;
            @include font-set(body-small);
            height: #{(map-get(map-get($font-sets, body-small), line-height) * 4) + 'px'};
            > span {
                display: block;
            }
            .prc {
                &-first {
                    margin-bottom: 3px;
                    color: $c-grey-dark;
                }
                &-last {
                    font-weight: $fw-bold;
                }
            }
            &-third{
                margin-top: 3px;
                .prc-third{
                    color: $c-primary;
                    font-weight: $fw-bold;
                    &-info{
                        padding-left: 5px;
                        @include font-set(body-xsmall);
                    }
                }
            }
        }
        &-detail {
            margin-top: 10px;
            text-align: center;
        }

        &.prd-mini-promotion, &.prd-mini-cart{
            .prd-mini{
                &-inner{
                    padding: 10px;
                    height: 320px;
                    display: flex;
                    flex-direction: column;
                }
                &-name{
                    display: block;
                }
                &-price{
                    text-align: left;
                }
                &-detail{
                   padding: 10px 0 5px;
                   border-top: 1px solid $c-grey-lighter;
                   margin: auto -5px 0;
                   display: flex;
                   align-items: center;
    
                   &-left, &-right{
                       flex: 1;
                       @include font-set(body-small);
                   }
    
                   .btn{
                       padding: 0;
                       width: 70px;
    
                       &-primary{
                           display: none;
                       }
                   }
                }
            }
    
            &.selected{
                .prd-mini{
                    &-inner{
                        box-shadow: 0 0 0px 1px $c-primary;
                    }
                    &-detail{
                        .btn-selection{
                            background-color: $c-primary;
                            border-color: $c-primary;
                            color: $c-white;

                            &::before, &::after{
                                content: attr(data-sel-title);
                            }
                        }
                    }
                }
            }
        }

        &.prd-mini-user {
            .prd-mini {
                &-inner {
                    display: flex;
                    flex-direction: column;
                    height: 310px;
                    padding: 10px;
                }
                &-name,
                &-warranty {
                    display: block;
                }
                &-price {
                    display: none;
                }
                &-detail {
                    padding: 10px 5px 0;
                    border-top: 1px solid $c-grey-lighter;
                    margin: auto -5px 0;
                    text-align: left;
                }
            }
        }
    }

    @each $k, $v in $c-product-card{
        &-bg-#{$k} &-inner{
            background-color: $v;
            border-color: $v;
        }
    }
}

// Reviews
[class*="-reviews"] {
    display: flex;
    align-items: center;
    
    .rating{
        display: inline-block;
        width: 68px;
        @extend .svg;
        @extend .svg-stars-empty;

        &::before{
            content: "";
            display: block;
            height: 100%;
            width: 0;
            @extend .svg;
            @extend .svg-stars-full;
        }

        @for $i from 1 through 10{
            &[data-rating="#{$i / 2}"]::before{
                width: 20% * ($i / 2);
            }
        }
    }

    .qty {
        padding: 5px 0 0 10px;
    }
    &.reviews-alt {
        .rating {
            @extend .svg-stars-alt-empty;
            &::before {
                @extend .svg-stars-alt-full;
            }
        }
    }
}

// Actions
.compare-activated{
    .prd{
        &-compare {
            input {
                @extend .svg-check;
                &:checked {
                    @extend .svg-check-full;
                }
            }
        }

        &.selected {
            .prd {
                &-inner {
                    box-shadow: 0 0 0px 2px $c-primary;
                }
            }
        }
    }
}

// PDP Quick View
.pdp-qview{
    margin: -20px -20px 0;
    &-header{
        padding: 25px 0;
        background-color: $c-bg-light;
        .pdp{
            &-name{
                padding: 0 40px;
            }
            &-energy-label{
                margin-right: 0;
            }
            &-reviews{
                justify-content: center;
            }
            &-gallery{
                position: static;
                margin: 0;
                padding: 0;
                .swiper-button-next, .swiper-button-prev{
                    display: none;
                }
            }
        }
    }
    &-body{
        padding: 35px 20px 50px;
        .pdp{
            &-features{
                margin-top: 0;
                padding-bottom: 0;
            }
            &-dimensions{
                margin-top: 50px;
            }
        }
    }
    &-button{
        text-align: center;
        margin-top: 25px;
    }
}