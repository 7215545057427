#site-footer {
    margin-top: 100px;
}

// mobile elements
.footer-menu {
    display: none !important;
}

// top footer
.footer-top {
    padding: 100px 0 120px;
    .col-left {
        display: block;
    }
    .col-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-bottom: 40px;
    }
}

.customer-support {
    text-align: right;
    margin-top: auto;
}

.social-links {
    margin-top: 0;
}

// middle footer
.footer-middle {
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;
}

// bottom footer
.footer-bottom {
    display: flex;
    align-items: center;
}
.copyright {
    margin-top: 0;
    order: -1;
    margin-right: auto;
}