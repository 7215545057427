@include m-elements-appshell-desktop;

// Buttons
.btn {
    &::after{
        transform: translateY(100%);
        opacity: 0;
        display: inline-block;
    }
    
    &:hover{
        &::after{
            transform: translateY(0);
            opacity: 1;
        }

        &::before{
            transform: translateY(-100%);
            opacity: 0;
        }
    }
    &-primary {
        &:hover {
            background-color: darken($c-primary, 5%);
            border-color: darken($c-primary, 5%);
            color: $c-text-light;
        }
    }

    &-secondary {
        &:hover {
            background-color: darken($c-secondary, 5%);
            border-color: darken($c-secondary, 5%);
            color: $c-text-light;
        }
    }

    &-light {
        &:hover {
            background-color: darken($c-white, 5%);
            border-color: darken($c-white, 5%);
            color: $c-text-dark;
        }
    }

    // Outline Buttons
    &-outline {
        &-primary {
            &:hover {
                background-color: darken($c-dark, 5%);
                border-color: darken($c-dark, 5%);
                color: $c-text-light;
            }
        }

        &-secondary {
            &:hover {
                background-color: darken($c-white, 5%);
                border-color: darken($c-white, 5%);
                color: $c-text-dark;
            }
        }
    
        &-light {
            &:hover {
                background-color: $c-white;
                color: $c-black;
            }
        }
    
        &-dark {
            &:hover {
                background-color: $c-dark;
                color: $c-text-light;
            }
            &.btn-selection:hover{
                background-color: $c-primary;
                border-color: $c-primary;
                color: $c-text-light;
            }
        }
    }
   
    // page top
    &-page-top {
        display: block;
    }
}

.link{
    &:hover{
        // &::after{
        //     opacity: 1;
        // }
        text-decoration: underline;
    }

    &-inline{
        &:hover{
            // &::after{
            //     opacity: 0;
            // }
            text-decoration: none;
        }
    }
}


// Mobile Scrollable Desktop Fix
.scrollable-h{
    white-space: normal;
    overflow-x: visible;
    -webkit-overflow-scrolling:unset;
    -ms-overflow-style: unset;

    &::-webkit-scrollbar{
        display: block;
    }
}

// Page Cover
.cover{
    &-media{
        .responsive{
            width: 100%;
            height: auto;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
    &-body{
        margin: unset;
        align-self: center;
        padding-bottom: 0;
        h1{
            max-width: none;
        }
    }
}

.page-cover{
    &.static{
        .responsive{
            width: 100%;
            height: auto;
        }
    }
    &.cover-left, &.cover-right{
        .cover-media .responsive{
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
        }
    }
}


// Tab Content
.tab-content{
    padding: 30px 0;
}
.tab-simple{
    .tab-content{
        padding: 0;
        .tab-pane{
            padding: 30px 30px 20px;
        }
    }
}

// Nav Tabs Fix
.nav {
    &-tabs {
        &.tab-alt-mob {
            box-shadow: inset 0px -3px 0px 0px $c-grey-light;
            .nav-link {
                border-width: 0 0 3px 0;
                border-style: solid;
                border-color: transparent;
                background-color: transparent;
                color: $body-color;
                &.active {
                    border-bottom-color: $c-primary;
                }
            }
        }
        &.tab-ext{
            margin-bottom: -30px;
        }
    }
    &-pills{
        flex-wrap: wrap;
    }
}

.tab-ext-colored{
    .tab-content{
        padding: 0;
    }
    .nav-tabs.tab-ext{
        padding: 0;
        margin-bottom: 0;
    }
}

.seo-text{
    margin: 100px auto;
}

// breadcrumb
.breadcrumb{
    padding-left: 40px;
}

// Container No Padding
.no-pad{
    padding-left: $container-padding-x;
    padding-right: $container-padding-x;
}

// Section Title
.section-title{
    @include font-set(h-large, true);
    text-align: center;
    margin: 60px auto 20px;
}

.max-container{
    margin: 0 auto;
    max-width: 1600px;
    padding: 0 40px !important;
}

// Mini Popup
.mini-popup{
    border-radius: 0 !important;
    max-width: 500px;
    height: 100%;
    left: auto;
    right: 0;
    bottom: auto;
    top: 0;
    border-radius: $br-popup-bottom;
    transform: translateX(100%) translateY(0);

    &.activated{
        transform: translateX(0);
    }
}

.mnp{
    &-header{
        margin: 0 35px;
    }
    &-menu {
        padding: 50px 50px 0;
        [class*="menu-btn-"]{
            @include transitions();
            
            &:hover{
                background-color: $c-grey-lighter;
            }
        }
        .d{
            display: list-item;
        }
    }
    &-content{
        padding: 20px 50px 0;
        height: 100%;
        max-height: none;
    }
}

// 404
.pnf{
    margin: 180px 0;
    &-block1{
        margin-bottom: 95px;
        padding: 0;
    }
    &-sign{
        margin-right: 60px;
        .icon{
            font-size: 152px;
        }
    }
    &-title{
        b{
            font-size: 100px;
        }
        span{
            font-size: 48px;
        }
    }
    &-block2{
        p{
            padding: 0;
            @include font-set(body-xlarge);
        }
    }
    &-buttons{
        .btn{
            padding: 0 44px;
        }
    }
}

// Dark Mode Control
$dm-notifier-height: 135px;
.drk{
    &-notify{
        justify-content: center;
        height: $dm-notifier-height;
    }
    &-switch{
        text-align: center;
    }
    &-control{
        padding: 40px;
        &-desc{
            margin-top: 20px;
        }
    }
    &-notifier-opened{
        &:not(.header-opaque){
            #site-header{
                height: $dm-notifier-height;
            }
            .header-top{
                top: $dm-notifier-height;
            }
        }
    }
}