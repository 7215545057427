// APP SHELL FIX
body{
    overflow: auto;
}
#ash-plc{
    display: none !important;
}

::selection {
  background: $c-primary;
  color: $c-text-light;
  text-shadow:none;
}

html {
    scroll-behavior: smooth;
    background-color: $c-bg-body;
}

img {
    max-width: 100%;
}

picture{
    text-align: center;
    display: inline-block;
    width: 100%;

    img{
        width: auto;
        max-width: 100%;
    }
}

a {
    cursor: pointer;
    outline: none;
    text-decoration: none;
}

p{
    a{
        text-decoration: underline;
    }
}

// Copy to Clipboard
#clipboard{
    position: absolute;
    left: -9999px;
}

[data-clipboard]{
    .copied-text{
        display: none;
        animation: show .111s linear;
    }
    &.copied{
        .copied-text{
            display: inline;
        }
    }
}

input{
    outline: none;

    &[type=number]{
        -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
}

textarea{
    resize: none;
}

.clearfix{
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}

figure{
    margin:0;
}

@include button-appshell;

// CONTAINERS
[class*="container-max-"]{
    margin:0 auto;
    padding: 0 $container-padding-x;
}

.container-max-{
    @each $c in $containers{
        &#{$c}{
            max-width: #{$c + ($container-padding-x * 2)};
        }
    }
}

.ul-clear{
    padding: 0;
    margin: 0;
    list-style: none;
}

.ul-styled{
    padding-left: 20px;
    margin: 0;
    list-style: disc;
}

.f-input {
    $p: 10px;
    flex: 1;
    display: flex;
    align-items: center;
    background: #e5e5e5;
    border-radius: 5px;
    padding: 10px;

    .icon-holder:not(:last-child) {
        margin-right: 10px;
    }

    > span {
        color: rgba($color: $c-black, $alpha: .5);
    }

    form {
        flex: 1;
        align-self: stretch;
        display: flex;
        align-items: center;
    }
    input {
        flex: 1;
        background: inherit;
        border: none;
        padding: 0;
        line-height: 32px;
        &::placeholder {
            color: rgba($color: $c-black, $alpha: .5);
        }
    }
}

@include qty-badge-appshell;

.lazy{
    opacity: 0;
    @include transitions($prop: opacity, $dur: "now");

    &.loaded, &.error, &.bg-load{
        opacity: 1;
    }
}

.responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    &-item, img, video{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
    // 21:9
    &-21-9{
        &::before {
            padding-top: percentage(21 / 9);
        }
    }
    // 16:9
    &-16-9{
        &::before {
            padding-top: percentage(16 / 9);
        }
    }
    // 4:3
    &-4-3{
        &::before {
            padding-top: percentage(4 / 3);
        }
    }
}

#overlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: $z-normal;
    width: 100%;
    height: 100%;
    background: rgba($color: $c-black, $alpha: .5);
    animation: show .2s ease;
}

// Energy Color Generator
.energy-label{
    // line-height: $energy-label-height;
    // font-style: normal;
    // text-align: center;
    display: inline-block;
    font-size: 0;
    width: 46px;
    height: 25px;
    // height: $energy-label-height;
    // width: 25px;
    // position: relative;
    // background-color: currentColor;
    // padding-left: 3px;
    // margin-right: 9px; // Fix for ::after
}

// a.energy{
//     @each $k, $v in $energy-colors{
//         &-#{$k}{
//             color: $v;
//         }
//     }
// }

// Preloader
%preloader-svg{
    align-items: center;
    justify-content: center;
    svg{
        > g{
            stroke-dasharray: 50 40;
            stroke-dashoffset: 50;
            stroke-linecap: square;
            fill: none;
            transform-origin: center;
            stroke-width: 5px;
            stroke: $c-primary;
            animation: preloader .6s linear infinite;
        }
    }
}

.preloader{
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-master;
    background-color: rgba($color: $c-white, $alpha: .4);
    display: none;
    height: 100vh;
    width: 100%;
    @extend %preloader-svg; 
}

.preloader-inline{
    display: flex;
    padding: 20px;
    @extend %preloader-svg;
}

.page-processing{
    .preloader{
        display: flex;
    }
}