// Login & Signup
.page-login-signup{
    #site-footer{
        margin-top: 0;
    }
}


.sgn{
    &-holder{
        background-color: $c-bg-light;
        .signup{
            display: none;
            height: calc(100vh - #{$header-height-mobile});
        }
        .form-result{
            display: none;
        }
        .social-gg{
            .icon{
                color: #ea4436;
            }
        }
    
        .social-fb{
            margin-top: 10px;
            .icon{
                color: #365189;
            }
        }
    }
    &-media{
        display: none;
    }
    
}

.signup-ready{
    .sgn-holder .signup{
        display: block;
    }
    .sgn-holder .login{
        display: none;
    }
    
    &.signup-complete{
        [class*="prg-step-"]{
            display: none;
        }
        .form-result{
            display: flex;
        }
    }
}

.guest-ready{
    .sgn-holder .guest{
        display: block;
    }
    .sgn-holder .login{
        display: none;
    }
}

.social-otp-ready{
    .sgn-holder .social-otp{
        display: block;
    }
    .sgn-holder .login{
        display: none;
    }
}



// Login
.login{
    width: 315px;
    margin: 0 auto;
    padding: 30px 0;

    h1{
        @include font-set(h);
        text-align: center;
        margin-bottom: 20px;
    }

    .btn{
        width: 100%;
    }

    form{
        margin-top: 40px;
    }

    .remember{
        display: flex;
        align-items: center;

        a{
            margin-left: auto;
        }
    }

    .submit{
        margin-bottom: 30px;
    }

    .signup-btn{
        text-align: center;
        .btn{
            max-width: 205px;
        }
    }
}

// Signup
.signup{
    width: 315px;
    margin: 0 auto;
    padding: 30px 0;

    .prg{
        &-info{
            margin-bottom: 30px;
        }
        &-steps{
            height: 100%;
        }
        &-back{
            button span{
                display: none;
            }
        }
    }

    .next{
        padding-top: 10px;
        text-align: right;
    }
    .confirm-code{
        display: flex;
        align-items: center;
        .btn{
            margin-left: auto;
        }
    }
    .confirm{
        padding-top: 10px;
        margin-bottom: 0;
    }

    .finish{
        margin-top: 20px;
        .btn{
            width: 100%;
        }
    }

    .password{
        .input-icon .icon{
            opacity: .5;
            cursor: pointer;
        }
        .show-pass{
            .icon{
                opacity: 1;
            }
        }
        .form-row-info{
            @include font-set(body-small);
        }
    }
}

// Guest
.guest{
    display: none;
    width: 315px;
    margin: 0 auto;
    padding: 30px 0;
    .prg{
        &-inner::after{
            display: none;
        }
        &-inner::before{
            border-color: $c-white;
        }
        &-back{
            button span{
                display: none;
            }
        }
        &-info{
            margin-bottom: 25px;
        }
    }
    .btn{
        width: 100%;
    }
    .form-rows{
        margin-top: 40px;
    }
    .finish{
        margin-top: 30px;
    }
}

// Social OTP
.social-otp{
    display: none;
    width: 315px;
    margin: 0 auto;
    padding: 30px 0;
    .prg{
        &-inner::after{
            display: none;
        }
        &-inner::before{
            border-color: $c-white;
        }
        &-back{
            button span{
                display: none;
            }
        }
        &-info{
            margin-bottom: 25px;
        }
    }
    .form-rows{
        margin-top: 40px;
    }
    .send-code{
        text-align: right;
    }
    .confirm-code{
        text-align: left;
    }
    .finish{
        .btn{
            width: 100%;
        }
    }
}

// User Pages
.page-user {
    .header-top {
        .col-left,
        .col-center {
            display: none;
        }
        .col-left-alt {
            display: flex;
            padding-right: 15px;
        }
    }
}

.m-user {
    display: flex;
    align-items: center;
    figure {
        flex: none;
        width: 30px;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid $c-primary;
    }
    &-name {
        @include font-set(h-xsmall);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    &-short{
        height: 30px;
        @include font-set(h-tiny);
        line-height: 30px;
        text-align: center;
    }
}

.user-page-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-higher;
    background: $c-bg-body;
    width: 100%;
    height: $header-height-mobile;
    padding: 0 15px;
    display: flex;
    align-items: center;
    .t {
        @include font-set(h-xsmall);
    }
}

.p-user {
    display: none;
    align-items: center;
    margin-top: 20px;
    figure {
        flex: none;
        width: 50px;
        margin-right: 20px;
        border-radius: 50%;
        border: 1px solid $c-primary;
    }
    &-name {
        @include font-set(h);
    }
    &-short{
        height: 50px;
        @include font-set(h);
        line-height: 50px;
        text-align: center;
    }
}

.user-page-menu {
    margin-top: 10px;
    .qty-badge {
        margin-left: 5px;
    }
}

.user-page-footer {
    padding: 50px 0;
    text-align: center;
    .b {
        margin-top: 15px;
    }
}

.user-page-empty-message {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $c-bg-prd-footer;
    padding: 20px 20px 30px;
    .t {
        margin-top: 10px;
        @include font-set(h-small);
    }
    .p {
        margin-top: 5px;
        text-align: center;
    }
    .b {
        margin-top: 20px;
    }
}

// Grid Configs
[class*="-grid"] {
    position: relative;
    .pl-10 {
        padding-left: 10px;
    }
    .pr-50 {
        padding-right: 50px;
    }
    .mt-10 {
        margin-top: 10px;
    }
    .mt-30 {
        margin-top: 30px;
    }
    .mt-40 {
        margin-top: 40px;
    }
    .col-right {
        text-align: right;
    }
}

// Grid Accordion
.grid-acc {
    margin: 15px (-$container-padding-x) 0;
    .acc {
        &-item {
            button {
                padding: 15px 45px 15px 10px;
                &::after {
                    @extend .svg-arrow-b;
                    @include transition();
                }
                
            }
        }
    }
    > li {
        padding: 0 10px;
        &.active {
            background: $c-bg-light;
            .acc {
                &-item {
                    button {
                        &::after {
                            @extend .svg-arrow-b;
                            transform: scale(-1);
                        }
                    }
                }
                &-body {
                    padding: 0;
                }
            }
        }
    }
}

// Purchased Products
.page-user-products {
    #site-footer {
        margin-top: 0;
    }
    .user-page-holder {
        background: $c-bg-colored;
    }
    .option-count {
        padding: 20px 0;
    }
}

.user-prds {
    margin: 0 (-$container-padding-x);
    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination {
        display: none;
    }
    .swiper-slide {
        width: 180px;
        + .swiper-slide {
            margin-left: 10px;
        }
        &:first-child {
            margin-left: 20px;
        }
        &:last-child {
            margin-right: 20px;
        }
    }
}

// Purchased Product Detail
.page-user-product-detail {
    #site-footer {
        margin-top: 0;
    }
}

.support-ticket {
    margin-top: 20px;
    padding: 30px 0;
    background: $c-bg-light;
    [class*="container-max-"] {
        position: relative;
        padding-left: 60px;
    }
    .icon {
        position: absolute;
        top: 0;
        left: 20px;
    }
    .t {
        @include font-set(h-small);
        margin-bottom: 5px;
    }
    .b {
        margin-top: 20px;
    }
}

// Orders & Order Detail
.ord {
    &-group {
        margin-top: 20px;
        &-title {
            @include font-set(h-xsmall);
        }
    }
    &-chk-grid {
        border-top: 1px solid $c-grey-border;
        > .row {
            padding: 20px 0 30px;
        }
    }
    &-name {
        @include font-set(h-xsmall);
    }
    &-flow{
        margin-top: 10px;
    }
    &-cancel{
        margin-top: 10px;
    }
    &-block2 {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .btn-ord-back {
            @include font-set(body-small);
            flex: none;
        }
        .btn-cargo-no {
            padding: 0 15px;
            margin-left: 20px;
        }            
    }
    &-bar {
        margin-top: 15px;
        display: flex;
        padding: 0 10px;
        > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            position: relative;
            @include font-set(body-xsmall);
            time {
                max-width: 35px;
                min-height: 25px;
                margin-bottom: 7px;
            }
            i {
                flex: none;
                width: 12px;
                height: 12px;
                border: 3px solid $c-grey-border;
                border-radius: 50%;
                background: $c-bg-body;
                z-index: $z-lower;
            }
            span {
                margin-top: 10px;
                min-height: 24px;
            }
            + div {
                &::before {
                    content: "";
                    border-top: 4px solid $c-grey-border;
                    position: absolute;
                    top: 36px;
                    left: calc(-100% + 6px);
                    width: 100%;
                }
            }
        }
        &.warranty{
            > div:first-child{
                flex: 1.5;
                &::before {
                    content: "";
                    border-top: 4px solid map-get($ord-bar-colors, warranty);
                    position: absolute;
                    top: 36px;
                    left: 6px;
                    width: 100%;
                }
            }
            > div:last-child{
                flex: .5;
                &::before {
                    display: none;
                }
            }
        }

        @each $k, $v in $ord-bar-colors {
            &.#{$k} {
                .completed {
                    i,
                    + .completed::before {
                        border-color: $v;
                    }
                }
            }
        }
    }
    &-preorder{
        display: flex;
        align-items: flex-start;
        margin-top: 15px;
        .icon{
            flex: 0 0 32px;
            margin-right: 5px;
        }
        span{
            @include font-set(body-small);
            line-height: 1.3;
        }
    }
    &-extra-warranty{
        border-top: 1px solid $c-grey-border;
    }
}

// Orders
.ord-grid.lp {
    margin: 10px (-$container-padding-x / 2) 0;
    padding: 20px 20px 0;
    border: 1px solid $c-grey-border;
    .ord {
        &-block1 {
            display: block;
        }
        &-no {
            margin-top: 5px;
        }
        &-payment-type {
            margin-top: 5px;
            color: $c-grey-dark;
        }
        &-price {
            font-weight: $fw-bold;
        }
        &-status {
            &::before {
                margin-right: 5px;
            }
        }
    }
}

// Order Detail
.ord-grid.dp {
    .ord {
        &-block1 {
            margin: 0 (-$container-padding-x);
            padding: 25px $container-padding-x 30px;
            background: $c-bg-light;
            .p {
                margin-top: 3px;
                font-weight: $fw-bold;
            }
        }
        &-no {
            .p {
                @include font-set(h-paragraph);
            }
        }
        &-payment-type , &-email{
            margin-top: 20px;
        }
        &-total {
            margin-top: 10px;
        }
        &-status {
            margin-top: 30px;
            font-weight: $fw-bold;
            &::before {
                margin-right: 10px;
                width: 11px;
                height: 11px;
            }
        }
        &-addresses {
            .item {
                padding: 20px 0;
                border-top: 1px solid $c-grey-border;
                .t {
                    @include font-set(h-xsmall);
                }
                .p {
                    margin-top: 10px;
                }
            }
        }
        &-chk-grid {
            margin: 0 (-$container-padding-x);
            padding: 0 20px;
            background: $c-bg-light;
        }
        &-purchase {
            margin-top: 20px;
            .item {
                margin-top: 10px;
                display: flex;
                .p {
                    margin-left: auto;
                }
                &.total {
                    margin-top: 30px;
                    padding-top: 30px;
                    border-top: 1px solid $c-grey-border;
                    .p {
                        font-weight: $fw-bold;
                    }
                }
            }
        }
        &-docs {
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid $c-grey-border;
            .item {
                margin-top: 10px;
            }
        }
        &-cancellation{
            padding: 25px 10px;
        }
    }
}

// Order Cancel/Return
.ocp{
    &-buttons{
        margin-top: 20px;
        text-align: right;
        .btn + .btn{
            margin-left: 15px;
        }
    }
    &-q{
        @include font-set(h-xsmall);
    }
    &-a{
        margin-top: 20px;
        &-content{
            display: none;
            padding: 20px 0 0 32px;
        }
        &-info{
            margin-top: 20px;
            li{
                p{
                    margin-top: 10px;
                }
                b{
                    display: block;
                    margin-bottom: 5px;
                    &.hero{
                        @include font-set(body-large); 
                    }
                }
                + li{
                    margin-top: 15px;
                }
            }
        }
        &.active{
            .ocp-a-content{
                display: block;
            }
        }
    }
}

// Notifications
.ntf-grid {
    margin-top: 15px;
    .ntf {
        &-item {
            animation: none;
            opacity: 1;
            padding: 30px 0;
            &::before {
                width: 10px;
                height: 10px;
                top: 37px;
            }
        }
        &-content {
            padding-left: 25px;
            b {
                @include font-set(h-paragraph);
            }
        }
        &-detail{
            .cpn-grid{
                margin-bottom: 30px;
            }
        }
        &-footer {
            animation: none;
            margin-top: 20px;
        }
    }
    #product-swiper {
        margin: 0 (-$container-padding-x);
        .swiper-pagination {
            display: none;
        }
    }
}

// Requests
.req-grid.lp {
    margin: 20px (-$container-padding-x / 2) 0;
    padding: 20px 20px 0;
    border: 1px solid $c-grey-border;
    .req {
        &-no {
            @include font-set(body-small);
            color: $c-grey-dark;
        }
        &-date {
            margin-top: 5px;
            font-weight: $fw-bold;
        }
        &-service {
            margin-top: 10px;
        }
        &-block1 {
            margin: 15px -10px 0;
            padding: 15px 10px;
            border-top: 1px solid $c-grey-border;
            display: flex;
            align-items: flex-start;
            > * {
                + * {
                    margin-left: 20px;
                }
            }
            .btn-req-detail {
                $i: 32px;
                margin-left: auto;
                margin-right: $i;
                svg {
                    position: absolute;
                    right: -($i + 10px);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

// Request Detail
.req-grid.dp {
    .req {
        &-map-embed {
            margin: 0 (-$container-padding-x);
        }
        &-map-info {
            margin-top: 20px;
            @include font-set(body-small);
            color: $c-grey-dark;
        }
        &-title {
            margin-top: 40px;
        }
        &-informations {
            .item {
                margin-top: 20px;
                .t {
                    @include font-set(h-small);
                }
                .p {
                    margin-top: 5px;
                    &::before {
                        margin-right: 5px;
                    }
                }
                .b {
                    margin-top: 20px;
                }
            }
        }        
    }
}

// Favorites
.fav {
    &-grid {
        margin: 20px (-$container-padding-x / 2) 0;
        padding: 20px 10px;
        border: 1px solid $c-grey-border;
    }
    &-name {
        @include font-set(h-xsmall);
    }
    &-block1 {
        margin-top: 10px;
        display: flex;
        align-items: center;
        max-width: 250px;
    }
    &-price {
        &-block1{
            font-weight: $fw-bold;
            display: inline-flex;
            flex-direction: column;
            .prc-first {
                color: $c-grey-dark;
            }
        }
        &-block2{
            .prc-third{
                font-weight: $fw-bold;
                color: $c-primary;
                &-info{
                    @include font-set(body-small);
                    padding-left: 4px;
                }
            }
        }
    }
    &-add-to-cart {
        margin-left: auto;
    }
    &-remove {
        position: absolute;
        top: -5px;
        right: 10px;
    }
    &-status{
        text-align: right;
        color: $c-grey-dark;
    }
}

// Coupons
.cpn {
    $pr: 60px;
    &-grid {
        margin: 10px (-$container-padding-x / 2) 0;
        padding: 20px 20px 0;
        border: 1px solid $c-grey-border;
        .grid-acc {
            .active {
                .acc-body {
                    padding: 0 10px 20px;
                }
                .cpn-button{
                    visibility: hidden;
                }
            }
        }
    }
    &-title {
        padding-right: $pr;
        font-weight: $fw-bold;
    }
    &-date {
        padding-right: $pr;
    }
    &-status {
        margin-top: 5px;
        &::before {
            margin-right: 5px;
        }
    }
    &-badge {
        position: absolute;
        top: 20px;
        right: 20px;
        text-align: center;
        color: #6e994e;
        font-weight: $fw-bold;
        small {
            display: block;
            @include font-set(body-small);
        }
    }
    &-info {
        color: $c-grey-dark;
        margin: -30px 0 15px;
    }
    &-code {
        margin-top: 30px;
        .p {
            display: flex;
            align-items: center;
        }
        .icon {
            flex: 0 0 32px;
            margin-left: 20px;
        }
    }
    &-no{
        @include font-set(body-large);
        font-weight: $fw-bold;
    }
}

// Profile Informations
.inf {
    &-grid {
        margin-top: 20px;
    }
    &-group {
        &-title {
            @include font-set(h-xsmall);
            margin-bottom: 10px;
        }
        
        &-footer {
            margin-top: 20px;
            text-align: center;
        }
    }    
}

.usr-addresses {
    .item {
        position: relative;
        margin-top: 10px;
        padding: 20px;
        background: $c-bg-light;
        .t {
            @include font-set(h-xsmall);
            padding-right: 100px;
        }
        .p {
            margin-top: 15px;
        }
        .b {
            position: absolute;
            top: 24px;
            right: 20px;
            @include font-set(body-small);
        }
    }
}

.usr-profile-informations {
    margin-top: 20px;
    @at-root {
        .btn-profile-save {
            width: 100%;
        }
    }
    .delete-account{
        background-color: $c-bg-light;
        padding: 30px 0;
        margin: 40px -20px -40px;
        text-align: center;
    }
}

// Change Password
.psw {
    &-grid {
        margin-top: 30px;
    }
}

// Forgot Password
.page-forgot-password{
    background-color: $c-bg-light;
    .spt-header {
        background: $c-bg-body;
    }
    .spt-hdr-inner {
        height: auto;
    }
    .spt-hdr-content {
        padding: 9px 15px;
    }
}

#forgot-password{
    padding: 30px 10px;
    .form{
        max-width: 315px;
        margin: 0 auto;
        &-button{
            padding-top: 10px;
            text-align: center;
        }
        &-footer{
            text-align: center;
            margin-top: 30px;
        }
        &-row-info{
            margin-top: 10px;
            @include font-set(body-small);
            padding-bottom: 10px;
        }
        &-reset{
            padding-top: 25px;
        }
    }
}

.fpr{
    &-desc{
        margin-bottom: 10px;
    }
    &-email{
        margin-bottom: 20px;
        font-weight: $fw-bold;
        text-align: center;
    }
    &-info{
        text-align: center;
    }
    &-button{
        text-align: center;
        margin-top: 45px; 
    }
}