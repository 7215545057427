// Popular Products
#popular-products, #featured-techs{
    .bnr-header, .bnr-footer{
        opacity: 1;
        transform: translateY(0);
    }
}

@media (min-width: 500px) {
    // Content Pages (mucize lezzetler)
    .cnt {
        &-cover-image {
            figure{
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: auto;
                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@media (min-width: 666px) {
    // #site-main{
    //     .category-cover{
    //         height: 375px;
    //     }
    // }

    .cover-media{ 
        .responsive{
            width: 100%;
            height: auto;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}