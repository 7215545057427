// header
#site-header {
    &::before {
        content: "";
        display: block;
        height: $header-height-mobile;
    }
}

@include header-appshell;

// .page-type-cover {
//     #site-header {
//         &::before {
//             display: none;
//         }
//     }
//     .header-top {
//         background: $header-top-cover-bg;
//         color: $header-top-cover-color;
//         > .container {
//             > .row {
//                 border-color: $header-top-cover-border-bottom-color;
//             }
//         }
//     }
//     .logo-org {
//         display: none;
//     }
//     .logo-alt {
//         display: inline-block;
//     }

//     &.header-opaque {
//         .header-top {
//             background: $header-top-opaque-bg;
//             color: $header-top-opaque-color;
//         }
//         .logo-alt {
//             display: none;
//         }
//         .logo-org {
//             display: inline-block;
//         }
//         .logo {
//             img {
//                 animation: logo .333s ease;
//             }
//         }
//     }
// }

%header-modals {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-higher;
    width: 100%;
    height: 100%;
    background: $c-bg-body;
    color: $body-color;
    overflow: auto;

    .btn-close {
        position: absolute;
        right: 20px;
        top: 9px;
        z-index: $z-lower;
    }

    &.activated {
        display: flex;
    }
}

// menu
#main-menu {
    @extend %header-modals;
    animation: toRight .2s ease;
    padding: 0 0 60px;
    li {
        > a {
            position: relative;
            display: flex;
            align-items: center;
            .qty-badge {
                margin-left: auto;
                margin-right: 45px;
            }
        }
        > .sub {
            display: none;
        }
        &.selected {
            // background: $menu-sub-bg;
            > a {
                font-weight: 700;
            }
            > .sub {
                display: flex;
                flex-direction: column;
            }
        }
        &.all {
            > a {
                &::before {
                    @extend .svg;
                    @extend .svg-plus-c;
                    content: "";
                    margin: (($menu-lv-2-img-height - 32px) / 2) 0;
                }
            }
        }
    }
}

.menu-user {
    padding: 0 $menu-space-x;
    > li {
        > a {
            @include font-set(h-xsmall);
            padding: 15px 0;
        }
    }
}

.menu-search {
    padding: 0 $menu-space-x;
    > li {
        > a {
            border-top: 1px solid $menu-lv-1-border-color;
            padding: 15px 0;
        }
    }
}

.menu-category {
    > li {
        animation: toTop 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
        opacity: 0;
        > a {
            padding: $menu-lv-1-padding;
            border-top: 1px solid $menu-lv-1-border-color;
            width: calc(100% - (#{$menu-space-x} * 2));
            margin: 0 auto;
            &::after {
                @extend .svg;
                @extend .svg-arrow2-r;
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
            > .responsive {
                display: none;
            }
        }
        // &.selected {
        //     > a {
        //         &::after {
        //             @extend .svg-minus;
        //         }
        //     }
        // }
        &.no-sub {
            > a {
                &::after {
                    @extend .svg-arrow2-r;
                }
            }
        }
        @for $i from 1 through 10 {
            &:nth-child(#{$i + 1}) {
                animation-delay: $i * .05s;
            }
        }
    }
    // lv 2
    .lv-2 {
        // display: flex;
        // flex-wrap: wrap;
        // padding: 0 10px 20px;
        > li {
            // width: calc(100% / #{$menu-lv-2-row-item});
            // padding: 10px;
            > a {
                // flex-direction: column;
                // text-align: center;
                > .responsive {
                    max-width: $menu-lv-2-img-width;
                }
                > span {
                    margin-top: 15px;
                }
                &::after {
                    @extend .svg;
                    @extend .svg-plus;
                }
            }
            &.selected {
                > a {
                    &::after {
                        @extend .svg-minus;
                    }
                }
            }
            &.no-sub{
                > a{
                    &::after {
                        @extend .svg-arrow2-r;
                    }
                }
            }
        }
    }
    // lv 3
    .lv-3 {
        display: none;
        padding: 5px 0;
        > li {
            padding: 5px 0;
            // > a {
            //     display: inline-flex !important;
            // }
        }
    }
    // banner
    .banner {
        @include responsive(375, 180);
    }
}

.menu-brands {
    $p: 2.5px;
    display: flex;
    flex-wrap: wrap;
    padding: (20px - $p) (10px - $p) (40px - $p);
    > li {
        width: calc(100% / #{$menu-brand-row-item});
        padding: $p;
        > a {
            justify-content: center;
            background: $c-white;
            height: $menu-brand-height;
            @include responsive(115, 61);
            > .responsive {
                max-width: 115px;
            }
        }
    }
}

.menu-links {
    margin-top: 15px;
    padding: 0 $menu-space-x;
    > li {
        > a {
            padding: 15px 0;
        }
    }    
}

.menu-social-links {
    margin-top: 30px;
    padding: 0 $menu-space-x;
    display: flex;
    align-items: center;
    > li {
        margin-right: 30px;
    }
}

// search
$search-space-x: 20px;
#header-search {
    @extend %header-modals;
}

.search-input {
    padding: 10px;

    .btn-clear{
        display: none;
    }
}

.suggested-searches {
    padding: 25px;
    text-align: center;
    > .area-title {
        margin-bottom: 30px;
    }
    > ul {
        > li {
            padding: 15px;
            > a {
                display: inline-flex;
                color: $c-grey-dark;
            }
        }
    }
}

.suggested-results {
    display: none;
    padding: 0 $search-space-x;
    overflow: auto;
    > ul {
        > li {
            + li {
                border-top: 1px solid $c-grey-border;
            }
        }
    }

    .prd-row{
        &-inner{
            margin: 0;
        }
        &-media{
            width: 60px;
        }
        &-price{
            margin-top: 0;
            span{
                font-weight: $fw-normal;
            }
        }
        &-block1 {
            padding: 0 10px;
        }
    }
}

.searching{
    .suggested-results, .search-input .btn-clear{
        display: block;
    }

    .suggested-searches{
        display: none;
    }
}

// Popper
%popper{
    background-color: $c-light;
    border-radius: $br-popover;
    padding: 20px 15px;
    position: absolute;
    top: $header-height-mobile - 9px;
    width: calc(100% - 20px);
    max-width: 355px;
    color: $body-color;
    display: none;

    &::before{
        content:"";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent $c-light transparent;
        position: absolute;
        top: -8px;
    }

    &.activated{
        display: block;
    }
}

// Notifiers
#notifiers{
    @extend %popper;
    left: -36px;
    width: calc(100vw - 20px);
    &::before{
        left: 55px;
    }
}

#cart-notifier{
    @extend %popper;
    z-index: $z-high;
    position: fixed;
    left: auto;
    right: 10px;
    &::before{
        right: 13px;
    } 
}

.ntf{
    &-holder{
        position: relative;
    }
    &-header{
        position: relative;
        margin-bottom: 20px;

        .btn-close{
            position: absolute;
            right: 0;
            top: -10px;
        }
    }

    &-title{
        @include font-set(h-small);
        color: $c-primary;
    }

    &-item{
        animation: toTop 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 15px 5px;
        opacity: 0;
        position: relative;

        + .ntf-item{
            border-top: 1px solid $c-grey-light;
        }

        &::before{
            position: absolute;
            top: 20px;
            left: 5px;
        }

        @for $i from 1 through 10 {
            &:nth-child(#{$i + 1}) {
                animation-delay: $i * .05s;
            }
        }

        &.visited {
            .ntf-content {
                opacity: .6;
            }
        }
    }

    @at-root {
        [class*="ntf-type-"] {
            &::before{
                content: "";
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }
        }
    }
    
    @each $i, $ntf in $c-notifiers{
        &-type-#{$i}{
            &::before{
                background-color: $ntf;
            }
        }
    }

    &-no-img{
        padding-left: 23px;
    }

    &-img{
        width: 50px;
        background-color: $c-bg-body;
        border-radius: 50%;
        padding: 5px;
    }

    &-content{
        flex: 1;
        padding-left: 10px;

        b{
            display: block;
            font-weight: $fw-bold;
        }

        p{
            margin-bottom: 0;
        }
    }

    &-detail {
        width: 100%;
        margin-top: 20px;
    }

    &-footer{
        text-align: center;
        margin-top: 15px;
        animation: toTopDelayed 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
    }

    &-ready{
        #overlay{
            display: block;
        }
    }

    &-cart-success{
        text-align: center;
        .icon{
            color: $c-success;
        }
    }
    &-msg{
        @include font-set(h-small);
        margin-top: 10px;
    }
}

#cart-notifier{
    .ntf-footer{
        display: flex;
        margin-top: 25px;
        .btn{
            padding: 0 20px;
            flex: 1;
            &:first-child{
                margin-right: 10px;
            }
        }
    }
    .wty{
        &-msg{
            text-align: center;
            margin: 5px auto 0;
            max-width: 200px;
        }
        &-row + .wty-row{
            border-top: 1px solid $c-grey-border;
        }
    }
}

// Hidden Header
.m-header-hidden{
    #site-header{
        display: none;
    }
}

// Header User Menu
.hum{
    &-body{ 
        padding-top: 15px;
        position: absolute;
        top: 14px;
        left: 50%;
        transform: translateX(-50%);
        &::before{
            content:"";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent $c-light transparent;
            position: absolute;
            top: 7px;
            left: calc(50% - 4px);
        }
        ul{
            padding: 20px 15px;
            background-color: $c-light;
            border-radius: $br-popover;
            color: $body-color;
        }
        li + li{
            margin-top: 10px;
        }
    }
}

// Header Support Menu
.hsm{
    &-body{
        padding-top: 15px;
        position: absolute;
        top: 14px;
        left: 50%;
        transform: translateX(-50%);
        &::before{
            content:"";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent $c-light transparent;
            position: absolute;
            top: 7px;
            left: calc(50% - 4px);
        }
        ul{
            padding: 20px 15px;
            background-color: $c-light;
            border-radius: $br-popover;
            color: $body-color;
        }
        li + li{
            margin-top: 10px;
        }
    }
    > a{
        display: flex;
        align-items: center;
        &::after{
            content: "";
            @extend .svg;
            @extend .svg-arrow-b;
            transform: scale(.75);
        }
    }
}