// mobile elements
#main-menu .btn-close,
.menu-user,
.menu-search,
.menu-links,
.menu-social-links,
.menu-category > li > a::after,
#main-menu li.all {
    display: none !important;
}

// header
#site-header {
    &::before {
        height: $header-height-desktop;
    }
}

@include header-appshell-desktop;

%d-header-modals {
    .header-top {
        background: $header-top-bg;
        color: $header-top-color;
        transition: none;

        > .container > .row{
            border-bottom: $header-top-border-bottom;
        }
    }
    .logo img {
        animation: none !important;
    }
    .logo-org {
        display: inline;
    }
    .logo-alt {
        display: none;
    }
    #overlay {
        display: block;
    }
}

// menu
$d-menu-space-x: 20px;
#main-menu {
    position: relative;
    top: 0;
    height: auto;
    background: $c-white;
    overflow: visible;
    padding: 0 $d-menu-space-x;
    li {
        > .sub {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background: $c-white;
            padding: 25px 0;
            align-items: flex-start;
            justify-content: center;
            max-height: calc(100vh - 290px);
            overflow: auto;
        }
        &.selected {
            background: none;
            > a {
                font-weight: 400;
            }
            > .sub {
                flex-direction: row;
                background-color: $c-bg-grey;
                color: $c-text-light;
            }
        }
    }
}

.menu-category {
    $p: 10px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $c-grey-lighter;
    animation: none;
    > li {
        width: 100px + ($p * 2);
        padding: 20px $p 0;
        opacity: 1;
        animation: none;
        > a {
            flex-direction: column;
            text-align: center;
            padding: 0 0 15px;
            border-top: none;
            border-bottom: 3px solid transparent;
            top: 1px;
            width: 100%;
            height: 100%;
            > .responsive {
                display: inline-block;
                margin-bottom: 5px;
            }
        }
        &.selected {
            > a {
                border-color: $c-primary;
            }
        }
    }
    // lv 2
    .lv-2 {
        display: block;
        column-count: 4;
        column-gap: 0;
        padding: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 1080px;
        > li {
            width: auto;
            padding: 5px 30px;
            break-inside: avoid;
            > a {
                display: inline-flex !important;
                flex-direction: row;
                text-align: left;
                // > .responsive {
                //     display: none;
                // }
                > span {
                    margin-top: 0;
                    @include font-set(h-xsmall);
                }
            }
        }
        .sublv{
            .banner{
                display: none;
            }
        }
    }
    // lv 3
    .lv-3 {
        display: block;
        .responsive {
            display: none;
        }
    }
    // banner
    .banner {
        flex: none;
        order: 1;
        width: 375px;
        margin: 5px 120px 0 0;
    }
}

.menu-brands {
    $p: 15px;
    flex: 1;
    justify-content: center;
    padding: 0;
    > li {
        max-width: 115px + ($p * 2);
        padding: 0 $p;
    }
}

.menu-ready {
    @extend %d-header-modals;
    #main-menu {
        animation: none;
    }
}

// search
$c: 720px + ($container-padding-x * 2);
#header-search {
    position: relative;
}

.search-input {
    padding: 30px $container-padding-x;
    width: 100%;
    max-width: $c;
    margin: auto;
    .btn-back {
        position: fixed;
        top: 70px;
        right: 91px;
        margin-right: 0 !important;
        background: $c-white;
        // border-bottom: 3px solid $c-primary;
        // padding: 5px 5px 11px;
        .d {
            display: flex;
        }
    }
    .btn-search-post {
        display: block;
    }
}

.suggested-searches {
    width: 100%;
    max-width: $c;
    margin: auto;
    padding: 0 $container-padding-x 60px;
    text-align: left;
    .area-title {
        margin-bottom: 0;        
    }
    > ul {
        margin-left: -15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.suggested-results {
    $search-input-height: 112px;
    padding-bottom: 50px;
    height: calc(95vh - #{$search-input-height} - #{$header-height-desktop});
    > ul {
        display: grid;
        grid-template-columns: repeat(5, minmax(auto, 180px));
        gap: 30px;
        justify-content: center;
        > li {
            + li {
                border: none;
            }
        }
    }

    // Row View
    .prd-row {
        &-inner {
            border: 1px solid $c-grey-light;
            flex-direction: column;
            align-items: stretch;
            padding: 0;
            height: 320px;
        }
        &-media {
            width: 160px;
            margin: 10px auto 5px;
        }
        &-name {
            @include font-set(h-xtiny);
            margin-bottom: 8px;
        }
        &-price {
            display: flex;
            flex-direction: column;
            @include font-set(body-small);
            .prc-first {
                margin-bottom: 3px;
                color: $c-grey-dark;
                text-decoration: line-through;
            }
            .prc-last {
                font-weight: $fw-bold;
            }
            &-third{
                margin-top: 3px;
                .prc-third{
                    color: $c-primary;
                    font-weight: $fw-bold;
                    &-info{
                        padding-left: 5px;
                        @include font-set(body-xsmall);
                    }
                }
            }
        }
        &-block2 {
            display: block;
            padding: 10px 5px;
            border-top: 1px solid $c-grey-light;
            margin: 0 5px;
        }
    }
}

[class*="search-ready"] {
    @extend %d-header-modals;
}

.search-input {
    .btn-clear{
        display: none !important;
    }
}

// Notifiers
#notifiers{
    left: -46px;
    top: $header-height-desktop - 14px;
}

#cart-notifier{
    right: 35px;
    top: $header-height-desktop;
}

// Hidden Header
.m-header-hidden{
    #site-header{
        display: block;
    }
}

// Header User Menu
.hum{
    &:hover{
        .hum-body{
            display: block;
        }
    }
}

// Header Support Menu
.hsm{
    &:hover{
        .hsm-body{
            display: block;
        }
        > a{
            &::after{
                transform: scale(.75) rotate(180deg);
            }
        }
    }
}

// Search Opened
.search-ready{
    .btn-search.d{
        max-width: 50px;
        .placeholder{
            display: none;
        }
        .icon-holder{
            margin-right: 0;
            .icon-close{
                display: inline-block;
            }
            .icon-search{
                display: none;
            }
        }
    }
    .search-input{
        .btn-back{
            display: none;
        }
    }
}