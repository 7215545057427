// Service Item
.srv {
    &-list {
        margin-top: 20px;
    }
    &-item {
        $p: 20px;
        position: relative;
        border-bottom: 1px solid $c-grey-border;
        padding: $p;
        &:after {
            @extend .svg;
            @extend .svg-arrow2-r;
            content: "";
            position: absolute;
            right: $p;
            top: $p;
        }
        &:first-child {
            border-top: 1px solid $c-grey-border;
        }
        > div {
            position: relative;
        }
    }
    &-name {
        @include font-set(h-small);
        padding-right: 50px;
    }
    &-status {
        margin-top: 7px;
        [class*="ntf-type-"] {
            &::before {
                margin-right: 7px;
            }
        }
        .btn-hours {
            margin-left: 7px;
            text-decoration: underline;
            color: $c-grey-dark;
        }
    }
    &-address {
        margin-top: 15px;
    }
    // &-phone {
    //     z-index: 3;
    // }
    &-email{
        display: none;
    }
    &-link {
        @extend .bnr-link;
    }
    &-match{
       margin-top: 10px;
       width: 100%;
       display: flex; 
    }
    &-stock{
       display: inline-flex;
       align-items: center;
    }
    &-distance{
        margin-left: auto;
    }
    &-dir{
        display: none;
    }
    &-fwd-bar{
        display: inline-block;
        width: 40px;
        margin-left: 6px;
        font-size: 0;
        &::before{
            content: "";
            width: 0;
            height: 100%;
            display: inline-block;
        }

        @each $k, $v in $c-stock-bars{
            &[data-stock="#{$k}"]{
                &::before{
                    background-color: $v;
                    width: $k * 25%;
                }
            }
        }
    }
}

// Store Finder
.store-finder{
    padding: 0 20px 20px;

    .stf{
        &-button{
            margin-top: 20px;
            display: flex;
            .btn-primary{
                margin-right: 10px;
                flex: 1;
            }
            .btn-outline-dark{
                padding: 0 35px;
            }
        }
        &-result{
            margin-top: 30px;
            span::before{
                margin-right: 6px;
            }
        }
    }
}

// Map View
.map-view{
    $map-view-header-height: 50px;
    &-container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z-lower;
        background-color: $c-white;
        display: none;
        #Map{
            height: calc(100% - #{$map-view-header-height});
        }
    }
    &-header{
        background-color: $c-white;
        height: $map-view-header-height;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 15px;
        .btn-spt-back{
            @extend .svg;
            @extend .svg-arrow2-r;
            display: inline-block;
            transform: rotate(180deg);
        }
    }
    &-title{
        flex: 1;
        @include font-set(h-xsmall);
        margin-left: 10px;
    }
    &-content{
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        .srv{
            &-item{
                background-color: $c-white;
                width: 285px;
                border-radius: $br-popover;
                &::after{
                    display: none;
                }
            }
            &-map, &-stock{
                display: none;
            }
            &-right{
                margin: 45px auto 0;
                max-width: 245px;
                .btn{
                    width: 100%;
                    height: $btn-h-default;
                    border-radius: $btn-h-default * $btn-br-factor;
                }
            }
        }
        > .srv-item{
            margin: 0 auto;
        }
    }
}

.map-view-activeted{
    .modal-header,
    #click-collect-stores{
        display: none;
    }
    .modal-body{
        overflow: hidden;
    }
    .map-view-container{
        display: block;
    }
}

// Support Pages
.page-support {
    margin-bottom: 75px;
    #site-footer {
        margin-top: 0;
    }
}

// Support
.page-support-main {
    .category-list {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
        li {
            width: 120px;
            margin: 2.5px;
        }
    }
    #site-main {
        background: $c-bg-colored;
    }
}

.spt-bnf-list {
    margin-top: 60px;
}
.spt-bnf {
    position: relative;
    padding: 30px 20px 30px 64px;
    background: $c-bg-body;
    .icon {
        position: absolute;
        top: 30px;
        left: 20px;
    }
    .t {
        @include font-set('h-small');
    }
    .p {
        margin-top: 7px;
    }
    .b {
        margin-top: 20px;
    }
    .k{
        margin-top: 5px;
        @include font-set('body-large');
        font-weight: $fw-bold;
    }
    
    + .spt-bnf {
        border-top: 1px solid $c-grey-border;
    }
}

.spt-map-embed {
    margin-top: 10px;
}

.spt-search {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $z-low;
    width: 100%;
    padding: 15px 10px 10px;
    &-title{
        display: none;
    }
    &-input {
        position: relative;
        display: flex;
        box-shadow: 1px 1px 2px 0 rgba(37, 37, 37, 0.15);
        border-radius: 5px;
        background: $c-bg-body;
        input {
            flex: 1;
            line-height: 54px;
            padding: 0 20px;
            background: none;
            border: none;
            border-radius: 5px;
            max-width: calc(100% - 124px);
            &:focus + .spt-search-kws, &:valid + .spt-search-kws{
                display: none;
            }
        }
        button {
            padding: 0 15px;
        }
    }
    &-kws{
        pointer-events: none;
        position: absolute;
        display: flex;
        padding: 0 55px;
        align-items: center;
        width: 100%;
        height: 100%;
        span{
            display: none;
            overflow: hidden;
            color: $c-text-weak;
            animation: show .666s ease;
            white-space: nowrap;
            &.active{
                display: block;
            }
        }
    }

    .btn-back {
        display: none;
    }

    &-suggest {
        display: none;
        border-radius: 5px;
        padding: 0 20px 15px;
        height: 100%;
        overflow: auto;

        @at-root {
            .spt-sug-row {
                display: none;
                padding: 20px 10px;
            
                time {
                    @include font-set(body-small);
                    color: $c-grey-dark;
                }
            
                &.prd-row {
                    padding: 10px 0;
                    .prd-row {
                        &-inner {
                            margin: 0;
                            padding: 0;
                        }
                        &-media {
                            width: 60px;
                        }
                    }
                }
            
                + .spt-sug-row {
                    border-top: 1px solid $c-grey-border;
                }

                &-empty {
                    padding: 40px 0;
                    > div {
                        + div {
                            margin-top: 40px;
                        }
                    }
                    .t {
                        @include font-set(body-large);
                        font-weight: $fw-bold;
                    }
                    .p {
                        margin-top: 10px;
                    }
                    .md {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    &.searching {
        .spt-sug-row {
            display: block;
            &-empty {
                display: none;
            }
        }
    }

    &-ready {
        overflow: hidden;
        #overlay {
            display: block;
        }
        .spt-search {
            bottom: auto;
            top: 0;
            height: 100vh;
            background: $c-bg-body;
            z-index: $z-high;
            
            .btn-back {
                display: block;
            }

            .btn-search{
                display: none;
            }
            
            &-input {
                background: $c-bg-light;
                input {
                    padding: 0;
                }
            }
            &-suggest {
                display: block;
            }
            &-kws{
                display: none;
            }
        }
    }
}

// Product Detail
.page-support-product-detail{
    .pdp-mini-features{
        margin-top: 50px;
        padding-right: 20px;
    }
}

// Services
.page-services {
    margin-bottom: 0;
    .srv-list {
        margin-top: 0;
    }

    .nav-link {
        flex: 1;
    }

    &.map-view-activeted {
        .map-view-container {
            position: fixed;
            z-index: $z-high;
        }
        .swiper-container {
            .swiper-button-prev,
            .swiper-button-next,
            .swiper-pagination {
                display: none;
            }
            .swiper-slide {
                width: 285px;
                
                .srv-item {
                    background: $c-bg-light;
                }
                .srv-email{
                    display: flex;
                    align-items: center;
                    text-align: center;
                    height: 40px;
                    padding: 0 20px;
                    border: 1px solid $c-grey-dark;
                    border-radius: 20px;
                    @include font-set(body-small, true);
                }
                .srv-phone{
                    display: flex;
                    align-items: center;
                    text-align: center;
                    height: 40px;
                    padding: 0 25px;
                    border: 1px solid $c-primary;
                    border-radius: 20px;
                    @include font-set(body-small, true);
                    margin-right: 10px;
                }
                .srv-footer{
                    display: flex;
                    justify-content: space-between;
                    margin-top: 5px;
                }
                .srv-dir{
                    display: block;
                }
                .srv-contact{
                    display: flex;
                    margin-top: 30px;
                }
                .srv-link{
                    display: none;
                }

                + .swiper-slide {
                    margin-left: 10px;
                }
                &:first-child {
                    margin-left: 20px;
                }
                &:last-child {
                    margin-right: 20px;
                }

                &-active {
                    .srv-item {
                        background: $c-bg-body;
                    }
                }
            }
        }
    }
}

.find-service {
    padding: 0 20px 30px;

    .spt-bnf {
        margin-top: 30px;
        padding-left: 45px;
        border-top: 1px solid $c-grey-border;
        .icon {
            left: 0;
        }
    }

    @at-root {
        .btn-srv-search {
            margin-top: 20px;
            width: 100%;
        }
        .btn-loc-search {
            width: 100%;
        }
    }
}

.store-gallery {
    @include responsive(375, 240);
    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }
    .swiper-pagination {
        position: absolute;
        bottom: 0;
        z-index: $z-lower;
        padding: 20px;
    }
}

.srv-information {
    padding: 30px;
    line-height: 1.5;
    .st {
        @include font-set(body-large);
    }
    p:last-child {
        margin-bottom: 0;
    }
}

.map-view-cover {
    display: none;
    @include responsive(720, 780);
}

// Support Article Pages
.spt{
    &-header{
        background-color: $c-bg-colored;
        .breadcrumb{
            display: none;
        }
    }
    &-hdr{
        &-inner{
            height: 150px;
            width: 100%;
            position: relative;
        }
        &-media{
            width: 230px;
            height: 100%;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;
            figure{
                width: 100%;
            }
            img{
                width: 100%;
            }
        }
        &-content{
            display: flex;
            align-items: center;
            padding: 10px 0 0 15px;
            .btn-spt-back{
                margin-right: 10px;
                display: inline-block;
            }
        }
        &-title{
            margin: 0;
            @include font-set(h-xsmall);
        }
    }
    &-article{
        &-filters{
            .nav-link{
                flex: 1;
            }
        }
        &-list{
            padding: 25px 20px;
        }
        &-header{
            height: 50px;
            padding: 9px 15px;
            .btn-spt-back{
                display: inline-block;
            }
            .breadcrumb{
                display: none;
            }
        }
        &-content{
            margin: 0 auto;
            max-width: 780px;
            padding: 0 30px;
            p{ 
                margin: 0;
                + p{
                    margin-top: 20px;
                }
                + .spt-article-subtitle{
                    margin-top: 40px;
                }
            }
        }
        &-title{
            @include font-set(h);
            margin-bottom: 20px;
        }
        &-video{
            margin: 40px 0;
            video{
                width: 100%;
            }
        }
        &-subtitle{
            margin-bottom: 20px;
            @include font-set(h-paragraph);
        }
        &-tags{
            margin-top: 40px;
            padding: 0 30px;
            a{
                @include font-set(body-small);
                color: $c-grey-dark;
            }
        }
        &-feedback{
            margin: 30px 0 45px;
            padding: 0 30px;
            span{
                display: block;
                margin-bottom: 15px;
            }
            .btn + .btn{
                margin-left: 10px;
            }
        }
    }
    &-video{
        &-info{
            margin-top: 5px;
            padding: 0 30px;
            color: $c-grey-dark;
        }
    }
    &-list{
        &-info{
            @include font-set(body-small);
            margin-bottom: 25px;
            padding-left: 10px;
        }
        &-title{
            text-align: center;
            @include font-set(h);
            margin-bottom: 20px;
        }
        &-item{
            border-top: 1px solid $c-grey-light;
            position: relative;
            a{
                display: block;
                padding: 20px 10px;
                font-weight: $fw-bold;
            }
            &.spt-item-video a::after{
                position: absolute;
                top: calc(50% - 16px);
                right: 0;
                content: "";
                display: inline-block;
                @extend .svg;
                @extend .svg-video-type-dark;
            }
        }
    }
    &-bnf{
        &-title{
            margin-bottom: 20px;
            @include font-set(h);
            text-align: center;
        }
        &-slider{
            background-color: $c-bg-colored;
            padding: 40px 0;
            margin-top: 45px;
            .spt-bnf {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                padding: 30px 20px 40px;
                border-radius: $br-popup-middle;
                height: 100%;
                .icon {
                    position: static;
                }
                .t {
                    margin-top: 40px;
                }
                .b {
                    margin-top: auto;
                    padding-top: 50px;
                }
            }
            .swiper-slide{
                width: 220px;
                height: auto;
                + .swiper-slide{
                    margin-left: 10px;
                }
                &:first-child{
                    margin-left: 20px;
                }
                &:last-child{
                    margin-right: 20px;
                }
            }
            .swiper-button-next, .swiper-button-prev{
                display: none !important;
            }
        }
    }
}

// Buying Guide
.page-support-buying-guide, .page-support-service-app, .page-support-service-request, .page-support-software-finder, .page-support-software-manual-finder{
    .spt-header {
        background: none;
    }
    .spt-hdr-inner {
        height: auto;
    }
    .spt-hdr-content {
        padding: 9px 15px;
    }
}
.spt-guide-list {
    padding: 0 0 50px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    .bnr {
        &-media {
            @include responsive(335, 200);
            &-info-video {
                padding: 0 20px 20px;
            }
        }
        &-body {
            padding: 10px 0 40px;
        }
        &-title{
            @include line-clamp(2);
            height: 55px;
        }
    }
}

// Contact Us
.contact{
    padding: 0 20px;
    h1.ctc-title{
        margin: 20px 0;
    }
    #contact-result{
        display: none;
    }
}

.ctc{
    &-title{
        text-align: center;
        @include font-set(h);
        margin-bottom: 10px;
    }
    &-support{
        padding: 40px 10px;
        background-color: $c-bg-colored;
        text-align: center;
        p{
            margin-bottom: 20px;
        }
    }
    &-form{
        margin-top: 30px;
        padding: 0 10px;
        p{
            text-align: center;
            margin-bottom: 30px;
        }
        .btn{
            width: 100%;
        }
    }
    &-info{
        margin-top: 60px;
        &-row{
           margin-bottom: 30px; 
        }
        &-title{
            @include font-set(h-small);
            margin-bottom: 10px;
        }
    }
}

.contact-form-completed{
    .ctc-form-inner{
        display: none;
    }
    #contact-result{
        display: block;
    }
}

// Service Appointment & Support Form
#app{
    &-wizard{
        background-color: $c-bg-light;
        .nav-link{
            pointer-events: none;
            span{
                position: relative;
                padding-left: 16px;
                &::before{
                    content: attr(data-content);
                    position: absolute;
                    width: 32px;
                    height: 32px;
                    top: calc(50% - 16px);
                    left: -16px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            &.completed{
                pointer-events: all;
                span::before{
                    content: "";
                    @extend .svg;
                    @extend .svg-completed;
                }
            }
        }
        &.manual-selection{
            .app-prd-list{
                display: none;
            }
            .app-man-section{
                display: block;
            }
        }
    }
    &-result{
        background-color: $c-bg-light;
        text-align: center;
        padding: 50px 10px;
        display: none;
    }
}

.app{
    &-steps{
        padding: 30px;
    }
    &-step{
        display: none;
        &.active{
            display: flex;
        }
        &-info{
            margin-bottom: 30px;
        }
        .form-button{
            padding-top: 10px;
            text-align: center;
        }
        .btn-next{
            width: 100%;
            max-width: 315px;
        }
        .prd-row{
            border-bottom: 1px solid $c-grey-light;
            &-inner{
                margin: 0;
            }
            &-media{
                width: 60px;
            }
        }
        .form-submit{
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid $c-grey-light;
        }
    }
    &-man{
        &-select{
            text-align: center;
            margin: 20px 0 30px;
        }
        &-section{
            display: none;
            margin-bottom: 10px;
            .app-prd{
                margin-bottom: 10px;
            }
        }
    }
    &-res{
        &-bottom{
            padding: 40px 20px;
            margin-top: 45px;
            background-color: $c-bg-colored;
        }
        &-inner{
            max-width: 205px;
            margin: 0 auto;
            .btn{
                margin: 40px 0 20px;
            }
        }
    }
}

.time-picker{
    .swiper-pagination, .swiper-button-next, .swiper-button-prev{
        display:none;
    }
    .swiper-slide{
        width: 230px;
        margin-left: 30px;
        &:last-child{
            margin-right: 30px;
        }
    }
}
.tpc{
    &-title{
        @include font-set(body-small);
        margin-bottom: 20px;
    }
    &-date{
        font-weight: $fw-bold;
        margin-bottom: 5px;
    }
    &-day{
        color: $c-grey-dark;
        margin-bottom: 20px;
    }
    &-times{
        font-size: 0;
        .btn{
            padding: 0;
            width: 110px;
            margin-bottom: 10px;
            &:nth-child(2n){
                margin-left: 10px;
            }
        }
    }
    &-swiper{
        margin: 0 -30px;
    }
}

.app-form-completed{
    #app{
        &-wizard{
            display: none;
        }
        &-result{
            display: block;
        }
    }
}

.page-support-service-request{
    .nav-link{
        flex: 1;
    }
}

// TV Software Finder
.tsf{
    &-info{
        margin-bottom: 30px;
    }
    &-label{
        @include responsive(768, 399);
        margin-bottom: 20px;
    }
    &-form{
        .submit{
            text-align: center;
        }
    }
}

// Live Chat Form
#live-chat-form{
    max-width: 540px;
    margin: 0 auto;
    .form-button{
        padding-top: 20px;
        text-align: center;
        &.finish{
            padding-top: 0;
            .btn{
                width: 100%;
            }
        }
    }
    .confirm-code{
        padding-top: 10px;
    }
}

.lcf{
    &-header{
        background-color: $c-bg-colored;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 45px 30px;
        figure{
            margin-bottom: 20px;
        }
        h1{
            text-align: center;
            @include font-set(h-large);
        }
    }
    &-form{
        padding: 30px;
    }
}

// Software & Guide Finder
.sgf{
    margin: 25px 0 130px;
    &-tabs{
        .nav-tabs{
            margin: 20px -20px 0;
            width: calc(100% + 40px);
        }
        .nav-link{
            white-space: normal !important;
            flex: 1;
        }
        .tab-content{
            margin-top: 30px;
        }
    }
    &-search{
        .spt-search{
            position: relative;
            &-input{
                box-shadow: 1px 1px 4px 0 rgba(37, 37, 37, 0.15);
                input{
                    max-width: calc(100% - 32px);
                    padding: 0;
                }
            }
        }
    }
    &-form{
        .form-button{
            text-align: center;
        }
        .btn{
            width: 150px;
        }
    }
    &-info{
        margin-top: 48px;
        &-title{
            @include font-set(h-xsmall);
            margin-bottom: 7px;
        }
        &-media{
            margin-top: 15px;
            padding: 10px 20px;
            background-color: $c-light;
            @include responsive(630, 143);
        }
    }
    &-result{
        margin-top: 50px;
        border-top: 1px solid $c-grey-light;
        padding-top: 15px;
        &-info{
            @include font-set(body-small);
        }
        &-products{
            margin-top: 15px;
            .prd-row-inner{
                margin: 0;
                border-bottom: 1px solid $c-grey-light;
            }
        }
    }
    &-downloads{
        padding-top: 20px;
        .menu-btn{
            padding: 0;
        }
        .icon{
            font-size: 18px;
        }
    }
}